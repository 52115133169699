import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
//import Modal from "react-modal";

import axios from "axios";

import clsx from "clsx";

//import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  //  fade,
  //  ThemeProvider,
  withStyles,
  makeStyles,
  //  createMuiTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import IconButton from "@material-ui/core/IconButton";
//import Button from "@material-ui/core/Button";
//import Input from "@material-ui/core/Input";
//import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
//import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { COLORS } from "../../helper/constants/colors";
import { AUTH_CONSTANT } from "../../helper/constants/AuthConstant";

import ButtonRedPrimary from "../../components/Button/ButtonRedPrimary";

import AppLoader from "../../components/Loader/AppLoader";
import { isAuthenticated } from "../../helper/utils/AuthData";
import { Endpoint } from "../../helper/utils/ApiEndpoint";

const Login = () => {
  useEffect(() => {
    //console.log("isAuthenticated 2", isAuthenticated());
    if (isAuthenticated()) {
      navigate("/attendance");
    }
  }, []);

  const classes = useStyles();
  const [values, setValues] = useState({
    email: "",
    password: "",
    //showPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 768px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({
    query: "(max-width: 768px)",
    query: "(max-device-width: 768px)",
    query: "(min-width: 200px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 768px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const style = {
    container: {
      backgroundColor: "#ddd",
    },
  };

  const handleChange = (prop) => (event) => {
    //event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    //setValues({ ...values, showPassword: !values.showPassword });
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Custom TextField
  const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: COLORS.redPrimary,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: COLORS.redPrimary,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#c4c4c4",
        },
        "&:hover fieldset": {
          borderColor: "gray",
        },
        "&.Mui-focused fieldset": {
          borderColor: COLORS.redPrimary,
        },
      },
    },
  })(TextField);

  //Login request
  const [submitAuth, setSubmitAuth] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState("");

  const handleSubmitAuth = () => {
    setErrortext("");
    //setValues({ ...values, ["email"]: "test@gmail.com" });

    if (values.email === "") {
      //alert("Masukkan email");
      setErrortext("Masukkan email !");
    } else if (values.password === "") {
      //alert("Masukkan password");
      setErrortext("Masukkan password !");
    } else {
      setLoading(true);
      // console.log("body", {
      //   Auth: values,
      // });

      // console.log("setSubmitAuth", values);
      // console.log("setLoading", loading);

      let data = JSON.stringify({ Auth: values });

      // console.log("data", data);

      let postData = {
        email: "test@test.com",
        password: "password",
      };

      let axiosHeaders = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      };

      axios
        .post(Endpoint + "auth/login", data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          const responseData = response.data;
          // console.log("response", JSON.stringify(responseData));

          localStorage.setItem(
            AUTH_CONSTANT.data,
            JSON.stringify(responseData)
          );
          navigate("/attendance");
        })
        .catch((error) => {
          setLoading(false);
          const response = error.response;
          const statusCode = response.status;
          const responseData = response.data;
          // console.log("statusCode", statusCode);
          // console.log("errorMessage", error);
          // console.log("responseData", responseData);

          let infoMessage = responseData.info.message;
          let infoError = "";
          if (responseData.info.errors.length > 0) {
            infoError = responseData.info.errors[0].description;
          }
          // console.log("infoMessage", infoMessage);
          // console.log("infoError", infoError);

          if (infoError !== "") {
            infoMessage = infoError;
          }

          setErrortext(infoMessage);
        });
    }
  };

  function closeModal() {
    setLoading(false);
  }

  return (
    <div>
      <Container maxWidth="sm" className={classes.mainContainer}>
        <Typography
          variant="h5"
          component="h2"
          style={{
            textAlign: "center",
          }}
        >
          Login
        </Typography>

        <form className={classes.form} noValidate autoComplete="off">
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextField
              key="email"
              className={classes.textField}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              size="normal"
              value={values.email}
              onChange={handleChange("email")}
            />
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              style={{ marginTop: 20 }}
              variant="outlined"
              size="normal"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>

            <label style={{ marginTop: 10, color: "red" }}>{errortext}</label>

            <ButtonRedPrimary
              variant="contained"
              color="primary"
              style={{ width: "100%", maxWidth: 300, marginTop: 20 }}
              onClick={handleSubmitAuth}
            >
              Login
            </ButtonRedPrimary>
          </Grid>
        </form>
      </Container>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeModal}
        loaderText="Logging in ..."
      />
    </div>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  mainContainer: {
    minWidth: 190,
    padding: 30,
    backgroundColor: "white",

    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  form: {
    marginTop: 30,
  },
  margin: {
    //margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
    maxWidth: 300,
    borderColor: "red",
  },
}));
