import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import successCheckImage from "../../assets/images/success_check.png";
import { AUTH_CONSTANT } from "../../helper/constants/AuthConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessDialogAttendance = (props) => {
  const [authData, setAuthData] = useState({});
  useEffect(
    () => {
      let jsonAuthData = JSON.parse(localStorage.getItem(AUTH_CONSTANT.data));
      setAuthData(jsonAuthData);
    } /* eslint-disable-line */,
    []
  );

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-success-title"
      aria-describedby="alert-dialog-success-description"
    >
      <DialogTitle
        id="alert-dialog-success-title"
        style={{
          textAlign: "center",
        }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent>
        <img
          src={successCheckImage}
          alt="success"
          width={150}
          style={{
            margin: "0 auto",
            display: "block",
            marginBottom: "1rem",
          }}
        />
        <Typography variant="body1">
          User:{" "}
          <b>
            {authData.data?.member_first_name} {authData.data?.member_last_name}
          </b>
        </Typography>
        <Typography variant="body1">
          Lokasi: <b>{`${props.latitude}, ${props.longitude}`}</b>
        </Typography>
        <Typography variant="body1">
          Tanggal:{" "}
          <b>
            <Moment
              format="DD MMMM YYYY"
              tz="Asia/Jakarta"
              locale="id"
              interval={1}
              unit="seconds"
            ></Moment>
          </b>
        </Typography>
        <Typography variant="body1">
          Pukul:{" "}
          <b>
            <Moment
              format="HH.mm"
              tz="Asia/Jakarta"
              locale="id"
              interval={1}
              unit="seconds"
            ></Moment>
          </b>
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={props.handleClose}
          color="primary"
          style={{
            background: "#221E5B",
            color: "#fff",
            width: "100%",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialogAttendance;
