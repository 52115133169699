import { Box, Button, Divider, Grid, List, ListItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
//import material UI
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  CancelRounded,
  CheckCircle,
  KeyboardBackspace,
} from "@material-ui/icons";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

const LiveAttendance = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({});
  const [historyClock, setHistoryClock] = useState([]);
  const verificationMethod = useSelector((state) => state.verificationMethod);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      fetchHistoryClock();
    } /* eslint-disable-line */,
    []
  );

  const fetchHistoryClock = async () => {
    let response;
    try {
      setLoading(true);
      response = await axios.get(
        `${Endpoint}human-resource/attendance/history/clock-in`,
        {
          params: {
            "options[filter][year]": moment().format("YYYY"),
            "options[filter][month]": moment().format("MM"),
            "options[filter][day]": moment().format("DD"),
          },
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setStatus({ status: "success", message: response.data.info.message });
      setHistoryClock(response.data.data);
    } catch (e) {
      setStatus({ status: "error", message: e.response?.data?.info.message });
      console.log(e.response);
    } finally {
      setLoading(false);
    }
  };

  const navigateClockInOut = () => {
    if (verificationMethod.data?.verification.verification_member_pin === 1) {
      console.log("masuk 1");
      navigate("/live-attendance/pin");
    } else if (
      verificationMethod.data?.verification.verification_member_password ===
        1 ||
      verificationMethod.data?.verification.verification_thumb_recognition === 1
    ) {
      navigate("/live-attendance/password");
      console.log("masuk 2");
    } else {
      console.log("masuk sini");
      navigate("/live-attendance/clock-in-out");
    }
  };

  const closeLoader = () => {
    setLoading(false);
  };

  const parseTime = (date) => {
    let time = date.split(" ");
    let timeArr = time[1].split(":");
    return `${timeArr[0]}.${timeArr[1]}`;
  };

  return (
    <PreRequestAttendance>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            background: "#221E5B",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.backButton}
              onClick={() => navigate("/attendance")}
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Live Attendance
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Clock Card */}
        <Card
          style={{
            margin: "0 auto",
            width: "100%",
            background: "#221E5B",
            maxWidth: VALUES.maxScreenWidth,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            marginBottom: "1rem",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <Moment
                format="HH.mm"
                tz="Asia/Jakarta"
                locale="id"
                interval={1}
                unit="seconds"
              ></Moment>
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "#fff", textAlign: "center" }}
              decoration="bold"
            >
              <Moment
                format="dddd, D MMMM YYYY"
                tz="Asia/Jakarta"
                locale="id"
                interval={1}
                unit="seconds"
              ></Moment>
            </Typography>

            <Card
              style={{
                margin: "0 auto",
                width: "100%",
                marginTop: "1rem",
                maxWidth: VALUES.maxScreenWidth,
                borderRadius: 24,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  style={{ color: "#221E5B", textAlign: "center" }}
                >
                  Jadwal{" "}
                  <Moment
                    format="D MMMM YYYY"
                    tz="Asia/Jakarta"
                    locale="id"
                    interval={1}
                    unit="seconds"
                  ></Moment>
                </Typography>
                <Typography
                  variant="h5"
                  style={{ color: "#221E5B", textAlign: "center" }}
                  decoration="bold"
                >
                  <b>
                    {verificationMethod.data?.shift?.schedule_in} -{" "}
                    {verificationMethod.data?.shift?.schedule_out}
                  </b>
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#221E5B", textAlign: "center" }}
                  decoration="bold"
                >
                  <b>di {verificationMethod.data?.schedule?.name}</b>
                </Typography>

                <Box textAlign="center">
                  {verificationMethod.clockStatus !== "done" && (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "1rem",
                        width: "100%",
                        height: "3rem",
                        background: verificationMethod.data?.clock_in
                          ? "#CE354A"
                          : "#221E5B",
                        color: "#FFFFFF",
                      }}
                      onClick={() => navigateClockInOut()}
                    >
                      <b>
                        {verificationMethod.data?.clock_in
                          ? "Clock Out"
                          : "Clock In"}
                      </b>
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "1rem",
                      width: "100%",
                      height: "3rem",
                      background: "#fff",
                      color: "#221E5B",
                    }}
                    onClick={() => navigate("/request-attendance")}
                  >
                    <b>Request Attendance</b>
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "1rem",
                      width: "100%",
                      height: "3rem",
                      background: "#fff",
                      color: "#221E5B",
                    }}
                    onClick={() => navigate("/request-exception")}
                  >
                    <b>Request Exception</b>
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </CardContent>
        </Card>

        <div
          style={{
            margin: "0 auto",
            width: "95%",
            maxWidth: VALUES.maxScreenWidth,
            marginTop: "1rem",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                style={{ color: "#221E5B", textAlign: "left" }}
                decoration="bold"
              >
                <b>History Clock In & Out</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  variant="outlined"
                  style={{
                    background: "#fff",
                    color: "#221E5B",
                    borderRadius: 64,
                  }}
                  onClick={() => navigate("/history-clock-in-out")}
                >
                  <b>Lihat Semua</b>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* History Card */}
        <Card
          style={{
            margin: "0 auto",
            width: "100%",
            maxWidth: VALUES.maxScreenWidth,
            borderRadius: 24,
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <CardContent>
            {status.status === "error" ? (
              <Typography
                variant="h5"
                style={{ color: COLORS.purple, textAlign: "center" }}
              >
                <b>Terdapat Suatu Error</b>
              </Typography>
            ) : (
              <List>
                {historyClock?.map((item, indexHistory) => (
                  <div key={indexHistory}>
                    {!isEmpty(item.log) ? (
                      item.log.map((log, indexLog) => (
                        <ListItem key={indexLog}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                <b style={{ color: "#221E5B" }}>
                                  ● Clock {log.clock_out ? "out" : "in"}
                                </b>
                                {log.status_attendance === "1" ? (
                                  <CheckCircle
                                    style={{
                                      color: "#1AC692",
                                      marginLeft: "1rem",
                                    }}
                                  />
                                ) : (
                                  <CancelRounded
                                    style={{
                                      color: COLORS.redPrimary,
                                      marginLeft: "1rem",
                                    }}
                                  />
                                )}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ color: "#262261", marginLeft: "1rem" }}
                              >
                                {log.clock_out
                                  ? parseTime(log.clock_out)
                                  : parseTime(log.clock_in)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                style={{ color: "#221E5B", textAlign: "right" }}
                              >
                                Today
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </ListItem>
                      ))
                    ) : (
                      <Typography
                        variant="h5"
                        style={{ color: COLORS.purple, textAlign: "center" }}
                      >
                        <b>Tidak ada data history hari ini</b>
                      </Typography>
                    )}
                  </div>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
        <AppLoader
          isLoaderOpen={loading || verificationMethod.loading}
          onRequestClose={closeLoader}
          loaderText="Loading data ..."
        />
      </div>
    </PreRequestAttendance>
  );
};

export default LiveAttendance;
