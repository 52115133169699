import { Button, CircularProgress, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
//import material UI
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import AppLoader from "../../../components/Loader/AppLoader";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: "3rem",
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const ChangePin = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  const [pinValue, setPinValue] = useState({
    oldPin: "",
    newPin: "",
    confirmPin: "",
  });

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/profile");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const handleChangePin = async () => {
    setLoading(true);
    console.log(pinValue);
    if (
      pinValue.oldPin === "" ||
      pinValue.newPin === "" ||
      pinValue.confirmPin === ""
    ) {
      setStatus({
        status: "error",
        info: "Validation Error !!",
        message: "Please fill all field",
      });
      setLoading(false);
    } else if (pinValue.newPin !== pinValue.confirmPin) {
      setStatus({
        status: "error",
        info: "Validation Error !!",
        message: "New pin and confirm pin must be same",
      });
      setLoading(false);
    } else {
      try {
        const data = {
          User: {
            last_pin: pinValue.oldPin,
            new_pin: pinValue.newPin,
          },
        };

        const response = await axios.post(
          `${Endpoint}account-management/user/pin/update`,
          data,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Change PIN Success !!",
            message: "Pin has been changed",
          });
        }
      } catch (error) {
        setStatus({
          status: "error",
          info: "Change PIN Error Occured !!",
          message: error.response.data.info.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar title={"Change PIN"} />
      </AppBar>
      <Card
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#fff",
          maxWidth: VALUES.maxScreenWidth,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
            }}
            gutterBottom
          >
            {"Enter your current PIN"}
          </Typography>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{
              borderRadius: "10px",
              border: "2px solid grey",
              margin: "0 12px",
            }}
            onComplete={(value, index) => {
              setPinValue({
                ...pinValue,
                oldPin: value,
              });
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue({
                  ...pinValue,
                  oldPin: "",
                });
              }
            }}
            type="numeric"
          />

          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              marginTop: "3rem",
            }}
            gutterBottom
          >
            {"Enter your new PIN"}
          </Typography>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{
              borderRadius: "10px",
              border: "2px solid grey",
              margin: "0 12px",
            }}
            onComplete={(value, index) => {
              setPinValue({
                ...pinValue,
                newPin: value,
              });
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue({
                  ...pinValue,
                  newPin: "",
                });
              }
            }}
            type="numeric"
          />

          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              marginTop: "3rem",
            }}
            gutterBottom
          >
            {"Enter your new PIN again"}
          </Typography>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{
              borderRadius: "10px",
              border: "2px solid grey",
              margin: "0 12px",
            }}
            onComplete={(value, index) => {
              setPinValue({
                ...pinValue,
                confirmPin: value,
              });
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setPinValue({
                  ...pinValue,
                  confirmPin: "",
                });
              }
            }}
            type="numeric"
          />

          <Button
            fullWidth
            variant="contained"
            className={classes.fixedBottomButton}
            style={{
              backgroundColor:
                pinValue.oldPin === "" &&
                pinValue.newPin === "" &&
                pinValue.confirmPin === ""
                  ? "#808080"
                  : "#262261",
              color: "#ffffff",
            }}
            onClick={() => handleChangePin()}
          >
            <b>Submit</b>
          </Button>
        </CardContent>
      </Card>

      <ErrorDialog
        open={status.status === "error"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <SuccessDialog
        open={status.status === "success"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default ChangePin;
