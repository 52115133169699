import { AppBar, Box, Card, CardContent, Grid, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { KeyboardBackspace, Work } from '@material-ui/icons';
import axios from 'axios';
import { navigate } from 'hookrouter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import AppLoader from '../../components/Loader/AppLoader';
import { COLORS } from '../../helper/constants/colors';
import { VALUES } from '../../helper/constants/ValueConstants';
import { Endpoint } from '../../helper/utils/ApiEndpoint';
import { getToken, isAuthenticated } from '../../helper/utils/AuthData';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
		marginBottom: 100,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
}));

function MySchedule() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({});
	const [schedule, setSchedule] = useState([]);
	const [scheduleToday, setScheduleToday] = useState({});

	useEffect(() => {
		if (!isAuthenticated()) {
			navigate('/');
		}
		fetchSchedule();
	}, []);

	const fetchSchedule = async () => {
		try {
			setLoading(true);
			const response = await axios.get(`${Endpoint}human-resource/my-schedule`, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getToken()}`,
				},
			});
			setStatus({ status: 'success', message: response.data.info.message });
			setSchedule(response.data.data);
			let today = response.data.data.find(item => item.today === true);
			setScheduleToday(today);
		} catch (error) {
			setStatus({ status: 'error', message: error.response?.data?.info.message });
		} finally {
			setLoading(false);
		}
	};

	const getDate = date => {
		let dateMoment = moment(date, 'YYYY-MM-DD').locale('id');
		return `${dateMoment.format('dddd')}, ${dateMoment.format('MMM')}`;
	};

	const closeLoader = () => {
		setLoading(false);
	};

	return (
		<>
			<div className={classes.root}>
				<AppBar
					position="static"
					style={{
						background: '#221E5B',
						borderTopLeftRadius: 24,
						borderTopRightRadius: 24,
					}}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							className={classes.backButton}
							onClick={() => navigate('/attendance')}
						>
							<KeyboardBackspace />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1 }}>
							My Schedule
						</Typography>
					</Toolbar>
				</AppBar>
				<Card
					style={{
						margin: '0 auto',
						width: '100%',
						background: '#221E5B',
						maxWidth: VALUES.maxScreenWidth,
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 24,
						borderBottomRightRadius: 24,
						marginBottom: '1rem',
					}}
				>
					<CardContent>
						<Card
							style={{
								margin: '0 auto',
								width: '100%',
								marginTop: '1rem',
								maxWidth: VALUES.maxScreenWidth,
								borderRadius: 24,
								padding: '10px 0px',
							}}
						>
							<CardContent style={{ color: '#221E5B' }}>
								<Box textAlign="center">
									<Typography variant="subtitle1">
										Jadwal hari ini,{' '}
										<Moment
											format="dddd, D MMMM YYYY"
											tz="Asia/Jakarta"
											locale="id"
											interval={1}
											unit="seconds"
										></Moment>
									</Typography>
									<Typography variant="h5">
										<b>
											{scheduleToday.scheduleIn} - {scheduleToday.scheduleOut}
										</b>
									</Typography>
									<Typography variant="h6">di {scheduleToday.company}</Typography>
									<div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
										<Work />
										<Typography variant="h6">
											<b>Shift {scheduleToday.shift}</b>
										</Typography>
									</div>
								</Box>
							</CardContent>
						</Card>
					</CardContent>
				</Card>
				<div
					style={{
						margin: '0 auto',
						width: '95%',
						maxWidth: VALUES.maxScreenWidth,
						marginTop: '1rem',
					}}
				>
					<Typography
						variant="h6"
						style={{ color: '#221E5B', textAlign: 'left', marginLeft: '10px' }}
						decoration="bold"
					>
						<b>Jadwal Kerja</b>
					</Typography>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
					{schedule.map(item => (
						<Card style={{ padding: '6px', borderRadius: '10px' }}>
							<CardContent>
								<Grid container spacing={2} alignItems="center" style={{ color: item.off_day ? COLORS.redPrimary : COLORS.purple }}>
									<Grid item xs={3} >
										<Typography align="center" variant="subtitle1">
											<b>{getDate(item.tanggal)}</b>
										</Typography>
										<Typography variant="h4" align="center">
											<b>{item.tanggal.split('-')[2]}</b>
										</Typography>
									</Grid>
									{item.off_day === false ? (
										<Grid item xs={9}>
											<Typography variant="subtitle1">
												<b>Hari Kerja</b>
											</Typography>
											<Typography variant="h4">
												<b>
													{item.scheduleIn} - {item.scheduleOut}
												</b>
											</Typography>
											<Typography variant="subtitle1">di {item.company}</Typography>
											<div style={{ display: 'flex', gap: '10px' }}>
												<Work />
												<Typography variant="h6">
													<b>{item.shift}</b>
												</Typography>
											</div>
										</Grid>
									) : (
										<Grid item xs={9}>
											<Typography variant="subtitle1">
												<b>{item.off_name}</b>
											</Typography>
											<Typography variant="h4">
												<b>
													{item.scheduleIn} - {item.scheduleOut}
												</b>
											</Typography>
										</Grid>
									)}
								</Grid>
							</CardContent>
						</Card>
					))}
				</div>
			</div>
			<AppLoader isLoaderOpen={loading} onRequestClose={closeLoader} loaderText="Fetching Data ..." />
		</>
	);
}

export default MySchedule;
