import React, { useState, useEffect } from "react";
import axios from "axios";
import { navigate } from "hookrouter";

//import material UI
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Image } from "react-bootstrap";
/*
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
*/

//import custom lib
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import { AUTH_CONSTANT } from "../../../helper/constants/AuthConstant";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";

//import image assets
import iconSearch from "../../../assets/icons/ic_search.png";

//import custom component
import AppLoader from "../../../components/Loader/AppLoader";
import GoalItem from "./GoalCollectionItem";

//css style
const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  container: {
    padding: 15,
  },
  header: {
    position: "fixed",
    padding: 0,
    width: "100%",
    maxWidth: VALUES.maxScreenWidth,
    backgroundColor: COLORS.redPrimary,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    zIndex: 10,
  },
  textSearch: {
    height: 30,
    width: "100%",
    borderWidth: 0,
    backgroundColor: "white",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 4,
    outline: 0,
    boxShadow: 0,
  },
  buttonSearch: {
    width: 40,
    height: 35,
    backgroundColor: COLORS.redPrimary,
    border: "2px solid white",
    marginLeft: 10,
    outline: 0,
    boxShadow: 0,
    borderRadius: 40,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.redprimaryDark,
      opacity: 1,
      transition: "all .2s ease-in-out",
    },
    "&:focus": {
      backgroundColor: COLORS.redprimaryDark,
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    marginTop: 5,
  },
});

const GoalCollection = () => {
  const classes = useStyles();

  useEffect(() => {
    //localStorage.clear();
    if (!isAuthenticated()) {
      navigate("/");
    }

    let searchValue = "";
    try {
      if (localStorage.getItem(AUTH_CONSTANT.searchValue) !== "") {
        searchValue = localStorage.getItem(AUTH_CONSTANT.searchValue);
        setTextSearch(searchValue);
      }
    } catch (err) {}

    console.log("searchValue", searchValue);

    getGoalCollection("3", searchValue);

    //console.log("Endpoint", Endpoint);
    //console.log("GoalCollection");
    /*
        const timer = setTimeout(() => {
          if (context.token) {
            navigate("/");
          }
        }, 100);
        return () => clearTimeout(timer);
        */
  }, []);

  const [loading, setLoading] = useState(false); //true
  const [isSuccess, setIsSuccess] = useState(false);
  const [goalCollection, setGoalCollection] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const getGoalCollection = (statusId, txtSearch) => {
    setGoalCollection([]);
    setLoading(true);

    //Get Request
    let searchParam = "";
    if (txtSearch !== "" && txtSearch !== null) {
      searchParam = "&options[filter][search]=" + txtSearch;
    }
    let filterByStatusParam = "&options[filter][status_id]=" + statusId;

    axios
      .get(
        Endpoint +
          "swd/goal?options[paging][offset]=0&options[paging][limit]=100" +
          searchParam +
          filterByStatusParam,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setIsSuccess(true);
        const responseData = response.data;
        setGoalCollection(responseData.data);

        //console.log("response goal collection", JSON.stringify(responseData));
      })
      .catch((error) => {
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  const handleSearchGoal = () => {
    //alert(textSearch);
    getGoalCollection(filterGoalStatus, textSearch);
  };

  const handleChangeTextSearch = (e) => {
    setTextSearch(e.target.value);
    localStorage.setItem(AUTH_CONSTANT.searchValue, e.target.value);
  };

  const [filterGoalStatus, setFilterGoalStatus] = useState("3");

  const handleChangeStatusOption = (event) => {
    setFilterGoalStatus(event.target.value);
    getGoalCollection(event.target.value, textSearch);
  };

  function closeLoader() {
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              key="text_search"
              placeholder="Cari goal"
              className={classes.textSearch}
              //value={textSearch}
              value={textSearch == null ? "" : textSearch}
              onChange={handleChangeTextSearch}
            ></input>
            <button className={classes.buttonSearch}>
              <Image
                src={iconSearch}
                roundedCircle
                style={{ width: 20, height: 20 }}
                onClick={handleSearchGoal}
              />
            </button>
          </div>

          <div style={{ textAlign: "right", marginRight: 48 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              {/* <InputLabel id="select-status-label">Age</InputLabel> */}
              <Select
                labelId="select-status-label"
                id="select-status-id"
                value={filterGoalStatus}
                onChange={handleChangeStatusOption}
                style={{
                  backgroundColor: "transparent",
                  height: 35,
                  textAlign: "left",
                  color: "white",
                }}
              >
                <MenuItem value={3}>Active</MenuItem>
                <MenuItem value={6}>Overdue</MenuItem>
                <MenuItem value={2}>Approved</MenuItem>
                <MenuItem value={4}>Completed</MenuItem>
                <MenuItem value={1}>Draft</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: 90 }}>
        {/* <label style={{ backgroundColor: "green", paddingBottom: 1000 }}>
          Tes
        </label> */}
        <div className={classes.container}>
          {goalCollection.map((item) => (
            <GoalItem key={item.id} dataItem={item} />
          ))}
        </div>
      </div>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeLoader}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default GoalCollection;
