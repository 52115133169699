import React, { useState, useEffect } from "react";
import { navigate } from "hookrouter";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";

//import material ui
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { Container, Row, Col, Image } from "react-bootstrap";

//import component lib
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//import image assets
import avatarUser from "../../../assets/icons/ic_avatar_user.png";
import iconExpectedValue from "../../../assets/icons/ic_expected_value.png";
import iconDaysLeft from "../../../assets/icons/ic_value_days_left.png";
import iconPosition from "../../../assets/icons/ic_blue_target_value.png";

//import custom lib
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";
import { formatDateTime } from "../../../helper/utils/DateFormatter";

//import custom component
//import CircularProgressBar from "../../../components/ProgressBar/CircleProgressBar";

const useStyles = makeStyles({
  root: {
    width: "100%",
    minWidth: 200,
    fontFamily: "Nunito",
    marginBottom: 10,
    color: COLORS.textColorGray4,
  },
  container: {
    backgroundColor: "transparent",
  },
  containerItemWhite: {
    backgroundColor: "white",
    borderRadius: 8,
  },
  containerItemRed: {
    backgroundColor: COLORS.redPrimaryOpacity30,
    borderBottomWidth: 6,
    borderBottomColor: COLORS.redPrimary,
    borderRadius: 8,
  },
  valueTitle: {
    fontSize: 10,
  },
  valueContent: {
    fontSize: 12,
    color: COLORS.textColorGray5,
  },
  circleBackground: {
    padding: 0,
    margin: 0,
    display: "flex",
    // position:'absolute',
    backgroundColor: "transparent",
    borderRadius: "50%",
    width: 45,
    height: 45,
    left: 0,
    top: 0,
    borderWidth: 1,
    borderColor: COLORS.gray3,
    justifyContent: "center",
    alignItems: "center",
  },
  textOra: {
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
    fontWeight: "bold",
    position: "absolute",
    color: COLORS.textColorGray5,
  },
});

const MeasuredActivityCollectionItem = ({ dataItem }) => {
  const classes = useStyles();

  //Result Oriented
  const [resultValueAchievement, setResultValueAchievement] = useState(0);

  const [
    expectedResultValueAchievement,
    setExpectedResultValueAchievement,
  ] = useState(0);

  const [gapResultValueAchievement, setGapResultValueAchievement] = useState(0);
  const [oraValueAchievement, setOraValueAchievement] = useState(0);

  const [
    oraValueAchievementPercentage,
    setOraValueAchievementPercentage,
  ] = useState(0);

  const [progressbarColor, setProgressbarColor] = useState(COLORS.redPrimary);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    //Result Oriented
    let rva = (
      dataItem.calculatedValue.resultOriented.result_value_achievement * 100
    ).toFixed(2);

    let erva = (
      dataItem.calculatedValue.resultOriented
        .expected_result_value_achievement * 100
    ).toFixed(2);

    let grva = (
      dataItem.calculatedValue.resultOriented.gap_result_value_achievement * 100
    ).toFixed(2);

    let ora = (
      dataItem.calculatedValue.resultOriented.overall_result_achievement * 100
    ).toFixed(2);

    let oraPercentage =
      dataItem.calculatedValue.resultOriented.overall_result_achievement; //.toFixed(2)

    setOraValueAchievementPercentage(oraPercentage);

    setResultValueAchievement(rva);
    setExpectedResultValueAchievement(erva);
    setGapResultValueAchievement(grva);

    setOraValueAchievement(ora);

    let progressbarColorValue = COLORS.redPrimary;
    if (ora < 85) {
      progressbarColorValue = COLORS.redPrimary;
    } else if (ora < 100) {
      progressbarColorValue = COLORS.yellowPrimary;
    } else {
      progressbarColorValue = COLORS.greenPrimary;
    }

    setProgressbarColor(progressbarColorValue);
  }, []);

  const handleItemClick = () => {
    //alert("Info", dataItem.name);
    let measuredActivityId = dataItem.id;
    navigate("/measured-activity-detail/" + measuredActivityId);
  };

  return (
    <div className={classes.root} key={dataItem.id}>
      <div className={classes.container}>
        <Card
          variant="outlined"
          onClick={handleItemClick}
          className={
            dataItem.calculatedValue.resultOriented.overall_result_achievement <
            dataItem.calculatedValue.resultOriented
              .expected_result_value_achievement
              ? classes.containerItemRed
              : classes.containerItemWhite
          }
        >
          <CardActionArea>
            <CardContent style={{ padding: 10 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <Avatar
                    src="../../../assets/icons/ic_avatar_user.png"
                    style={{ width: 25, height: 25 }}
                  />
                </div>

                <div style={{ marginLeft: 8 }}>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ fontSize: 12 }}
                  >
                    {dataItem.owner.member_first_name}{" "}
                    {dataItem.owner.member_last_name}
                  </Typography>
                  <Typography style={{ fontSize: 11 }} color="textSecondary">
                    {dataItem.owner.structure_position_title_name}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className={classes.circleBackground}>
                    <CircularProgressbar
                      value={oraValueAchievement}
                      //text={`${percentage}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: progressbarColor,
                        trailColor: COLORS.gray1,
                      })}
                    />

                    <Typography
                      className={classes.textOra}
                      color="textSecondary"
                    >
                      {oraValueAchievement}%
                    </Typography>
                  </div>
                </div>

                <div style={{ marginLeft: 8 }}>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ fontSize: 12 }}
                  >
                    {dataItem.name}
                  </Typography>
                </div>
              </div>

              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                style={{ marginTop: 5 }}
              >
                <Grid item xs={4}>
                  <Typography
                    className={classes.valueTitle}
                    color="textSecondary"
                  >
                    Expected Value
                  </Typography>

                  <Grid container direction="row" alignItems="center">
                    <Grid>
                      <Image
                        src={iconExpectedValue}
                        roundedCircle
                        style={{ width: 15, height: 15 }}
                      />
                    </Grid>

                    <Grid>
                      <Typography
                        className={classes.valueContent}
                        color="textSecondary"
                        style={{ marginLeft: 5 }}
                      >
                        {expectedResultValueAchievement} %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={classes.valueTitle}
                    color="textSecondary"
                  >
                    Days Left
                  </Typography>

                  <Grid container direction="row" alignItems="center">
                    <Grid>
                      <Image
                        src={iconDaysLeft}
                        roundedCircle
                        style={{ width: 15, height: 15 }}
                      />
                    </Grid>

                    <Grid>
                      <Typography
                        className={classes.valueContent}
                        color="textSecondary"
                        style={{ marginLeft: 5 }}
                      >
                        {dataItem.time.remaining_days}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={classes.valueTitle}
                    color="textSecondary"
                  >
                    Position
                  </Typography>

                  <Grid container direction="row" alignItems="center">
                    <Grid>
                      <Image
                        src={iconPosition}
                        roundedCircle
                        style={{ width: 15, height: 15 }}
                      />
                    </Grid>

                    <Grid>
                      <Typography
                        className={classes.valueContent}
                        color="textSecondary"
                        style={{ marginLeft: 5 }}
                      >
                        {dataItem.owner.structure_position_title_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div style={{ marginTop: 12, fontSize: 11 }}>
                Perubahan data terakhir {formatDateTime(dataItem.updated_at, 7)}
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  );
};

export default MeasuredActivityCollectionItem;
