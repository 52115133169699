import React, { useState, useEffect } from "react";
import { navigate } from "hookrouter";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";

//import custom lib
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import { AUTH_CONSTANT } from "../../../helper/constants/AuthConstant";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";

//import custom component
import CircularProgressBar from "../../../components/ProgressBar/CircularProgressBar";
import MeasuredActivityCollectionItem from "./MeasuredActivityCollectionItem";

//css style
const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    width: "100%",
    //height: "100vh",
    color: "white",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  container: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const MeasuredActivityCollection = ({ goalId }) => {
  //init makeStyles classes
  const classes = useStyles();

  const [goalId2, setGoalId2] = useState("");

  //state show loader
  const [loading, setLoading] = useState(false);

  //state is request success
  const [isSuccess, setIsSuccess] = useState(false);

  //state data measured activity collection
  const [measuredActivityCollection, setMeasuredActivityCollection] = useState(
    []
  );

  //state check if data exist
  const [isDataExist, setIsDataExist] = useState(false);

  //run first load page
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    setGoalId2(goalId);
    let searchValue = "";
    getMeasuredActivityCollection("3", searchValue);
  }, []);

  //request get measured activity collection
  const getMeasuredActivityCollection = (statusId, txtSearch) => {
    let goalId3 = window.location.pathname.split("/")[2];

    //set collection to empty first
    setMeasuredActivityCollection([]);

    //show loader
    setLoading(true);

    //Get Request
    let searchParam = "";
    //if (textSearch !== "" && textSearch !== null) {
    //searchParam = "&options[filter][search]=" + txtSearch;
    //}
    let filterByStatusParam = ""; // "&options[filter][status_id]=" + statusId;
    let filterByGoalId = `&options[filter][goal_id]=${goalId3}`;

    axios
      .get(
        Endpoint +
          "swd/measured-activity?options[paging][offset]=0&options[paging][limit]=100" +
          filterByGoalId +
          searchParam +
          filterByStatusParam,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        //hide loader
        setLoading(false);

        //set request success
        setIsSuccess(true);

        //set data collection
        const responseData = response.data;
        setMeasuredActivityCollection(responseData.data);

        //get data count
        let dataCount = responseData.data.length;

        //set data exist or not
        if (dataCount > 0) {
          setIsDataExist(true);
        } else {
          setIsDataExist(false);
        }

        //console.log("response ma collection", JSON.stringify(responseData));
      })
      .catch((error) => {
        //hide loader
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  return (
    <div className={classes.root}>
      {loading && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 80 }}
        >
          <div>
            <CircularProgressBar />
          </div>
        </div>
      )}

      {!isDataExist && !loading && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <label style={{ color: COLORS.textColorGray3, fontSize: 13 }}>
            Tidak ada Measured Activity
          </label>
        </div>
      )}

      <div className={classes.container}>
        {measuredActivityCollection.map((item) => (
          <MeasuredActivityCollectionItem key={item.id} dataItem={item} />
        ))}
      </div>
    </div>
  );
};

export default MeasuredActivityCollection;
