import { Avatar, Divider, Grid, List, ListItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { KeyboardBackspace } from '@material-ui/icons';
import { navigate } from 'hookrouter';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/id';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import userImage from '../../../assets/images/user.png';
import PreRequestAttendance from '../../../components/HOC/PreRequestAttendance';
import AppLoader from '../../../components/Loader/AppLoader';
import { AUTH_CONSTANT } from '../../../helper/constants/AuthConstant';
import { VALUES } from '../../../helper/constants/ValueConstants';
//import custom lib
import { isAuthenticated } from '../../../helper/utils/AuthData';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
}));

const DetailHistoryClockInOut = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false); //true
	const [isSuccess, setIsSuccess] = useState(false);
	const { historyDetail, data } = useSelector(state => state.historyDetail);
	const verificationMethod = useSelector(state => state.verificationMethod);
	const [authData, setAuthData] = useState({});

	useEffect(
		() => {
			if (!isAuthenticated()) {
				navigate('/');
			}
			if (isEmpty(historyDetail)) {
				navigate('/history-clock-in-out');
			}
			let jsonAuthData = JSON.parse(localStorage.getItem(AUTH_CONSTANT.data));
			setAuthData(jsonAuthData);
		} /* eslint-disable-line */,
		[]
	);

	function closeLoader() {
		setLoading(false);
	}

	const parseTimeClock = () => {
		let date = moment();
		if (historyDetail.clock_in != null) {
			date = moment(historyDetail.clock_in, 'YYYY-MM-DD HH:mm:ss');
		} else if (historyDetail.clock_out != null) {
			date = moment(historyDetail.clock_out, 'YYYY-MM-DD HH:mm:ss');
		}
		return date;
	};

	const parseDate = () => {
		let date = moment(data.date, 'YYYY-MM-DD');
		return date;
	};

	return (
		<PreRequestAttendance>
			<div className={classes.root}>
				<AppBar
					position="static"
					style={{
						background: '#221E5B',
						borderTopLeftRadius: 24,
						borderTopRightRadius: 24,
					}}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							className={classes.backButton}
							onClick={() => navigate('/history-clock-in-out')}
						>
							<KeyboardBackspace />
						</IconButton>
					</Toolbar>
				</AppBar>

				<Card
					style={{
						margin: '0 auto',
						width: '100%',
						background: '#221E5B',
						maxWidth: VALUES.maxScreenWidth,
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 24,
						borderBottomRightRadius: 24,
						marginBottom: '1rem',
					}}
				>
					<CardContent
						style={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Avatar
							src={userImage}
							style={{
								marginTop: '1rem',
								backgroundColor: '#fff',
								width: '48px',
								height: '48px',
							}}
						></Avatar>
						<Typography variant="h6" style={{ color: '#fff', marginTop: '1rem' }}>
							{authData.data?.member_first_name} {authData.data?.member_last_name}
						</Typography>
						<Typography variant="subtitle2" style={{ color: '#fff', textAlign: 'center' }} decoration="bold">
							{verificationMethod.data?.work_pattern?.name}
						</Typography>

						<Typography variant="subtitle2" style={{ color: '#fff', marginTop: '2rem' }} decoration="bold">
							Jadwal{' '}
							<Moment
								format="D MMMM YYYY"
								date={parseDate()}
								tz="Asia/Jakarta"
								locale="id"
								interval={1}
								unit="seconds"
							></Moment>
						</Typography>
						<Typography variant="h6" style={{ color: '#fff' }} decoration="bold">
							{verificationMethod.data.shift?.schedule_in} - {verificationMethod.data.shift?.schedule_out}
						</Typography>

						<Typography variant="body2" style={{ color: '#fff', marginTop: '2rem' }} decoration="bold">
							{historyDetail.clock_in ? 'Clock In' : 'Clock Out'}
						</Typography>
						<Typography variant="h6" style={{ color: '#fff' }}>
							<Moment
								format="HH:mm"
								tz="Asia/Jakarta"
								date={parseTimeClock()}
								locale="id"
								interval={1}
								unit="seconds"
							></Moment>
						</Typography>
					</CardContent>
				</Card>

				<div
					style={{
						margin: '0 auto',
						width: '95%',
						maxWidth: VALUES.maxScreenWidth,
						marginTop: '1rem',
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h6" style={{ color: '#221E5B', textAlign: 'left' }} decoration="bold">
								<b>Info</b>
							</Typography>
						</Grid>
					</Grid>
				</div>

				<Card
					style={{
						margin: '0 auto',
						width: '100%',
						maxWidth: VALUES.maxScreenWidth,
						borderRadius: 24,
						marginTop: '1rem',
						marginBottom: '1rem',
					}}
				>
					<CardContent>
						<List>
							<ListItem>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Typography variant="body1" style={{ color: '#221E5B', textAlign: 'left' }}>
											Tanggal Clock In
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" style={{ color: '#221E5B', textAlign: 'right' }}>
											<Moment
												date={parseDate()}
												format="dddd, D MMMM YYYY"
												tz="Asia/Jakarta"
												locale="id"
												interval={1}
												unit="seconds"
											></Moment>
										</Typography>
									</Grid>
								</Grid>
								<Divider />
							</ListItem>

							<ListItem>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Typography variant="body1" style={{ color: '#221E5B', textAlign: 'left' }}>
											{historyDetail.clock_in ? 'Clock In' : 'Clock Out'}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" style={{ color: '#221E5B', textAlign: 'right' }}>
											<b>
												<Moment
													format="HH:mm"
													tz="Asia/Jakarta"
													date={parseTimeClock()}
													locale="id"
													interval={1}
													unit="seconds"
												></Moment>
											</b>
										</Typography>
									</Grid>
								</Grid>
								<Divider />
							</ListItem>

							<ListItem>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Typography variant="body1" style={{ color: '#221E5B', textAlign: 'left' }}>
											Status
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" style={{ color: '#221E5B', textAlign: 'right' }}>
											{historyDetail.status_attendance === "1" ? 'Success' : 'Failed'}
										</Typography>
									</Grid>
								</Grid>
								<Divider />
							</ListItem>

							<ListItem>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Typography variant="body1" style={{ color: '#221E5B', textAlign: 'left' }}>
											Deskripsi
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" style={{ color: '#221E5B', textAlign: 'right' }}>
											{historyDetail.clock_in_note != null
												? historyDetail.clock_in_note
												: historyDetail.clock_out_note != null
												? historyDetail.clock_out_note
												: '-'}
										</Typography>
									</Grid>
								</Grid>
								<Divider />
							</ListItem>
						</List>
					</CardContent>
				</Card>

				<AppLoader isLoaderOpen={loading} onRequestClose={closeLoader} loaderText="Loading data ..." />
			</div>
		</PreRequestAttendance>
	);
};

export default DetailHistoryClockInOut;
