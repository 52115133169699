import React from "react";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green, purple } from "@material-ui/core/colors";

const ButtonRedPrimary = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "12px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#d1354a", // //0063cc
    borderColor: "#d1354a", //0063cc
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#C40049",
      borderColor: "#C40049", //0062cc
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#C40049", //0062cc
      borderColor: "#C40049", //005cbf
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(209,53,74,.5)", //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
    },
  },
})(Button);

export default ButtonRedPrimary;
