import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CircularProgress from "@material-ui/core/CircularProgress";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: 10000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    filter: "blur(0px)",
  },
};

const AppLoader = ({ isLoaderOpen, onRequestClose, loaderText }) => {
  return (
    <div>
      <Modal
        isOpen={isLoaderOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Loading"
        shouldCloseOnOverlayClick={false} //canceled touch outside
      >
        <div style={{ alignItems: "center", textAlign: "center" }}>
          <CircularProgress color="secondary" />
          <br />
          <label>{loaderText} </label>
        </div>
      </Modal>

      {/* <Modal
        isOpen={loading}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={true}
      >
        <label>Loading</label>
      </Modal> */}
    </div>
  );
};

export default AppLoader;
