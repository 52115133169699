import { Button, CircularProgress, FormControl, Input, InputAdornment, InputLabel } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
import clsx from 'clsx';
import { navigate } from 'hookrouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import BackToolbar from '../../../components/BackToolbar';
import ErrorDialog from '../../../components/Dialog/ErrorDialog';
import { COLORS } from '../../../helper/constants/colors';
import { VALUES } from '../../../helper/constants/ValueConstants';
import { Endpoint } from '../../../helper/utils/ApiEndpoint';
//import custom lib
import { getToken, isAuthenticated } from '../../../helper/utils/AuthData';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
	fixedBottomButton: {
		width: '100%',
		height: '5rem',
		bottom: 0,
		left: 0,
		right: 0,
		background: '#262261',
		color: '#fff',
		borderTopLeftRadius: 14,
		borderTopRightRadius: 14,
		borderBottomLeftRadius: 14,
		borderBottomRightRadius: 14,
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '25ch',
	},
}));

const PasswordCheckInOut = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false); //true
	const [error, setError] = useState({}); //true
	const [isSuccess, setIsSuccess] = useState(false);

	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	});

	const handleChange = prop => event => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const handleClose = () => {
		setError({});
	};

	useEffect(
		() => {
			if (!isAuthenticated()) {
				navigate('/');
			}
		} /* eslint-disable-line */,
		[]
	);

	const verificationPassword = async () => {
		setLoading(true);
		let response;
		let body = {
			Attendance: {
				password: values.password,
			},
		};
		try {
			response = await axios.post(`${Endpoint}human-resource/attendance/password-verification`, body, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getToken()}`,
				},
			});
			navigate('/live-check-in-out/check-in-out');
			setLoading(false);
		} catch (e) {
			response = {};
			console.log('password salah');
			setLoading(false);
			setError({ info: 'Konfirmasi password gagal', message: e.response.data.info.message });
		}
		return response;
	};

	return (
		<div className={classes.root}>
			<AppBar
				position="static"
				style={{
					background: '#fff',
					borderTopLeftRadius: 24,
					borderTopRightRadius: 24,
				}}
				//elevation={0}
			>
				<BackToolbar title={'Masukkan Password'} />
			</AppBar>
			<Card
				style={{
					margin: '0 auto',
					width: '100%',
					background: '#fff',
					maxWidth: VALUES.maxScreenWidth,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					borderBottomLeftRadius: 24,
					borderBottomRightRadius: 24,
				}}
			>
				<CardContent>
					<div
						style={{
							color: '#221E5B',
							marginTop: '10rem',
							marginBottom: '17rem',
							textAlign: 'center',
						}}
					>
						<FormControl className={clsx(classes.margin, classes.textField)}>
							<InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
							<Input
								id="standard-adornment-password"
								type={values.showPassword ? 'text' : 'password'}
								value={values.password}
								onChange={handleChange('password')}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
					<Button
						variant="contained"
						style={{ backgroundColor: values.password === '' ? COLORS.gray3 : COLORS.purple, color: COLORS.white }}
						className={classes.fixedBottomButton}
						onClick={() => verificationPassword()}
					>
						{loading ? <CircularProgress sx={{ color: COLORS.gray3 }} /> : <b>Input</b>}
					</Button>
					<ErrorDialog open={!isEmpty(error)} info={error.info} message={error.message} handleClose={handleClose} />
				</CardContent>
			</Card>
		</div>
	);
};

export default PasswordCheckInOut;
