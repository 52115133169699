import React, { useEffect, useState } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { id } from "date-fns/locale";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  useMediaQuery,
  Toolbar,
  IconButton,
  DialogContent,
  Avatar,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Grid,
  Paper,
  Input,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DurationPicker } from "material-duration-picker";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty, now } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { KeyboardBackspace } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const DialogNotifDetail = ({
  isOpenDialogNotifDetail,
  setOpenDialogNotifDetail,
  notifDetail,
  handleApprove,
  handleReject,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen={fullScreen}
        open={isOpenDialogNotifDetail}
        onClose={() => setOpenDialogNotifDetail(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <AppBar
          position="static"
          style={{
            background: "#fff",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
          //elevation={0}
        >
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.backButton}
              onClick={() => setOpenDialogNotifDetail(false)}
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography
              variant="h6"
              component={"span"}
              style={{ flexGrow: 1, color: "#221E5B" }}
            >
              {notifDetail.type !== "notification"
                ? "REQUEST DETAIL"
                : "NOTIFICATION DETAIL"}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Avatar
                src={notifDetail.photo_url}
                style={{
                  marginTop: 20,
                  width: "100px",
                  height: "100px",
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  color: "#221E5B",
                  fontWeight: "bold",
                }}
              >
                {notifDetail.user_name}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {notifDetail.title}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                }}
              >
                {moment(notifDetail.created_at)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ marginTop: 20 }}>
            {notifDetail.user_name} mengirim {notifDetail.title}
          </Typography>
          {notifDetail.type === "request_exception" && (
            <>
              <Typography variant="subtitle1">
                Jadwal :{" "}
                {moment(notifDetail.date_before)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
              <Typography variant="subtitle1">
                Pindah Ke :{" "}
                {moment(notifDetail.date_after)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
            </>
          )}
          {notifDetail.desc != null && notifDetail.desc !== "" && (
            <Typography variant="subtitle1">
              Desc : {notifDetail.desc}
            </Typography>
          )}
          <Typography variant="subtitle1">
            Status :{" "}
            <b
              style={{
                color: COLORS.redPrimary,
              }}
            >
              {notifDetail.status}
            </b>
          </Typography>

          {notifDetail.type !== "notification" && (
            <>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 40,
                }}
              >
                Disetujui ?
              </Typography>
              <Grid
                container
                spacing={3}
                style={{
                  marginTop: "1rem",
                }}
              >
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleReject(notifDetail.id, notifDetail.type);
                    }}
                    style={{
                      height: "3rem",
                    }}
                    disabled={loading}
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleApprove(notifDetail.id, notifDetail.type);
                    }}
                    style={{
                      height: "3rem",
                    }}
                    disabled={loading}
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogNotifDetail;
