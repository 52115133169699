import {
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import 'date-fns';
import { navigate } from 'hookrouter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BackToolbar from '../../../components/BackToolbar';
import { VALUES } from '../../../helper/constants/ValueConstants';
import { Endpoint } from '../../../helper/utils/ApiEndpoint';
//import custom lib
import { getToken, isAuthenticated } from '../../../helper/utils/AuthData';
import { setHistoryDetail } from '../../../store/Attendance/historyDetail';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
}));

const HistoryCheckInOut = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false); //true
	const [isSuccess, setIsSuccess] = useState(false);
	const [status, setStatus] = useState({});
	const [isCheckIn, setIsCheckIn] = useState(true);
	const [data, setData] = useState([]);
	const [dataRaw, setDataRaw] = useState([]);
	const dispatch = useDispatch();

	useEffect(
		() => {
			if (!isAuthenticated()) {
				navigate('/');
			}
			fetchHistory();
		} /* eslint-disable-line */,
		[]
	);

	useEffect(
		() => {
			filterCheckIn();
		} /* eslint-disable-line */,
		[isCheckIn, dataRaw]
	);

	const filterCheckIn = () => {
		let dataArr = [...dataRaw];
		dataArr = dataArr.map((date, index) => {
			let log = date.log.filter(item => {
				return isCheckIn ? item.clock_in != null : item.clock_out != null;
			});
			// untuk memfilter clock in atau clock out
			return { ...date, log };
		});
    console.log(dataRaw)
		setData(dataArr);
	};

	const fetchHistory = async () => {
		let response;
		try {
			setLoading(true);
			response = await axios.get(`${Endpoint}human-resource/attendance/history/check-in`, {
				params: {
					'options[filter][search]': moment().format("YYYY-MM-DD"),
				},
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getToken()}`,
				},
			});
			setData(response.data.data);
			setDataRaw(response.data.data);
			setStatus({ status: 'success', message: response.data.info.message });
		} catch (e) {
			setStatus({ status: 'error', message: e.response?.data?.info.message });
			console.log(e.response);
		} finally {
			setLoading(false);
		}
	};

	const parseDate = date => {
		if (!date) return ``;
		let dateArr = date.split('-');
		let month = parseInt(dateArr[1]) - 1;
		month = moment().month(month).format('MMMM');
		return `${dateArr[2]} ${month}`;
	};

	const navigateHistoryDetail = data => {
		dispatch(setHistoryDetail(data));
		navigate('/history-clock-in-out/detail');
	};

	const parseTime = date => {
		if (!date) return;
		let time = date.split(' ');
		let timeArr = time[1].split(':');
		return `${timeArr[0]}:${timeArr[1]}`;
	};

	return (
		<div className={classes.root}>
			<AppBar
				position="static"
				style={{
					background: '#fff',
					borderTopLeftRadius: 24,
					borderTopRightRadius: 24,
				}}
				//elevation={0}
			>
				<BackToolbar title={'History Clock In & Out'} url={'/live-check-in-out'} />
			</AppBar>

			<Typography variant="h5" style={{ color: '#221E5B', marginTop: '20px', marginLeft: '10px' }}>
				<b>{moment().format('DD MMMM YYYY').toString()}</b>
			</Typography>

			<div style={{ marginTop: '20px' }}>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									style={{
										color: '#262261',
										fontWeight: 'bold',
										fontSize: '1rem',
									}}
								>
									<Select
										value={isCheckIn}
										style={{
											fontWeight: 'bold',
											color: '#221E5B',
											fontSize: '1rem',
										}}
										onChange={e => setIsCheckIn(e.target.value)}
										disableUnderline
									>
										<MenuItem value={true}>Clock In</MenuItem>
										<MenuItem value={false}>Clock Out</MenuItem>
									</Select>
								</TableCell>
								<TableCell
									align="center"
									style={{
										color: '#262261',
										fontWeight: 'bold',
										fontSize: '1rem',
									}}
								>
									Lokasi
								</TableCell>
								<TableCell
									align="center"
									style={{
										color: '#262261',
										fontWeight: 'bold',
										fontSize: '1rem',
									}}
								>
									Status
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map(date =>
								date?.log.map((row, indexRow) => (
									<TableRow key={indexRow} onClick={() => navigateHistoryDetail({ historyDetail: row, data: date })}>
										<TableCell align="center">
											<p
												style={{
													color: '#262261',
													fontWeight: 'bold',
												}}
											>
												{isCheckIn ? parseTime(row.clock_in) : parseTime(row.clock_out)}
											</p>
										</TableCell>
										<TableCell align="center">
											<p
												style={{
													color: '#262261',
													fontWeight: 'bold',
												}}
											></p>
										</TableCell>
										<TableCell align="center">
											{row.status_attendance === '1' ? (
												<p
													style={{
														color: '#262261',
														fontWeight: 'bold',
													}}
												>
													Success
												</p>
											) : (
												<p
													style={{
														color: '#CE354A',
														fontWeight: 'bold',
													}}
												>
													Fail
												</p>
											)}
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
};

export default HistoryCheckInOut;
