import React, { useEffect, useState } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { id } from "date-fns/locale";

import { Button, CircularProgress, FormControl } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Grid,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DurationPicker } from "material-duration-picker";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty, now } from "lodash";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { AlarmAddRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const RequestOvertime = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  const [validation_data, setValidation_data] = useState({});
  const [shift, setShift] = useState("");
  const [shift_schedule_in, setShift_schedule_in] = useState("");
  const [shift_schedule_out, setShift_schedule_out] = useState("");

  // input
  const [overtime_type, set_overtime_type] = useState("after_shift");
  const [workday, set_workday] = useState(new Date(now()));
  const [overtime_duration, set_overtime_duration] = useState(0);
  const [rest_duration, set_rest_duration] = useState(0);
  const [description, set_description] = useState("");

  const verificationMethod = useSelector((state) => state.verificationMethod);
  //const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    const getForm = async () => {
      try {
        setLoading(true);
        const token = getToken();
        const response = await axios.get(
          Endpoint + "human-resource/overtime/create",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setValidation_data(response.data);
          if (isWorkday(workday)) {
            setShift(response.data.data.masterShiftCollections[0].title);
            setShift_schedule_in(
              response.data.data.masterShiftCollections[0].schedule_in
            );
            setShift_schedule_out(
              response.data.data.masterShiftCollections[0].schedule_out
            );
          } else {
            setShift("Day Off");
            setShift_schedule_in("00:00");
            setShift_schedule_out("00:00");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getForm();
    //console.log(validation_data.data.masterShiftCollections[0]);
  }, []);

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/overtime");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const isWorkday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const storeOvertime = async () => {
    try {
      setLoading(true);
      const data = {
        Overtime: {
          category: overtime_type,
          shift_id: validation_data.data.masterShiftCollections[0].id,
          workday: workday.toISOString().split("T")[0],
          schedule_in: shift_schedule_in,
          schedule_out: shift_schedule_out,
          overtime_duration: overtime_duration,
          rest_duration: rest_duration,
          description: description,
        },
      };

      const response = await axios.post(
        Endpoint + "human-resource/overtime/create",
        data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setStatus({
          status: "success",
          info: "success",
          message: "Successfully Requested",
        });
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Something went wrong",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PreRequestAttendance>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            background: "#fff",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
          //elevation={0}
        >
          <BackToolbar title={"Request Overtime"} />
        </AppBar>

        <Card
          style={{
            margin: "0 auto",
            width: "100%",
            background: "#fff",
            maxWidth: VALUES.maxScreenWidth,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
          }}
        >
          <CardContent>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <InputLabel htmlFor="overtime-type">Jenis Overtime</InputLabel>
              <Select
                id="overtime-type"
                value={overtime_type}
                onChange={(e) => set_overtime_type(e.target.value)}
                displayEmpty
                startAdornment={
                  <InputAdornment position="start">
                    <AlarmAddRounded />
                  </InputAdornment>
                }
              >
                <MenuItem value={"after_shift"}>
                  After Shift (Setelah Jadwal Jam Kerja)
                </MenuItem>
                <MenuItem value={"before_shift"}>
                  Before Shift (Sebelum Jadwal Jam Kerja)
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date"
                  label="Kapan"
                  format="d, MMMM, yyyy"
                  value={workday}
                  inputFormat="yyyy-MM-dd HH:mm:ss"
                  onChange={(v) => {
                    set_workday(v);
                    if (isWorkday(v)) {
                      setShift(
                        validation_data.data.masterShiftCollections[0].title
                      );
                      setShift_schedule_in(
                        validation_data.data.masterShiftCollections[0]
                          .schedule_in
                      );
                      setShift_schedule_out(
                        validation_data.data.masterShiftCollections[0]
                          .schedule_out
                      );
                    } else {
                      setShift("Day Off");
                      setShift_schedule_in("00:00");
                      setShift_schedule_out("00:00");
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body2">Shift</Typography>
                    <Typography variant="body2">
                      <b>{shift}</b>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body2">Schedule In</Typography>
                    <Typography variant="body2">
                      <b>{shift_schedule_in}</b>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body2">Schedule Out</Typography>
                    <Typography variant="body2">
                      <b>{shift_schedule_out}</b>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <DurationPicker
                label="Durasi Overtime"
                value={overtime_duration}
                onValueChange={(v) => set_overtime_duration(v)}
                formatDuration={(duration) =>
                  formatDuration(duration, {
                    locale: id,
                  })
                }
                DurationDialogProps={{
                  labels: {
                    cancel: "Batal",
                    ok: "Ok",
                    weeks: "Minggu",
                    days: "Hari",
                    hours: "Jam",
                    minutes: "Menit",
                    seconds: "Detik",
                  },
                  DurationFieldsContainerProps: {
                    labels: {
                      cancel: "Batal",
                      ok: "Ok",
                      weeks: "Minggu",
                      days: "Hari",
                      hours: "Jam",
                      minutes: "Menit",
                      seconds: "Detik",
                    },
                  },
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <DurationPicker
                label="Durasi Break"
                value={rest_duration}
                onValueChange={(v) => set_rest_duration(v)}
                formatDuration={(duration) =>
                  formatDuration(duration, {
                    locale: id,
                  })
                }
                DurationDialogProps={{
                  labels: {
                    cancel: "Batal",
                    ok: "Ok",
                    weeks: "Minggu",
                    days: "Hari",
                    hours: "Jam",
                    minutes: "Menit",
                    seconds: "Detik",
                  },
                  DurationFieldsContainerProps: {
                    labels: {
                      cancel: "Batal",
                      ok: "Ok",
                      weeks: "Minggu",
                      days: "Hari",
                      hours: "Jam",
                      minutes: "Menit",
                      seconds: "Detik",
                    },
                  },
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <TextField
                fullWidth
                label="Deskripsi"
                placeholder="Masukkan deskripsi (Opsional)"
                onChange={(e) => set_description(e.target.value)}
                value={description}
              />
            </FormControl>

            <Grid
              container
              spacing={3}
              style={{
                marginTop: "1rem",
              }}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={
                    () => {
                      set_overtime_type("after_shift");
                      set_workday(new Date(now()));
                      set_overtime_duration(0);
                      set_rest_duration(0);
                      set_description("");
                    } /*handleCancel()*/
                  }
                  disabled={loading}
                >
                  Batal
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={
                    () => {
                      storeOvertime();
                    } /*handleSubmit()*/
                  }
                  disabled={loading}
                >
                  Ajukan
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <ErrorDialog
          open={status.status === "error"}
          info={status.info}
          message={status.message}
          handleClose={handleClose}
        />
        <SuccessDialog
          open={status.status === "success"}
          info={status.info}
          message={status.message}
          handleClose={handleClose}
        />
        <AppLoader
          isLoaderOpen={verificationMethod.loading}
          onRequestClose={() => {}}
          loaderText="Loading data ..."
        />
      </div>
    </PreRequestAttendance>
  );
};

export default RequestOvertime;
