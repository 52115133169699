import { createSlice } from '@reduxjs/toolkit';

const defineValue = () => {
	const firstPath = window.location.pathname.split('/')[1];
	if (firstPath === 'mail') {
		return 1;
	} else if (firstPath === 'team') {
		return 2;
	} else if (firstPath === 'profile') {
		return 3;
	} else {
		return 0;
	}
};

const initialState = {
	index: defineValue(),
};

const bottomNavigation = createSlice({
	name: 'bottomNavigation',
	initialState,
	reducers: {
		setBottomNavigation: (state, action) => {
			state.index = action.payload.index;
			return state;
		},
	},
});

export const { setBottomNavigation } = bottomNavigation.actions;
export default bottomNavigation.reducer;
