export const AUTH_CONSTANT = {
  data: "auth_data",
  token: "token",
  email: "email",
  groupName: "group_name",
  expireDate: "expiry_date",
  profilePhoto: "profile_photo",
  userRole: "user_role",
  firstName: "first_name",
  lastName: "last_name",
  nickName: "nick_name",
  positionTitle: "position_title",
  userId: "user_id",
  memberId: "member_id",
  completionStatusId: "completion_status_id",
  maximumUserQuota: "maximum_user_quota",
  usedUserQuota: "used_user_quota",
  remainingUserQuota: "reamining_user_quota",
  topPositionName: "top_position_name",
  searchValue: "search_value",
  verificationMethod: "verification_method"
};
