export const formatDateTime = (sDate, FormatType) => {
  return sDate;
  /*
  if (sDate === "" || sDate === null || sDate === "undefined") {
    return "-";
  } else {
    let lDate = new Date(sDate);

    let month = new Array(12);
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    let hh = lDate.getHours() < 10 ? "0" + lDate.getHours() : lDate.getHours();
    let mi =
      lDate.getMinutes() < 10 ? "0" + lDate.getMinutes() : lDate.getMinutes();
    let ss =
      lDate.getSeconds() < 10 ? "0" + lDate.getSeconds() : lDate.getSeconds();

    let d = lDate.getDate();
    let dd = d < 10 ? "0" + d : d;
    let yyyy = lDate.getFullYear();
    let mon = eval(lDate.getMonth() + 1);
    let mm = mon < 10 ? "0" + mon : mon;
    let monthName = month[lDate.getMonth()].toString();
    let weekdayName = weekday[lDate.getDay()];

    if (FormatType == 1) {
      return mm + "/" + dd + "/" + yyyy + " " + hh + ":" + mi;
    } else if (FormatType == 2) {
      return weekdayName + ", " + monthName + " " + dd + ", " + yyyy;
    } else if (FormatType == 3) {
      return mm + "/" + dd + "/" + yyyy;
    } else if (FormatType == 4) {
      let dd1 = lDate.getDate();
      return dd1 + "-" + monthName.substring(0, 3) + "-" + yyyy;
    } else if (FormatType == 5) {
      return mm + "/" + dd + "/" + yyyy + " " + hh + ":" + mi + ":" + ss;
    } else if (FormatType == 6) {
      return mon + "/" + d + "/" + yyyy + " " + hh + ":" + mi + ":" + ss;
    } else if (FormatType == 7) {
      return (
        dd + " " + monthName.substring(0, 3) + " " + yyyy + " " + hh + ":" + mi
      );
    }
  }

  */
};
