import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from '../../helper/utils/AuthData';
import { getInbox } from '../../store/Attendance/inbox';
import { getTeam } from '../../store/Attendance/team';
import { getVerificationMethod } from '../../store/Attendance/verificationMethod';

function PreRequestAttendance({ children }) {
	const dispatch = useDispatch();
	const verificationMethod = useSelector(state => state.verificationMethod);
	const team = useSelector(state => state.team);
	const inbox = useSelector(state => state.inbox);

	useEffect(() => {
		if (isAuthenticated() && isEmpty(verificationMethod.data)) {
			dispatch(getVerificationMethod());
		}
	}, [verificationMethod.data, dispatch]);

	useEffect(() => {
		if (isAuthenticated() && isEmpty(team.status.message)) {
			dispatch(getTeam());
		}
	}, [team.data, dispatch]);

	useEffect(() => {
		if (isAuthenticated() && isEmpty(inbox.status.message)) {
			dispatch(getInbox());
		}
	}, [inbox.data, dispatch]);

	return <>{children}</>;
}

export default PreRequestAttendance;
