export const DecimalFormat = (number, digitAfterComma = 2) => {
  //return "000";
  let cleanNumber = number;
  if (number === "" || number === null) {
    cleanNumber = "0";
  }
  let result = cleanNumber; // * 100;
  return result.toLocaleString("en-US", {
    maximumFractionDigits: digitAfterComma,
  });
};

export const DecimalFormatWithPercentage = (number, digitAfterComma = 2) => {
  //return "000";
  let cleanNumber = number;
  if (number === "" || number === null) {
    cleanNumber = "0";
  }
  let result = cleanNumber * 100;
  return result.toLocaleString("en-US", {
    maximumFractionDigits: digitAfterComma,
  });
};

export const CurrencyFormat = (number, digitAfterComma = 2) => {
  let cleanNumber = number;
  if (number === "" || number === null) {
    cleanNumber = "0";
  }

  return cleanNumber.toLocaleString("en-US", {
    maximumFractionDigits: digitAfterComma,
  });
};
