import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  CircularProgress,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import { Box, Divider, Grid, List, ListItem } from "@material-ui/core";

//import material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { DateRange } from "@material-ui/icons";

import calenderImage from "../../../assets/images/calender.png";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  timeOffQuotaCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
    borderRadius: 11,
    padding: "10px 20px",
    margin: "10px 10px",
  },
}));

const DetailTimeOffQuota = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [totalTimeOffAllowance, setTotalTimeOffAllowance] = useState(0);
  const [totalTimeOffTaken, setTotalTimeOffTaken] = useState(0);
  const [totalTimeOffRemaining, setTotalTimeOffRemaining] = useState(0);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      getTimeOffQuota();
    } /* eslint-disable-line */,
    []
  );

  const getTimeOffQuota = async () => {
    let response;
    try {
      setLoading(true);
      const token = getToken();
      response = await axios.get(Endpoint + "human-resource/request-timeoff", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setTotalTimeOffAllowance(
          response.data.data.cuti_list[0].hasUser.allowance
        );
        setTotalTimeOffTaken(
          response.data.data.cuti_list[0].hasUser.allowance -
            response.data.data.cuti_list[0].hasUser.remaining
        );
        setTotalTimeOffRemaining(
          response.data.data.cuti_list[0].hasUser.remaining
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar title={"Detail Kuota Cuti"} />
      </AppBar>

      <div style={{ paddingTop: 10 }}>
        <Card className={classes.timeOffQuotaCard}>
          <CardContent style={{ flex: "1 0 auto" }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span>Total Jatah Cuti Tahunan</span>
                </div>
                <Typography variant="h4" style={{ color: "white" }}>
                  <b>{totalTimeOffAllowance} Hari</b>
                </Typography>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: 10,
                  }}
                >
                  <span>Cuti Tahunan Terpakai</span>
                </div>
                <Typography variant="h4" style={{ color: "white" }}>
                  <b>{totalTimeOffTaken} Hari</b>
                </Typography>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: 10,
                  }}
                >
                  <span>Sisa Jatah Cuti Tahunan</span>
                </div>
                <Typography variant="h4" style={{ color: "white" }}>
                  <b>{totalTimeOffRemaining} Hari</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img src={calenderImage} alt="calender" width={200} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>

      <div
        style={{
          paddingTop: 10,
          paddingRight: 10,
          paddingLeft: 10,
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{
            height: "5rem",
          }}
          onClick={() => {
            navigate("/time-off/request");
          }}
        >
          <b>Ajukan Time-Off</b>
        </Button>
      </div>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default DetailTimeOffQuota;
