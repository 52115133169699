import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Endpoint } from '../../helper/utils/ApiEndpoint';
import { getToken } from '../../helper/utils/AuthData';

const initialState = {
	loading: false,
	status: { success: false, error: false, message: {} },
	data: [],
};

export const getTeam = createAsyncThunk('team/getTeam', async (params, { rejectWithValue }) => {
	let response;
	try {
		response = await axios.get(`${Endpoint}human-resource/team`, {
			headers: {
				Accept: '*/*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getToken()}`,
			},
		});
		return response.data;
	} catch (err) {
		response = err.response.data;
		return rejectWithValue(response);
	}
});

const team = createSlice({
	name: 'team',
	initialState,
	reducers: {},
	extraReducers: {
		[getTeam.pending]: (state, { payload }) => {
			state.loading = true;
		},
		[getTeam.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.data = payload?.data;
			state.status = {
				success: true,
				error: false,
				message: payload?.info,
			};
		},
		[getTeam.rejected]: (state, { payload }) => {
			state.loading = false;
			state.data = payload?.data;
			state.status = {
				success: false,
				error: true,
				message: payload?.info,
			};
		},
	},
});

export default team.reducer;
