import React, { useEffect, useState } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { id } from "date-fns/locale";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  useMediaQuery,
  Toolbar,
  IconButton,
  DialogContent,
  Avatar,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Grid,
  Paper,
  Input,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DurationPicker } from "material-duration-picker";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty, now } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { KeyboardBackspace } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const InboxDetail = ({ type, id }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  const [inboxDetail, setInboxDetail] = useState({});

  const getInboxDetail = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${Endpoint}human-resource/inbox/detail/${id}/${type}`,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      setInboxDetail(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const generateTitle = (type) => {
    switch (type) {
      case "request_exception":
        return "Request Exception";
      case "request_attendance":
        return "Request Attendance";
      case "request_time_off":
        return "Request Time Off";
      case "request_overtime":
        return "Request Overtime";
      default:
        return "";
    }
  };

  async function handleRequestApprove(id, type) {
    try {
      if (type === "request_exception") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-exception/approve/${id}`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menerima permintaan",
          });
        }
      } else if (type === "request_attendance") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-attendance/approve/${id}`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menerima permintaan",
          });
        }
      } else if (type === "request_time_off") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-timeoff/${id}/approve`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menerima permintaan",
          });
        }
      } else if (type === "request_overtime") {
        const response = await axios.post(
          `${Endpoint}human-resource/overtime/${id}/approve`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menerima permintaan",
          });
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.statusText,
          });
        }

        if (error.response.status == 403) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.data.info.message,
          });
        }
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Periksa koneksi jaringan internet Anda ",
        });
      }
      console.log("Error : ", error.response);
    }
  }

  async function handleRequestReject(id, type) {
    try {
      if (type === "request_exception") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-exception/reject/${id}`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menolak permintaan",
          });
        }
      } else if (type === "request_attendance") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-attendance/reject/${id}`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menolak permintaan",
          });
        }
      } else if (type === "request_time_off") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-timeoff/${id}/reject`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menolak permintaan",
          });
        }
      } else if (type === "request_overtime") {
        const response = await axios.post(
          `${Endpoint}human-resource/overtime/${id}/reject`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menolak permintaan",
          });
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.statusText,
          });
        }

        if (error.response.status == 403) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.data.info.message,
          });
        }
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Periksa koneksi jaringan internet Anda ",
        });
      }
      console.log("Error : ", error.response);
    }
  }

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/mail");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  useEffect(() => {
    getInboxDetail();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar
          title={
            type !== "notification" ? "REQUEST DETAIL" : "NOTIFICATION DETAIL"
          }
        />
      </AppBar>

      <Card
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#fff",
          maxWidth: VALUES.maxScreenWidth,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Avatar
                src={inboxDetail.photo_url}
                style={{
                  marginTop: 20,
                  width: "100px",
                  height: "100px",
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  color: "#221E5B",
                  fontWeight: "bold",
                }}
              >
                {inboxDetail.first_name} {inboxDetail.last_name}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {generateTitle(type)}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                }}
              >
                {moment(inboxDetail.created_at)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ marginTop: 20 }}>
            {inboxDetail.first_name} {inboxDetail.last_name} mengirim{" "}
            {generateTitle(type)}
          </Typography>
          {type === "request_exception" && (
            <>
              <Typography variant="subtitle1">
                Jadwal :{" "}
                {moment(inboxDetail.date_before)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
              <Typography variant="subtitle1">
                Pindah Ke :{" "}
                {moment(inboxDetail.date_after)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
            </>
          )}
          {type === "request_attendance" && (
            <>
              <Typography variant="subtitle1">
                Pada Tanggal :{" "}
                {moment(inboxDetail.workday)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
              {inboxDetail.clock_in !== null && (
                <Typography variant="subtitle1">
                  Jam Masuk :{" "}
                  {moment(inboxDetail.clock_in).locale("id").format("HH:mm")}
                </Typography>
              )}
              {inboxDetail.clock_out !== null && (
                <Typography variant="subtitle1">
                  Jam Pulang :{" "}
                  {moment(inboxDetail.clock_out).locale("id").format("HH:mm")}
                </Typography>
              )}
              {inboxDetail.clock_in_note === inboxDetail.clock_out_note ? (
                <Typography variant="subtitle1">
                  Deskripsi :{" "}
                  {inboxDetail.clock_in_note || inboxDetail.clock_out_note}
                </Typography>
              ) : (
                <>
                  {inboxDetail.clock_in_note !== null &&
                    inboxDetail.clock_in_note !== "" && (
                      <Typography variant="subtitle1">
                        Deskripsi : {inboxDetail.clock_in_note}
                      </Typography>
                    )}
                  {inboxDetail.clock_out_note !== null &&
                    inboxDetail.clock_out_note !== "" && (
                      <Typography variant="subtitle1">
                        Deskripsi : {inboxDetail.clock_out_note}
                      </Typography>
                    )}
                </>
              )}
            </>
          )}
          {type === "request_time_off" && (
            <>
              <Typography variant="subtitle1">
                Tanggal Mulai :{" "}
                {moment(inboxDetail.schedule_in)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
              <Typography variant="subtitle1">
                Tanggal Selesai :{" "}
                {moment(inboxDetail.schedule_out)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
              <Typography variant="subtitle1">
                File Bukti :{" "}
                <b>
                  <a href={inboxDetail.file} target="_blank">
                    Download
                  </a>
                </b>
              </Typography>
            </>
          )}
          {type === "request_overtime" && (
            <>
              <Typography variant="subtitle1">
                Tanggal :{" "}
                {moment(inboxDetail.workday)
                  .locale("id")
                  .format("dddd, D MMMM YYYY")}
              </Typography>
              <Typography variant="subtitle1">
                Durasi Overtime : {parseInt(inboxDetail.overtime_duration) / 60}{" "}
                Menit
              </Typography>
              <Typography variant="subtitle1">
                Durasi Istirahat : {parseInt(inboxDetail.rest_duration) / 60}{" "}
                Menit
              </Typography>
            </>
          )}
          {inboxDetail.description != null &&
            inboxDetail.description !== "" && (
              <Typography variant="subtitle1">
                Desc : {inboxDetail.description}
              </Typography>
            )}
          <Typography variant="subtitle1">
            Status :{" "}
            <b
              style={{
                color: COLORS.redPrimary,
              }}
            >
              {inboxDetail.status_approve !== "99" &&
                "Menunggu Persetujuan Atasan"}
            </b>
          </Typography>

          {type !== "notification" && (
            <>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 40,
                }}
              >
                Disetujui ?
              </Typography>
              <Grid
                container
                spacing={3}
                style={{
                  marginTop: "1rem",
                }}
              >
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleRequestReject(id, type);
                    }}
                    style={{
                      height: "3rem",
                    }}
                    disabled={loading}
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleRequestApprove(id, type);
                    }}
                    style={{
                      height: "3rem",
                    }}
                    disabled={loading}
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>

      <ErrorDialog
        open={status.status === "error"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <SuccessDialog
        open={status.status === "success"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default InboxDetail;
