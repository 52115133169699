import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import axios from "axios";
import { navigate } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import exportImage from "../../assets/icons/export.svg";
import calenderImage from "../../assets/images/calender.png";
import noteImage from "../../assets/images/note.png";
import BackToolbar from "../../components/BackToolbar";
import AppLoader from "../../components/Loader/AppLoader";
import { COLORS } from "../../helper/constants/colors";
import { VALUES } from "../../helper/constants/ValueConstants";
import { Endpoint } from "../../helper/utils/ApiEndpoint";
import { getToken, isAuthenticated } from "../../helper/utils/AuthData";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    marginBottom: 100,
  },
  absenceCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
    borderRadius: 11,
    padding: "0px 20px",
    margin: "20px 0px",
  },
  presenceCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)",
    borderRadius: 11,
    padding: "0px 20px",
  },
  exportButton: {
    display: "flex",
    borderRadius: 11,
    border: `2px solid ${COLORS.purple}`,
    justifyContent: "center",
    alignItems: "flex-end",
    gap: "20px",
    padding: "20px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#9B1B2D",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#EC5267",
  },
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box style={{ padding: "0px 25px" }}>{children}</Box>}
    </div>
  );
}

function ReportPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(0);
  const [status, setStatus] = useState({});
  const [value, setValue] = useState(0);
  const [data, setData] = useState({ absence: {}, presence: {} });

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
    fetchAbsence();
    fetchPresence();
  }, []);

  const fetchAbsence = async () => {
    try {
      setLoading((prevValue) => prevValue + 1);
      const response = await axios.get(
        `${Endpoint}human-resource/report-account/absence`,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setStatus({ status: "success", message: response.data.info.message });
      setData((prevValue) => ({ ...prevValue, absence: response.data.data }));
    } catch (error) {
      setStatus({
        status: "error",
        message: error.response?.data?.info.message,
      });
    } finally {
      setLoading((prevValue) => prevValue - 1);
    }
  };

  const fetchPresence = async () => {
    try {
      setLoading((prevValue) => prevValue + 1);
      const response = await axios.get(
        `${Endpoint}human-resource/report-account/presence`,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setStatus({ status: "success", message: response.data.info.message });
      setData((prevValue) => ({ ...prevValue, presence: response.data.data }));
    } catch (error) {
      setStatus({
        status: "error",
        message: error.response?.data?.info.message,
      });
    } finally {
      setLoading((prevValue) => prevValue - 1);
    }
  };

  const handleDownload = () => {
    console.log(value);
    if (value === 0) {
      window.location.assign(data.presence.export_url);
    } else {
      window.location.assign(data.absence.export_url);
    }
  };

  const closeLoader = () => {
    setLoading(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <BackToolbar title={"Report"} />
        <div style={{ paddingTop: 10 }}>
          <Card className={classes.presenceCard}>
            <CardContent style={{ flex: "1 0 auto" }}>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                <DateRange />
                <Typography variant="subtitle1">
                  <b>Kehadiran anda bulan ini</b>
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <img src={noteImage} alt="attendance" width={90} />
                </Grid>
                <Grid item xs={8}>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginTop: 10, marginBottom: 5 }}
                  >
                    <Grid item xs={9}>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "white" }}
                      >
                        {data.presence.kehadiran ? (
                          <>
                            <b>{data.presence.kehadiran}</b>/
                            {moment().daysInMonth()} Kehadiran
                          </>
                        ) : (
                          <>-</>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "white", textAlign: "right" }}
                      >
                        {data.presence.kehadiran ? (
                          <b>
                            {Math.floor(
                              (data.presence.kehadiran /
                                moment().daysInMonth()) *
                                100
                            )}
                            %
                          </b>
                        ) : (
                          <>-</>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      data.presence.kehadiran
                        ? Math.floor(
                            (data.presence.kehadiran / moment().daysInMonth()) *
                              100
                          )
                        : 0
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.absenceCard}>
            <CardContent style={{ flex: "1 0 auto" }}>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  gap: "10px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                <DateRange />
                <Typography variant="subtitle1">
                  <b>Cuti anda bulan ini</b>
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <img src={calenderImage} alt="attendance" width={90} />
                </Grid>
                <Grid item xs={8}>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginTop: 10, marginBottom: 5 }}
                  >
                    <Grid item xs={9}>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "white" }}
                      >
                        {data.absence.allowance ? (
                          <>
                            <b>
                              {data.absence.allowance - data.absence.remaining}
                            </b>
                            /{data.absence.allowance} Jatah Cuti
                          </>
                        ) : (
                          <>-</>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "white", textAlign: "right" }}
                      >
                        {data.absence.allowance ? (
                          <b>
                            {Math.floor(
                              ((data.absence.allowance -
                                data.absence.remaining) /
                                data.absence.allowance) *
                                100
                            )}
                            %
                          </b>
                        ) : (
                          <>-</>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      data.absence.allowance
                        ? Math.floor(
                            ((data.absence.allowance - data.absence.remaining) /
                              data.absence.allowance) *
                              100
                          )
                        : 0
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div>
            <Typography
              variant="h5"
              style={{ color: COLORS.purple, margin: "20px 0px" }}
            >
              <b>Report Detail</b>
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  style={{ textColor: COLORS.gray1 }}
                  textColor="primary"
                  indicatorColor="primary"
                  value={value}
                  onChange={handleChange}
                  aria-label="report-tab"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      borderBottom: `3px solid ${COLORS.purple}`,
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Tab label="Kehadiran" />
                  <Tab label="Cuti" />
                </Tabs>
              </Box>
              <a
                className={classes.exportButton}
                href={
                  value === 0
                    ? data.presence.export_url
                    : data.absence.export_url
                }
                download={value === 0 ? "presence.pdf" : "absence.pdf"}
                style={{ textDecoration: "none", marginTop: "1rem" }}
              >
                <img src={exportImage} style={{ width: "2em" }} alt="" />
                <Typography
                  variant="h5"
                  style={{ color: COLORS.purple, marginBottom: "-4px" }}
                >
                  <b>Export Data {value === 0 ? "Kehadiran" : "Cuti"}</b>
                </Typography>
              </a>
              <TabPanel value={value} index={0}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                    margin: "20px 0px",
                  }}
                >
                  {data.presence.list?.map((item) => (
                    <Card
                      style={{
                        padding: "10px",
                        color: "#221E5B",
                        borderRadius: "10px",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <DateRange />
                          <Typography variant="h6">
                            <b>{item.tanggal}</b>
                          </Typography>
                        </div>
                        <Typography
                          variant="subtitle1"
                          style={{ color: COLORS.textColorGray2 }}
                        >
                          Pukul : {item.pukul}
                        </Typography>
                        <Typography variant="subtitle2">
                          <b>Lokasi : {item.lokasi}</b>
                        </Typography>
                        <Typography variant="subtitle2">
                          <b>Status : Hadir</b>
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                    margin: "20px 0px",
                  }}
                >
                  {data.absence.list?.map((item) => (
                    <Card
                      style={{
                        padding: "10px",
                        color: "#221E5B",
                        borderRadius: "10px",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <DateRange />
                          <Typography variant="h6">
                            <b>{item.tanggal}</b>
                          </Typography>
                        </div>
                        <Typography
                          variant="subtitle1"
                          style={{ color: COLORS.textColorGray2 }}
                        >
                          Pukul : {item.pukul}
                        </Typography>
                        <Typography variant="subtitle2">
                          <b>Status : {item.status}</b>
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      <AppLoader
        isLoaderOpen={loading > 0}
        onRequestClose={closeLoader}
        loaderText="Fetching Data ..."
      />
    </>
  );
}

export default ReportPage;
