import { IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { navigate } from "hookrouter";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
    color: "#221E5B",
  },
}));

function BackToolbar({ title, url }) {
  const classes = useStyles();

  return (
    <Toolbar>
      <IconButton
        edge="start"
        className={classes.backButton}
        onClick={() => (url ? navigate(url) : window.history.go(-1))}
      >
        <KeyboardBackspace />
      </IconButton>
      <Typography
        variant="h6"
        component={"span"}
        style={{ flexGrow: 1, color: "#221E5B" }}
      >
        {title}
      </Typography>
    </Toolbar>
  );
}

export default BackToolbar;
