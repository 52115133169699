import { Button, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
//import material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  AccessAlarm,
  AddAlarm,
  DateRange,
  EventAvailable,
  HourglassEmpty,
  InsertDriveFile,
  Room,
  TimerOff,
  Today,
} from "@material-ui/icons";
import { navigate } from "hookrouter";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import reportImage from "../../assets/icons/report.svg";
import clockImage from "../../assets/images/clock.png";
import noteImage from "../../assets/images/note.png";
import userImage from "../../assets/images/user.png";
import BottomNavigation from "../../components/BottomNavigation";
import PreRequestAttendance from "../../components/HOC/PreRequestAttendance";
import Picture from "../../components/List/Picture";
import AppLoader from "../../components/Loader/AppLoader";
import { AUTH_CONSTANT } from "../../helper/constants/AuthConstant";
import { COLORS } from "../../helper/constants/colors";
import { VALUES } from "../../helper/constants/ValueConstants";

//import custom lib
import { isAuthenticated } from "../../helper/utils/AuthData";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    marginBottom: 100,
  },
  headerCard: {
    display: "flex",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    background: "linear-gradient(to right bottom, #221E5B 30%, #3f51b5 90%)",
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  headerCardDetail: {
    display: "flex",
    flexDirection: "column",
  },
  headerCardContent: {
    flex: "1 0 auto",
  },
  headerCardAvatar: {
    backgroundColor: COLORS.white,
    color: COLORS.purple,
    width: 50,
    height: 50,
  },
  clockInCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #221E5B 30%, #3f51b5 90%)",
    borderRadius: 11,
    padding: "10px 20px",
    margin: "10px 10px",
  },
  scheduleCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
    borderRadius: 11,
    padding: "10px 20px",
    margin: "10px 10px",
  },
  attendanceCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)",
    borderRadius: 11,
    padding: "10px 20px",
    margin: "10px 10px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#9B1B2D",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#EC5267",
  },
}))(LinearProgress);

const MainPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState({});
  const [timeRemaining, setTimeRemaining] = useState({
    hours: "",
    minutes: "",
  });
  const today = new Date();
  const tomorrow = new Date(today);
  const verificationMethod = useSelector((state) => state.verificationMethod);
  tomorrow.setDate(tomorrow.getDate() - 10);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      let jsonAuthData = JSON.parse(localStorage.getItem(AUTH_CONSTANT.data));
      setAuthData(jsonAuthData);

      parseTimeRemaining(verificationMethod.data);
    } /* eslint-disable-line */,
    []
  );

  useEffect(() => {
    if (!isEmpty(verificationMethod.data) && verificationMethod.status) {
      const timeInterval = setInterval(() => {
        parseTimeRemaining();
      }, 200);
      return () => {
        clearInterval(timeInterval);
      };
    }
  }, [verificationMethod.data]);

  const parseTimeRemaining = () => {
    const currentTime = moment();
    let duration;
    const clockInTime = moment(
      verificationMethod.data.shift?.schedule_in,
      "HH:mm"
    );
    // const currentClockOut = moment(verificationMethod.data.shift?.schedule_out, 'HH:mm');

    if (
      verificationMethod.clockStatus === "clockin" &&
      currentTime.isBefore(clockInTime)
    ) {
      // For Clock In
      // the before used to check if the user dont clock in even after the countdown was passed
      // then its gonna continue to the next else statement on the bottom
      duration = moment.duration(clockInTime.diff(currentTime));
    } else if (verificationMethod.clockStatus !== "done") {
      /*
       * For Clock Out
       * why the condition done is because to check if the user already clock in and clock out
       * if the user already clock in and out then this if statement will not get executed
       */
      const parseCurrentClockIn = parseTime(
        verificationMethod.data.clock_in?.clock_in
      );
      const currentClockIn = moment(parseCurrentClockIn, "HH:mm");
      duration = moment.duration(currentTime.diff(currentClockIn));
    }

    // check if duration valid
    if (duration?.isValid()) {
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      setTimeRemaining({
        hours: hours.toString(),
        minutes: minutes.toString(),
      });
    } else {
      setTimeRemaining({ hours: "0", minutes: "0" });
    }
  };

  const parseTime = (date) => {
    if (!date) return;
    let time = date.split(" ");
    let timeArr = time[1].split(":");
    return `${timeArr[0]}.${timeArr[1]}`;
  };

  const closeLoader = () => {
    setLoading(false);
  };

  const navigateClockIn = () => {
    if (verificationMethod.data?.verification.verification_member_pin === 1) {
      navigate("/live-attendance/pin");
    } else if (
      verificationMethod.data?.verification.verification_member_password ===
        1 ||
      verificationMethod.data?.verification.verification_thumb_recognition === 1
    ) {
      navigate("/live-attendance/password");
    } else {
      navigate("/live-attendance/clock-in-out");
    }
  };

  return (
    <PreRequestAttendance>
      <div className={classes.root}>
        <Card className={classes.headerCard}>
          <CardContent className={classes.headerCardContent}>
            <Typography variant="h5" style={{ color: "white", marginTop: 50 }}>
              Hai, {authData.data?.member_first_name}{" "}
              {authData.data?.member_last_name}
            </Typography>
            <Typography variant="subtitle1" style={{ color: "white" }}>
              {verificationMethod.data?.work_pattern?.name}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "white" }}>
              di {verificationMethod.data?.schedule?.name}
            </Typography>
            <Picture
              className={classes.headerCardAvatar}
              style={{ marginTop: -60, marginLeft: "auto", zIndex: 10 }}
              picture={userImage}
            ></Picture>
          </CardContent>
        </Card>
        <div style={{ paddingTop: 10 }}>
          <Grid
            style={{ justifyContent: "space-between" }}
            container
            spacing={1}
          >
            <Grid item xs={2} onClick={() => navigate("/live-attendance")}>
              <IconButton
                aria-label="My Attendance"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <Room style={{ color: "#221E5B" }} />
              </IconButton>
              <p
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  fontSize: 8,
                  color: "#262261",
                }}
              >
                My Attendance
              </p>
            </Grid>
            <Grid item xs={2} onClick={() => navigate("/my-schedule")}>
              <IconButton
                aria-label="My Schedule"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <EventAvailable style={{ color: "#221E5B" }} />
              </IconButton>
              <p
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  fontSize: 8,
                  color: "#262261",
                }}
              >
                My Schedule
              </p>
            </Grid>
            <Grid item xs={2} onClick={() => navigate("/overtime")}>
              <IconButton
                aria-label="Overtime"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <AddAlarm style={{ color: "#221E5B" }} />
              </IconButton>
              <p
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  fontSize: 8,
                  color: "#262261",
                }}
              >
                Overtime
              </p>
            </Grid>
            <Grid item xs={2} onClick={() => navigate("/time-off")}>
              <IconButton
                aria-label="Time Off"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <TimerOff style={{ color: "#221E5B" }} />
              </IconButton>
              <p
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  fontSize: 8,
                  color: "#262261",
                }}
              >
                Time Off
              </p>
            </Grid>
            <Grid item xs={2} onClick={() => navigate("/report")}>
              <IconButton
                aria-label="Report"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <img
                  style={{ width: "1em", height: "1em" }}
                  src={reportImage}
                  alt="report"
                />
              </IconButton>
              <p
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  fontSize: 8,
                  color: "#262261",
                }}
              >
                Report
              </p>
            </Grid>
          </Grid>
        </div>

        {/* Today Schedule Card */}
        {verificationMethod.clockStatus !== "done" && (
          <div style={{ paddingTop: 10 }}>
            <Card className={classes.clockInCard}>
              <CardContent style={{ flex: "1 0 auto" }}>
                {verificationMethod.data?.clock_in ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <div
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <HourglassEmpty />
                          <span>&nbsp;Durasi Kerja Berjalan</span>
                        </div>
                        <Typography
                          variant="h5"
                          style={{ color: "white", marginTop: 20 }}
                        >
                          {`${timeRemaining.hours} Jam ${timeRemaining.minutes} Menit`}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ color: "white" }}
                        >
                          Anda sedang clock in
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#CE354A",
                            marginTop: 30,
                            height: 50,
                          }}
                          fullWidth
                          onClick={() => navigateClockIn()}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#fff" }}
                          >
                            Clock Out
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      style={{
                        marginTop: 20,
                        backgroundColor: "#fff",
                        height: "3rem",
                      }}
                      fullWidth
                      onClick={() => {
                        navigate("/live-check-in-out");
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#262261" }}
                      >
                        Check In
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={7}>
                      <div
                        style={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Today />
                        <span>&nbsp;Jadwal Kerja Hari Ini</span>
                      </div>
                      <Typography
                        variant="h5"
                        style={{ color: "white", marginTop: 20 }}
                      >
                        {verificationMethod.data.shift?.schedule_in} -{" "}
                        {verificationMethod.data.shift?.schedule_out}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "white" }}
                      >
                        {`${timeRemaining.hours} Jam ${timeRemaining.minutes} Menit lagi`}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "white",
                          marginTop: 30,
                          height: 50,
                        }}
                        fullWidth
                        onClick={() => navigateClockIn()}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ color: COLORS.purple }}
                        >
                          Clock In
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </div>
        )}

        {/* Tomorrow Schedule Card */}
        {verificationMethod.data?.tomorrow_schedule && (
          <div style={{ paddingTop: 10 }}>
            <Card className={classes.scheduleCard}>
              <CardContent style={{ flex: "1 0 auto" }}>
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <AccessAlarm />
                      <span>&nbsp;Jadwal Kerja Besok</span>
                    </div>
                    <Typography
                      variant="h5"
                      style={{ color: "white", marginTop: 20 }}
                    >
                      {
                        verificationMethod.data?.tomorrow_schedule.shift
                          .schedule_in
                      }{" "}
                      -{" "}
                      {
                        verificationMethod.data?.tomorrow_schedule.shift
                          .schedule_out
                      }
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                      <Moment
                        add={{ days: 1 }}
                        format="dddd, D MMMM YYYY"
                        tz="Asia/Jakarta"
                        locale="id"
                        interval={1}
                        unit="seconds"
                      ></Moment>
                    </Typography>
                    <Typography variant="body2" style={{ color: "white" }}>
                      <b>
                        di {verificationMethod.data?.tomorrow_schedule.name}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <img src={clockImage} alt="schedule" width={150} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        )}
        <div style={{ paddingTop: 10 }}>
          <Card className={classes.attendanceCard}>
            <CardContent style={{ flex: "1 0 auto" }}>
              <Grid container spacing={1}>
                <Grid item md={5} xs={4}>
                  <img
                    src={noteImage}
                    alt="attendance"
                    width={
                      VALUES.maxScreenWidth > window.screen.width ? 100 : 150
                    }
                  />
                </Grid>
                <Grid item md={7} xs={8}>
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <DateRange />
                    <span>&nbsp;Absensi Anda Bulan Ini</span>
                  </div>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginTop:
                        VALUES.maxScreenWidth > window.screen.width ? 20 : 50,
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography variant="body2" style={{ color: "white" }}>
                        <b>15</b>/30 Kehadiran
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        style={{ color: "white", textAlign: "right" }}
                      >
                        50%
                      </Typography>
                    </Grid>
                  </Grid>
                  <BorderLinearProgress variant="determinate" value={50} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <BottomNavigation />
      </div>
      <AppLoader
        isLoaderOpen={loading || verificationMethod.loading}
        onRequestClose={closeLoader}
        loaderText="Fetching Data ..."
      />
    </PreRequestAttendance>
  );
};

export default MainPage;
