import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
import axios from "axios";

//import material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { Container, Row, Col, Image } from "react-bootstrap";

//import component lib
import { FilePicker } from "react-file-picker";

//import image assets
import iconDate from "../../../assets/icons/ic_calendar_circle_gray.png";

//import custom lib
import { COLORS } from "../../../helper/constants/colors";
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";

//import custom component
import AppLoader from "../../../components/Loader/AppLoader";

//css style
const useStyles = makeStyles({
  root: {
    width: "100%",
    minWidth: 200,
    borderRadius: 15,
    backgroundColor: "white",
    border: "1px solid lightGray",
    //padding: 15,
    //margin: 20,
  },
  container: {
    padding: 20,
  },
  textField: {
    width: "100%",
    borderRadius: 5,
    height: 40,
    backgroundColor: COLORS.gray2,
    border: "0px solid #cfd0d1",
    padding: 5,
    boxSizing: "border-box",
  },
  textArea: {
    width: "100%",
    maxWidth: "100%",
    resize: "none",
    borderRadius: 5,
    backgroundColor: COLORS.gray2,
    border: "0px solid #cfd0d1",
    padding: 5,
    boxSizing: "border-box",
  },
  dateTextField: {
    fontSize: 10,
    width: 150,
    padding: 0,
  },
  submitButton: {
    minWidth: 90,
    backgroundColor: COLORS.redPrimary,
    height: 35,
    textAlign: "center",
    color: "white",
    border: "0px solid white",
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    outline: 0,
    boxShadow: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.redprimaryDark,
      opacity: 1,
      transition: "all .2s ease-in-out",
    },
    "&:focus": {
      backgroundColor: COLORS.redprimaryDark,
    },
  },
});

const MeasuredActivityResultFormCreate = ({
  measuredActivityId,
  onCloseFormDialog,
  action,
  existingData,
}) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(
    new Date()
    //new Date("2021-01-01T23:59:59")
  );
  const [selectedTime, setSelectedTime] = useState(
    new Date()
    //new Date("2021-01-01T23:59:59")
  );
  const [loading, setLoading] = useState(false);
  //MA Result Param
  const [resultValue, setResultValue] = useState({
    result_value: "",
    result_date: "",
    result_time: "",
    description: "",
    evidence_category_id: "f436ea12-c1ec-4f1f-a0d0-c8e1ad9763ab", //attachment
    evidence_content: null,
    evidence_latitude: "",
    evidence_longitude: "",
  });

  const [fileName, setFileName] = useState("");
  const [errorText, setErrortext] = useState("");
  const [isEvidenceRequired, setIsEvidenceRequired] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageInfo, setMessageInfo] = useState("Performate");

  useEffect(() => {
    let date = new Date();
    let formattedDate = format(date, "yyyy-MM-dd");
    let formattedTime = format(date, "HH:mm:ss");

    if (action == "edit") {
      //set default value if action is "edit"
      setResultValue({
        ...resultValue,
        ["result_date"]: existingData.result_date,
        ["result_time"]: existingData.result_time,
        ["result_value"]: existingData.result_value,
        ["description"]: existingData.description,
      });

      let dateString =
        existingData.result_date + " " + existingData.result_time;
      let existingDateTime = new Date(dateString);
      setSelectedDate(existingDateTime);
      setSelectedTime(existingDateTime);
    } else {
      //set default value if action is "add"
      setResultValue({
        ...resultValue,
        ["result_date"]: formattedDate,
        ["result_time"]: formattedTime,
      });
    }
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    let formattedDate = format(date, "yyyy-MM-dd");
    //console.log("date 0", formattedDate);
    setResultValue({ ...resultValue, ["result_date"]: formattedDate });
  };

  const handleTimeChange = (date) => {
    setSelectedTime(date);
    let formattedTime = format(date, "HH:mm:ss");
    //console.log("date 0", formattedDate);
    setResultValue({ ...resultValue, ["result_time"]: formattedTime });
  };

  const handleChange = (prop) => (event) => {
    console.log("props", prop);
    //event.preventDefault();
    setResultValue({ ...resultValue, [prop]: event.target.value });
  };

  function closeLoader() {
    setLoading(false);
  }

  const injectTheme = () => {
    let node = document.getElementById("datepicker-arrow-right").parentNode
      .parentNode.parentNode.parentNode.parentNode.parentElement;
    node.classList.add("Css-Class-Name");
  };

  const handleSubmit = () => {
    console.log("date 2", resultValue.result_date);
    console.log("time 2", resultValue.result_time);
    console.log("res val", resultValue.result_value);

    if (resultValue.result_value === "") {
      //alert("Masukkan nilai pencapaian");
      setMessageInfo("Masukkan nilai pencapaian");
      setIsDialogOpen(true);
    } else {
      setLoading(true);
      //let formData1 = JSON.stringify({ MeasuredActivityResult: resultValue });

      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "MeasuredActivityResult[result_value]",
        resultValue.result_value
      );
      formData.append(
        "MeasuredActivityResult[result_date]",
        resultValue.result_date
      );
      formData.append(
        "MeasuredActivityResult[result_time]",
        resultValue.result_time
      );
      formData.append(
        "MeasuredActivityResult[description]",
        resultValue.description
      );

      if (fileName !== "") {
        formData.append(
          "MeasuredActivityResult[evidence_category_id]",
          resultValue.evidence_category_id
        );
        formData.append(
          "MeasuredActivityResult[evidence_content]",
          resultValue.evidence_content
        );

        formData.append(
          "MeasuredActivityResult[evidence_description]",
          resultValue.evidence_content.name
        );
      }

      //formData.append("MeasuredActivityResult[evidence_latitude]", "");
      //formData.append("MeasuredActivityResult[evidence_longitude]", "");

      axios
        .post(
          Endpoint + "swd/measured-activity/" + measuredActivityId + "/result",
          formData,
          {
            headers: {
              Accept: "application/json",
              //"Content-Type": "application/json",
              "content-type": "multipart/form-data",
              //"Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + getToken(),
            },
          }
        )
        .then((response) => {
          setIsSuccess(true);
          setLoading(false);
          const responseData = response.data;
          //console.log("response", JSON.stringify(responseData));
          //alert("Sukses, MA result berhasil disimpan !");

          setMessageInfo("Sukses, Measured Activity result berhasil disimpan.");
          setIsDialogOpen(true);

          //onCloseFormDialog();

          /*
          setTimeout(() => {
            window.history.go(-1);
          }, 3000);
          */
        })
        .catch((error) => {
          setLoading(false);
          try {
            const response = error.response;
            const statusCode = response.status;
            const responseData = response.data;
            console.log("statusCode", statusCode);
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            if (statusCode == 401) {
              console.log("unauthorized", statusCode);
              localStorage.clear();
              navigate("/");
            } else {
              let infoMessage = responseData.info.message;
              let infoError = "";
              if (responseData.info.errors.length > 0) {
                infoError = responseData.info.errors[0].description;
              }
              console.log("infoMessage", infoMessage);
              console.log("infoError", infoError);

              if (infoError != "") {
                infoMessage = infoError;
              }

              //alert(infoMessage);
              //setErrortext(infoMessage);
              setMessageInfo(infoMessage);
              setIsDialogOpen(true);
            }
          } catch (errorCatch) {
            setMessageInfo(errorCatch);
            setIsDialogOpen(true);
          }
        });
    }
  };

  const handleSubmitUpdate = () => {
    let measuredActivityResultId = existingData.id;

    console.log("date 2", resultValue.result_date);
    console.log("time 2", resultValue.result_time);
    console.log("res val", resultValue.result_value);

    if (resultValue.result_value === "") {
      //alert("Masukkan nilai pencapaian");
      setMessageInfo("Masukkan nilai pencapaian");
      setIsDialogOpen(true);
    } else {
      setLoading(true);
      //let formData1 = JSON.stringify({ MeasuredActivityResult: resultValue });

      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "MeasuredActivityResult[result_value]",
        resultValue.result_value
      );
      formData.append(
        "MeasuredActivityResult[result_date]",
        resultValue.result_date
      );
      formData.append(
        "MeasuredActivityResult[result_time]",
        resultValue.result_time
      );
      formData.append(
        "MeasuredActivityResult[description]",
        resultValue.description
      );

      if (fileName !== "") {
        formData.append(
          "MeasuredActivityResult[evidence_category_id]",
          resultValue.evidence_category_id
        );
        formData.append(
          "MeasuredActivityResult[evidence_content]",
          resultValue.evidence_content
        );

        formData.append(
          "MeasuredActivityResult[evidence_description]",
          resultValue.evidence_content.name
        );
      }

      formData.append("_method", "PUT");

      //formData.append("MeasuredActivityResult[evidence_latitude]", "");
      //formData.append("MeasuredActivityResult[evidence_longitude]", "");

      axios
        .post(
          Endpoint +
            "swd/measured-activity/" +
            measuredActivityId +
            "/result/" +
            measuredActivityResultId,
          formData,
          {
            headers: {
              Accept: "application/json",
              //"Content-Type": "application/json",
              "content-type": "multipart/form-data",
              //"Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + getToken(),
            },
          }
        )
        .then((response) => {
          setIsSuccess(true);
          setLoading(false);
          const responseData = response.data;
          //console.log("response", JSON.stringify(responseData));
          //alert("Sukses, MA result berhasil disimpan !");

          setMessageInfo("Sukses, Measured Activity result berhasil di ubah.");
          setIsDialogOpen(true);

          //onCloseFormDialog();

          /*
          setTimeout(() => {
            window.history.go(-1);
          }, 3000);
          */
        })
        .catch((error) => {
          setLoading(false);
          try {
            const response = error.response;
            const statusCode = response.status;
            const responseData = response.data;
            console.log("statusCode", statusCode);
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            if (statusCode == 401) {
              console.log("unauthorized", statusCode);
              localStorage.clear();
              navigate("/");
            } else {
              let infoMessage = responseData.info.message;
              let infoError = "";
              if (responseData.info.errors.length > 0) {
                infoError = responseData.info.errors[0].description;
              }
              console.log("infoMessage", infoMessage);
              console.log("infoError", infoError);

              if (infoError != "") {
                infoMessage = infoError;
              }

              //alert(infoMessage);
              //setErrortext(infoMessage);
              setMessageInfo(infoMessage);
              setIsDialogOpen(true);
            }
          } catch (errorCatch) {
            setMessageInfo(errorCatch);
            setIsDialogOpen(true);
          }
        });
    }
  };

  const handleFileChange = (file) => {
    /*
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
      this.extractWordRawText(e.target.result);
    };
    */

    //this.setState({ title: file.name });
    console.log("filename", file.name);

    setFileName(file.name);
    setErrortext("");

    setResultValue({ ...resultValue, ["evidence_content"]: file });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (isSuccess) {
      onCloseFormDialog();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          style={{ fontSize: 15, fontWeight: "bold" }}
          color="textPrimary"
        >
          {action === "edit" ? (
            <label>Ubah Pencapaian</label>
          ) : (
            <label>Tambah Pencapaian</label>
          )}
        </Typography>

        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
          <Image
            src={iconDate}
            roundedCircle
            style={{ width: 25, height: 25, marginRight: 10 }}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <KeyboardDatePicker
              placeholder="01/01/2021"
              value={selectedDate}
              onChange={(date) => handleDateChange(date)}
              format="dd/MM/yyyy"
              style={{ width: 150 }}
            /> */}

            <DatePicker
              //autoOk
              //clearable
              //disableFuture
              //label="Clearable"
              format="dd MMM yyyy"
              views={["year", "month", "date"]}
              value={selectedDate}
              onChange={handleDateChange}
              style={{ width: 100, fontSize: 10 }}
            />

            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes", "seconds"]}
              format="HH:mm:ss"
              //label="With seconds"
              value={selectedTime}
              onChange={handleTimeChange}
              style={{ marginLeft: 20, width: 70 }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Typography
          style={{ fontSize: 13, marginTop: 20, marginBottom: 5 }}
          color="textSecondary"
        >
          Masukkan nilai pencapaian
        </Typography>

        <input
          key="result_value"
          id="result-value"
          type="number"
          className={classes.textField}
          value={resultValue.result_value}
          placeholder=""
          onChange={handleChange("result_value")}
        />
        <br />
        <Typography
          style={{ fontSize: 13, marginTop: 15, marginBottom: 5 }}
          color="textSecondary"
        >
          Keterangan
        </Typography>
        <textarea
          key="description"
          id="result-description"
          className={classes.textArea}
          defaultValue={resultValue.description}
          rows={3}
          onChange={handleChange("description")}
        />

        <br />
        <FilePicker
          extensions={[
            "doc",
            "docx",
            "xls",
            "xlsx",
            "ppt",
            "pptx",
            "txt",
            "jpg",
            "jpeg",
            "png",
            "gif",
          ]} // Notice that I removed the "."
          onChange={handleFileChange}
          onError={(errMsg) => {
            setErrortext(errMsg);
            setFileName("");
            setResultValue({ ...resultValue, ["evidence_content"]: null });
            console.log(errMsg);
          }} // Please handle error
        >
          <button className={classes.submitButton} style={{ marginTop: 10 }}>
            Tambahkan Bukti
          </button>
        </FilePicker>
        <br />
        <label>{fileName}</label>
        <br />
        <label style={{ color: "red" }}>{errorText}</label>

        <br />
        <br />
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: "auto" }}>
            {/* <button>Tutup</button> */}

            {action === "edit" ? (
              <button
                onClick={handleSubmitUpdate}
                className={classes.submitButton}
                style={{ marginLeft: 10 }}
              >
                Ubah
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className={classes.submitButton}
                style={{ marginLeft: 10 }}
              >
                Simpan
              </button>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Info"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeLoader}
        loaderText="Menyimpan ..."
      />
    </div>
  );
};

export default MeasuredActivityResultFormCreate;
