import { Badge } from '@material-ui/core';
import BottomNavigationMaterial from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Home, Mail, People, Person } from '@material-ui/icons';
import { navigate } from 'hookrouter';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VALUES } from '../helper/constants/ValueConstants';
import { setBottomNavigation } from '../store/Attendance/bottomNavigation';

function BottomNavigation() {
	const bottomNavigation = useSelector(state => state.bottomNavigation);
	const dispatch = useDispatch();

	return (
		<BottomNavigationMaterial
			value={bottomNavigation.index}
			onChange={(event, newValue) => {
				dispatch(setBottomNavigation({ index: newValue }));
			}}
			showLabels
			style={{
				position: 'fixed',
				bottom: 0,
				width: '100%',
				maxWidth: VALUES.maxScreenWidth,
			}}
		>
			<BottomNavigationAction label="Home" icon={<Home />} onClick={() => navigate('/attendance')} />

			<BottomNavigationAction
				label="Mail"
				icon={
					<Badge badgeContent={2} color="secondary">
						<Mail />
					</Badge>
				}
				onClick={() => navigate('/mail')}
			/>
			<BottomNavigationAction label="Team" icon={<People />} onClick={() => navigate('/team')} />
			<BottomNavigationAction label="Profile" icon={<Person />} onClick={() => navigate('/profile')} />
		</BottomNavigationMaterial>
	);
}

export default BottomNavigation;
