import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  CircularProgress,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import { Box, Divider, Grid, List, ListItem } from "@material-ui/core";

//import material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { DateRange } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  timeOffQuotaCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
    borderRadius: 11,
    padding: "10px 20px",
    margin: "10px 10px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const HistoryTimeOff = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [value, setValue] = useState(0);
  const [bulan, setBulan] = useState(moment().format("MM"));
  const [tahun, setTahun] = useState(moment().format("YYYY"));
  const [status, setStatus] = useState({});
  const [dataRaw, setDataRaw] = useState([]);
  const [dataPending, setDataPending] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);
  const [timeoffRemaining, setTimeoffRemaining] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if (newValue === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "99";
      });
      setDataApproved(data);
      console.log(data);
    } else if (newValue === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "1" || item.status_approve === "2";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    console.log(index);
    if (index === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "99";
      });
      setDataApproved(data);
      console.log(data);
    } else if (index === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "1" || item.status_approve === "2";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }

      fatchHistoryTimeOff();
      getTimeoffRemaining();
    } /* eslint-disable-line */,
    [bulan, tahun]
  );

  const getTimeoffRemaining = async () => {
    let response;
    try {
      setLoading(true);
      const token = getToken();
      response = await axios.get(Endpoint + "human-resource/request-timeoff", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setTimeoffRemaining(response.data.data.cuti_list[0].hasUser.remaining);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fatchHistoryTimeOff = async () => {
    let response;
    try {
      setLoading(true);
      response = await axios.get(
        `${Endpoint}human-resource/request-timeoff/history`,
        {
          params: {
            "options[filter][year]": tahun,
            "options[filter][month]": bulan,
            "options[paging][limit]": 5,
            "options[paging][offset]": 0,
          },
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setDataRaw(response.data.data);
      console.log(response.data.data);
      setDataPending(
        response.data.data.filter((item) => {
          return item.status_approve === "1" || item.status_approve === "2";
        })
      );
      setDataApproved(
        response.data.data.filter((item) => {
          return item.status_approve === "99";
        })
      );
      setStatus({ status: "success", message: response.data.info.message });
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  const diffDate = (date1, date2) => {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    var difference = d2.getTime() - d1.getTime();
    var days = Math.ceil(difference / (1000 * 3600 * 24));
    if (days <= 0) {
      var days = 0 + 1;
    }
    return days;
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar title={"Time-Off"} />
      </AppBar>

      <div style={{ paddingTop: 10 }}>
        <Card className={classes.timeOffQuotaCard}>
          <CardContent style={{ flex: "1 0 auto" }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span>Sisa Cuti Tahunan</span>
                </div>
                <Typography variant="h4" style={{ color: "white" }}>
                  <b>{timeoffRemaining} Hari</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFFFFF",
                    color: "#221E5B",
                    height: "100%",
                  }}
                  onClick={() => {
                    navigate("/time-off/quota");
                  }}
                >
                  <b>Lihat Detail</b>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>

      <div
        style={{
          paddingTop: 10,
          paddingRight: 10,
          paddingLeft: 10,
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{
            height: "5rem",
          }}
          onClick={() => {
            navigate("/time-off/request");
          }}
        >
          <b>Ajukan Time-Off</b>
        </Button>
      </div>

      <div
        style={{
          paddingTop: 10,
          paddingRight: 10,
          paddingLeft: 10,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="History Overtime"
          style={{
            marginTop: "1rem",
          }}
        >
          <Tab label="Pengajuan" {...a11yProps(0)} />
          <Tab label="Terpakai" {...a11yProps(1)} />
        </Tabs>
        <FormControl
          variant="outlined"
          fullWidth
          style={{
            marginTop: "20px",
          }}
        >
          <Select
            value={bulan}
            inputProps={{
              name: "month",
              id: "month-filter",
            }}
            style={{
              fontFamily: "Nunito",
              fontWeight: "bold",
              fontSize: "0.8rem",
              color: "#221E5B",
            }}
            startAdornment={
              <InputAdornment position="start">
                <DateRange />
              </InputAdornment>
            }
            onChange={(e) => setBulan(e.target.value)}
          >
            <MenuItem value={"01"}>Januari {tahun}</MenuItem>
            <MenuItem value={"02"}>Februari {tahun}</MenuItem>
            <MenuItem value={"03"}>Maret {tahun}</MenuItem>
            <MenuItem value={"04"}>April {tahun}</MenuItem>
            <MenuItem value={"05"}>Mei {tahun}</MenuItem>
            <MenuItem value={"06"}>Juni {tahun}</MenuItem>
            <MenuItem value={"07"}>Juli {tahun}</MenuItem>
            <MenuItem value={"08"}>Agustus {tahun}</MenuItem>
            <MenuItem value={"09"}>September {tahun}</MenuItem>
            <MenuItem value={"10"}>Oktober {tahun}</MenuItem>
            <MenuItem value={"11"}>November {tahun}</MenuItem>
            <MenuItem value={"12"}>Desember {tahun}</MenuItem>
          </Select>
        </FormControl>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {dataPending?.map((item, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    maxWidth: VALUES.maxScreenWidth,
                    borderRadius: 24,
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <CardContent>
                    <List>
                      <ListItem
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ color: "#221E5B" }}>
                                ● {item.leave_category.name}
                              </b>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              style={{ color: "#221E5B", textAlign: "right" }}
                            >
                              {moment(item.created_at).format(
                                "DD MMMM YYYY"
                              ) === moment().format("DD MMMM YYYY")
                                ? "Hari ini"
                                : moment(item.created_at).format(
                                    "DD MMMM YYYY"
                                  )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography
                          variant="body1"
                          style={{ color: COLORS.redPrimary, marginLeft: 11 }}
                        >
                          <b>Menunggu Persetujuan</b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", marginLeft: 11 }}
                        >
                          Periode :{" "}
                          <b>
                            {diffDate(
                              moment(item.schedule_in).format("MM/DD/YYYY"),
                              moment(item.schedule_out).format("MM/DD/YYYY")
                            )}{" "}
                            Hari (
                            {moment(item.schedule_in).format("DD MMMM YYYY")}{" "}
                            s/d{" "}
                            {moment(item.schedule_out).format("DD MMMM YYYY")})
                          </b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", marginLeft: 11 }}
                        >
                          Deskripsi : <b>{item.description}</b>
                        </Typography>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              );
            })}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {dataApproved?.map((item, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    maxWidth: VALUES.maxScreenWidth,
                    borderRadius: 24,
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <CardContent>
                    <List>
                      <ListItem
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <b style={{ color: "#221E5B" }}>
                                ● {item.leave_category.name}
                              </b>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              style={{ color: "#221E5B", textAlign: "right" }}
                            >
                              {moment(item.created_at).format(
                                "DD MMMM YYYY"
                              ) === moment().format("DD MMMM YYYY")
                                ? "Hari ini"
                                : moment(item.created_at).format(
                                    "DD MMMM YYYY"
                                  )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", marginLeft: 11 }}
                        >
                          <b>Time Off Telah Disetujui</b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", marginLeft: 11 }}
                        >
                          Periode :{" "}
                          <b>
                            {diffDate(
                              moment(item.schedule_in).format("MM/DD/YYYY"),
                              moment(item.schedule_out).format("MM/DD/YYYY")
                            )}{" "}
                            Hari (
                            {moment(item.schedule_in).format("DD MMMM YYYY")}{" "}
                            s/d{" "}
                            {moment(item.schedule_out).format("DD MMMM YYYY")})
                          </b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#221E5B", marginLeft: 11 }}
                        >
                          Deskripsi : <b>{item.description}</b>
                        </Typography>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              );
            })}
          </TabPanel>
        </SwipeableViews>
      </div>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default HistoryTimeOff;
