import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
	div: {
		borderRadius: '50%',
		width: 60,
		height: 60,
		overflow: 'hidden',
		display: 'flex',
		position: 'relative',
	},
	picture: {
		height: '100%',
		position: 'absolute',
		left: '50%',
		transform: 'translate(-50%, 0%)',
	},
}));

function Picture({ picture, pictureProps, className, ...others }) {
	const classes = useStyles();
	return (
		<div className={`${classes.div} ${className}`} {...others}>
			<img src={picture} alt="" className={classes.picture} {...pictureProps} />
		</div>
	);
}

export default Picture;
