import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
import axios from "axios";

//import material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

//import custom lib
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import {
  DecimalFormat,
  DecimalFormatWithPercentage,
  CurrencyFormat,
} from "../../../helper/utils/StringFormatter";

//import custom component
import CircularProgressBar from "../../../components/ProgressBar/CircularProgressBar";
import MeasuredActivityResultCollectionItem from "./MeasuredActivityResultCollectionItem";

//css style
const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    width: "100%",
    //height: "100vh",
    //color: "white",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    //background: "linear-gradient(180deg, #54507d, #25225e)",
  },
  titlebarOuter: {
    display: "table",
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: VALUES.maxScreenWidth,
    backgroundColor: "#54507d",
    height: VALUES.titlebarHeight,
    borderRadius: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const MeasuredActivityResultCollection = ({ measuredActivityId }) => {
  //init makeStyles classes
  const classes = useStyles();

  //state show loader
  const [loading, setLoading] = useState(false);

  //state is request success
  const [isSuccess, setIsSuccess] = useState(false);

  //state set goal result collection
  const [
    measuredActivityResultCollection,
    setMeasuredActivityResultCollection,
  ] = useState([]);

  //state check if data collection exist
  const [isDataExist, setIsDataExist] = useState(false);

  //state ma id from url path [measuredActivityId above is not working]
  const [maId, setMaId] = useState("");

  //run first before load page
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");

      let windowHeight = window.innerHeight - 100;
      //setScreenheight(windowHeight + "px");
      //console.log("windowHeight 2", screenHeight);
    }

    //set measure actiity from path url
    let getMeasuredActivityIdFromUrl = window.location.pathname.split("/")[2];
    setMaId(getMeasuredActivityIdFromUrl);

    //get measured activity collection
    getMeasuredActivityResultCollection();
  }, []);

  //request get measured activity result collection
  const getMeasuredActivityResultCollection = () => {
    let getMeasuredActivityIdFromUrl = window.location.pathname.split("/")[2];

    //set data to empty
    setMeasuredActivityResultCollection([]);

    //show loader
    setLoading(true);

    axios
      .get(
        Endpoint +
          "swd/measured-activity/" +
          getMeasuredActivityIdFromUrl +
          "/result",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        //hide loader
        setLoading(false);

        //set request success true
        setIsSuccess(true);

        //set data collection
        const responseData = response.data;
        setMeasuredActivityResultCollection(responseData.data);

        //get data count
        let dataCount = responseData.data.length;

        //set data exist or not
        if (dataCount > 0) {
          setIsDataExist(true);
        } else {
          setIsDataExist(false);
        }

        //console.log("data count", dataCount);

        //console.log("response ma collection", JSON.stringify(responseData));
      })
      .catch((error) => {
        //hide loader
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  //handle remove ma result item id
  const removeItem = (itemId) => {
    //const items = this.state.items.filter(item => item.id !== itemId);
    //this.setState({ items: items });
    console.log("removed id: ", itemId);

    /*
    for (let i = 0; i < measuredActivityResultCollection.length; i++) {
      if (measuredActivityResultCollection[i].id === itemId) {
        measuredActivityResultCollection.splice(i, 1);
        console.log("removed 1", "success");
      }
    }
    */

    /*
    for (let todo in measuredActivityResultCollection) {
      if (measuredActivityResultCollection[todo].id === itemId) {
        delete measuredActivityResultCollection[todo];
        console.log("removed 2", "success");
      }
    }
    */

    getMeasuredActivityResultCollection();
  };

  return (
    <div className={classes.root}>
      {loading && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 80 }}
        >
          <div>
            <CircularProgressBar />
          </div>
        </div>
      )}

      {!isDataExist && !loading && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <label style={{ fontSize: 13, color: COLORS.textColorGray3 }}>
            Tidak ada Measured Activity Result
          </label>
        </div>
      )}

      {measuredActivityResultCollection.map((item, index) => (
        <MeasuredActivityResultCollectionItem
          key={item.id}
          measuredActivityId={maId}
          measuredActivityResultData={item}
          onRemove={() => removeItem(item.id)}
        />
      ))}
    </div>
  );
};

export default MeasuredActivityResultCollection;
