import { AUTH_CONSTANT } from "../constants/AuthConstant";
import { isJson } from "../validation/Common";

export const getAuthData = () => {
  let value = localStorage.getItem(AUTH_CONSTANT.data);
  //console.log("value", value);
  if (value === null) {
    value = "";
  } else if (!isJson(value)) {
    value = "";
  }

  return value;
};

export const getVerificationMethod = () => {
  let value = localStorage.getItem(AUTH_CONSTANT.verificationMethod);
  console.log(value);
  if (value === null) {
    return "";
  } else if (!isJson(value)) {
    return "";
  }
  return JSON.parse(value);
};

export const isAuthenticated = () => {
  return getToken() === "" ? false : true;
};

export const getToken = () => {
  let value = getAuthData();
  if (value !== "") {
    let data = JSON.parse(value);
    value = data.data.access_token;
  }

  return value;
};
