import {
	FormControl,
	InputAdornment,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import { DateRange } from '@material-ui/icons';
import axios from 'axios';
import { navigate } from 'hookrouter';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BackToolbar from '../../../components/BackToolbar';
import AppLoader from '../../../components/Loader/AppLoader';
import { VALUES } from '../../../helper/constants/ValueConstants';
import { Endpoint } from '../../../helper/utils/ApiEndpoint';
//import custom lib
import { getToken, isAuthenticated } from '../../../helper/utils/AuthData';
import { setHistoryDetail } from '../../../store/Attendance/historyDetail';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
}));

const HistoryClockInOut = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false); //true
	const [bulan, setBulan] = useState(moment().format('MM'));
	const [isClockIn, setIsClockIn] = useState(true);
	const [status, setStatus] = useState({});
	const [data, setData] = useState([]);
	const [dataRaw, setDataRaw] = useState([]);
	const dispatch = useDispatch();

	useEffect(
		() => {
			if (!isAuthenticated()) {
				navigate('/');
			}
			fetchHistory();
		} /* eslint-disable-line */,
		[bulan]
	);

	useEffect(
		() => {
			filterClockIn();
		} /* eslint-disable-line */,
		[bulan, isClockIn, dataRaw]
	);

	const filterClockIn = () => {
		let dataArr = [...dataRaw];
		dataArr = dataArr.map((date, index) => {
			let log = date.log.filter(item => {
				return isClockIn ? item.clock_in != null : item.clock_out != null;
			});
			// untuk memfilter clock in atau clock out
			return { ...date, log };
		});
		setData(dataArr);
	};

	const fetchHistory = async () => {
		let response;
		try {
			setLoading(true);
			response = await axios.get(`${Endpoint}human-resource/attendance/history/clock-in`, {
				params: {
					'options[filter][year]': moment().format('YYYY'),
					'options[filter][month]': moment().format('MM'),
				},
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getToken()}`,
				},
			});
			setData(response.data.data);
			setDataRaw(response.data.data);
			setStatus({ status: 'success', message: response.data.info.message });
		} catch (e) {
			setStatus({ status: 'error', message: e.response?.data?.info.message });
			console.log(e.response);
		} finally {
			setLoading(false);
		}
	};

	const closeLoader = () => {
		setLoading(false);
	};

	const parseDate = date => {
		if (!date) return ``;
		let dateArr = date.split('-');
		let month = parseInt(dateArr[1]) - 1;
		month = moment().month(month).format('MMMM');
		return `${dateArr[2]} ${month}`;
	};

	const parseTime = date => {
		if (!date) return;
		let time = date.split(' ');
		let timeArr = time[1].split(':');
		return `${timeArr[0]}:${timeArr[1]}`;
	};
	
	const navigateHistoryDetail = data => {
		dispatch(setHistoryDetail(data));
		navigate('/history-clock-in-out/detail');
	};

	return (
		<div className={classes.root}>
			<AppBar
				position="static"
				style={{
					background: '#fff',
					borderTopLeftRadius: 24,
					borderTopRightRadius: 24,
				}}
				//elevation={0}
			>
				<BackToolbar title={'History Clock In/Out'} url={"/live-attendance"} />
			</AppBar>

			<FormControl
				variant="outlined"
				fullWidth
				style={{
					marginTop: '20px',
				}}
			>
				<Select
					value={bulan}
					inputProps={{
						name: 'month',
						id: 'month-filter',
					}}
					style={{
						fontFamily: 'Nunito',
						fontWeight: 'bold',
						fontSize: '0.8rem',
						color: '#221E5B',
					}}
					startAdornment={
						<InputAdornment position="start">
							<DateRange />
						</InputAdornment>
					}
					onChange={e => setBulan(e.target.value)}
				>
					<MenuItem value={'01'}>Januari</MenuItem>
					<MenuItem value={'02'}>Februari</MenuItem>
					<MenuItem value={'03'}>Maret</MenuItem>
					<MenuItem value={'04'}>April</MenuItem>
					<MenuItem value={'05'}>Mei</MenuItem>
					<MenuItem value={'06'}>Juni</MenuItem>
					<MenuItem value={'07'}>Juli</MenuItem>
					<MenuItem value={'08'}>Agustus</MenuItem>
					<MenuItem value={'09'}>September</MenuItem>
					<MenuItem value={'10'}>Oktober</MenuItem>
					<MenuItem value={'11'}>November</MenuItem>
					<MenuItem value={'12'}>Desember</MenuItem>
				</Select>
			</FormControl>

			<div style={{ marginTop: '20px' }}>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									style={{
										color: '#262261',
										fontWeight: 'bold',
										fontSize: '1rem',
									}}
								>
									Tanggal
								</TableCell>
								<TableCell
									align="center"
									style={{
										color: '#262261',
										fontWeight: 'bold',
										fontSize: '1rem',
									}}
								>
									<Select
										value={isClockIn}
										style={{
											fontWeight: 'bold',
											color: '#221E5B',
											fontSize: '1rem',
										}}
										onChange={e => setIsClockIn(e.target.value)}
										disableUnderline
									>
										<MenuItem value={true}>Clock In</MenuItem>
										<MenuItem value={false}>Clock Out</MenuItem>
									</Select>
								</TableCell>
								<TableCell
									align="center"
									style={{
										color: '#262261',
										fontWeight: 'bold',
										fontSize: '1rem',
									}}
								>
									Status
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((date, index) =>
								isEmpty(date.log) ? (
									<TableRow key={index}>
										<TableCell align="center">
											<p
												style={{
													color: '#262261',
													fontWeight: 'bold',
												}}
											>
												{parseDate(date.date)}
											</p>
										</TableCell>
										<TableCell align="center">
											<p
												style={{
													color: '#262261',
													fontWeight: 'bold',
												}}
											>
												-
											</p>
										</TableCell>
										<TableCell align="center">-</TableCell>
									</TableRow>
								) : (
									date?.log.map((row, indexRow) => (
										<TableRow key={indexRow} onClick={() => navigateHistoryDetail({ historyDetail: row, data: date })}>
											<TableCell align="center">
												<p
													style={{
														color: '#262261',
														fontWeight: 'bold',
													}}
												>
													{parseDate(date.date)}
												</p>
											</TableCell>
											<TableCell align="center">
												<p
													style={{
														color: '#262261',
														fontWeight: 'bold',
													}}
												>
													{isClockIn ? parseTime(row.clock_in) : parseTime(row.clock_out)}
												</p>
											</TableCell>
											<TableCell align="center">
												{row.status_attendance === '1' ? (
													<p
														style={{
															color: '#262261',
															fontWeight: 'bold',
														}}
													>
														Success
													</p>
												) : (
													<p
														style={{
															color: '#CE354A',
															fontWeight: 'bold',
														}}
													>
														Fail
													</p>
												)}
											</TableCell>
										</TableRow>
									))
								)
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<AppLoader isLoaderOpen={loading} onRequestClose={closeLoader} loaderText="Loading data ..." />
		</div>
	);
};

export default HistoryClockInOut;
