import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
import axios from "axios";

//import material ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Row, Col, Image } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Modal from "@material-ui/core/Modal";

//import material icon
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

//import image asset
import avatarUser from "../../../assets/icons/ic_avatar_user.png";
import iconTargetValue from "../../../assets/icons/ic_blue_target_value.png";
import iconExpectedValue from "../../../assets/icons/ic_expected_value.png";
import iconDaysLeftValue from "../../../assets/icons/ic_value_days_left.png";
import iconGapValue from "../../../assets/icons/ic_value_gap.png";
import iconResultValue from "../../../assets/icons/ic_value_result.png";
import iconStartValue from "../../../assets/icons/ic_start_value.png";
import iconGoal from "../../../assets/icons/ic_goal.png";

//import component lib
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";

//import custom lib
import UseGeoLocation from "../../../components/UseGeoLocation";
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import {
  DecimalFormat,
  DecimalFormatWithPercentage,
  CurrencyFormat,
} from "../../../helper/utils/StringFormatter";

//import custom component
import AppLoader from "../../../components/Loader/AppLoader";
import GoalResultFormCreate from "../GoalResult/GoalResultFormCreate";
import GoalResultCollection from "../GoalResult/GoalResultCollection";
import MeasuredActivityCollection from "../MeasuredActivity/MeasuredActivityCollection";

const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    width: "100%",
    //height: "100vh",
    color: "white",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    background: "linear-gradient(180deg, #54507d, #25225e)",
  },
  titlebarOuter: {
    display: "table",
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: VALUES.maxScreenWidth,
    backgroundColor: "#54507d",
    height: VALUES.titlebarHeight,
    borderRadius: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  titlebarInner: {
    display: "table-cell",
    verticalAlign: "middle",
    paddingLeft: 20,
  },
  mainContainer: {
    marginTop: 45,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 60,
  },
  ownerTitle: {
    display: "flex",
    alignItems: "center", // middle / center vertical
    alignContent: "center",
    flexDirection: "row",
  },
  titleText: {
    color: COLORS.textColorGray1,
    fontSize: 13,
  },
  valueText: {
    color: "white",
    fontSize: 14,
    fontFamily: "NunitoSemiBold",
    marginLeft: 10,
  },
  valueIcon: {
    width: 16,
    height: 16,
  },
  textMethod: {
    fontSize: 12,
    color: COLORS.yellowPrimary,
    backgroundColor: COLORS.yellowPrimaryOpacity20,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 6,
  },
  spacer: {
    height: 20,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedUnderline: {
    borderBottom: "2px solid red",
    paddingBottom: 2,
    color: "black",
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const GoalDetail = (props) => {
  const classes = useStyles();

  const [goalId, setGoalId] = useState("");
  const [goalIdForMACollection, setGoalIdForMACollection] = useState("");
  const [isGoalResultFormShow, setIsGoalResultFormShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [goalData, setGoalData] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [progressbarColor, setProgressbarColor] = useState(COLORS.redPrimary);

  const [isGoalResultCollectionShow, setIsGoalResultCollectionShow] = useState(
    true
  );
  const [
    isMeasuredActivityCollectionShow,
    setIsMeasuredActivityCollectionShow,
  ] = useState(false);

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const location = UseGeoLocation();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    /*
      //inject css bottomsheet
      document.getElementsByClassName(
        "ReactSwipeableBottomSheet--closed"
      )[0].style.background = "red";
      */

    console.log("goal id", props.id);
    setGoalId(props.id);
    setGoalIdForMACollection(props.id);

    getGoalDetail(props.id);
  }, []);

  const handleShowGoalResultForm = () => {
    setIsGoalResultFormShow(true);
  };

  const handleCloseGoalResultForm = () => {
    setIsGoalResultFormShow(false);
  };

  const handleClickShowGoalResultCollection = () => {
    setIsGoalResultCollectionShow(true);
    setIsMeasuredActivityCollectionShow(false);
  };

  const handleClickShowMeasuredActivityCollection = () => {
    setIsGoalResultCollectionShow(false);
    setIsMeasuredActivityCollectionShow(true);
  };

  const handleShowBottomSheet = () => {
    setIsBottomSheetOpen(!isBottomSheetOpen);
  };

  const handleOnChangeBottomSheet = (open) => {
    setIsBottomSheetOpen(open);
  };

  function closeLoader() {
    setLoading(false);
  }

  const getGoalDetail = (goalId) => {
    console.log("request", "Goal Detail");

    setLoading(true);

    //Get Request
    let searchParam = "";
    //if (textSearch !== "") {
    //searchParam = "&options[filter][search]=" + txtSearch;
    //}
    //let filterByStatusParam = "&options[filter][status_id]=" + statusId;

    axios
      .get(Endpoint + "swd/goal/" + goalId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((response) => {
        setLoading(false);
        setIsSuccess(true);
        //const responseData = JSON.parse(response.data);
        const responseData = response.data;
        setGoalData(responseData);

        let ora =
          responseData.data.calculatedValue.resultOriented
            .overall_result_achievement * 100;

        let progressbarColorValue = COLORS.redPrimary;
        if (ora < 85) {
          progressbarColorValue = COLORS.redPrimary;
        } else if (ora < 100) {
          progressbarColorValue = COLORS.yellowPrimary;
        } else {
          progressbarColorValue = COLORS.greenPrimary;
        }

        setPercentage(ora);
        setProgressbarColor(progressbarColorValue);

        //console.log("response goal detail", JSON.stringify(responseData));
      })
      .catch((error) => {
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  return (
    <div className={classes.root}>
      {/* <div style={{ padding: 20 }}>
        <GoalResultFormCreate goalId={goalId} />
      </div> */}

      <div className={classes.titlebarOuter}>
        <div className={classes.titlebarInner}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={iconGoal}
              //roundedCircle
              style={{ width: 20, height: 20 }}
            />
            <label style={{ marginLeft: 10 }}>Goal</label>

            <div style={{ marginLeft: "auto" }}>
              <IconButton
                aria-label="delete"
                onClick={handleShowGoalResultForm}
              >
                <AddCircleOutlineIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainContainer}>
        <div className={classes.ownerTitle}>
          <Image
            src={avatarUser}
            roundedCircle
            style={{ width: 35, height: 35 }}
          />
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
          >
            <label style={{ fontFamily: "NunitoBold", fontSize: 13 }}>
              {goalData.data &&
                goalData.data.member.first_name +
                  " " +
                  goalData.data.member.last_name}
            </label>
            <label style={{ fontSize: 12 }}>
              {goalData.data &&
                goalData.data.owner.structure_position_title_name}
            </label>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div
            style={{
              margin: "0 auto",
              width: 150,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgressbar
              value={percentage}
              //text={`${percentage}%`}
              strokeWidth={5}
              styles={buildStyles({
                textColor: "white",
                pathColor: progressbarColor,
                trailColor: COLORS.gray1,
              })}
            />

            <Typography
              style={{
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                fontSize: 25,
              }}
            >
              {goalData.data &&
                DecimalFormatWithPercentage(
                  goalData.data.calculatedValue.resultOriented
                    .overall_result_achievement
                )}
              %
            </Typography>
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: 15 }}>
          {goalData.data && goalData.data.name}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <label className={classes.textMethod} style={{ marginRight: 5 }}>
            {goalData.data && goalData.data.achievementType.name}
          </label>
          <label className={classes.textMethod} style={{ marginRight: 5 }}>
            {goalData.data && goalData.data.calculationMethod.name}
          </label>
          <label className={classes.textMethod} style={{ marginRight: 5 }}>
            {goalData.data && goalData.data.inputMethod.name}
          </label>
          <label className={classes.textMethod}>
            {goalData.data && goalData.data.targetType.name}
          </label>
        </div>

        <div style={{ marginTop: 15 }}>
          <BorderLinearProgress variant="determinate" value={percentage} />
        </div>

        <table style={{ marginTop: 20, width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <label className={classes.titleText}>Nilai Awal</label> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconStartValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <label className={classes.valueText}>
                    {goalData.data && goalData.data.calculatedValue.start_value}
                  </label>
                </div>
              </td>
              <td>
                <div style={{ textAlign: "left", float: "right" }}>
                  <label className={classes.titleText}>Nilai Target</label>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    <Image
                      src={iconTargetValue}
                      roundedCircle
                      className={classes.valueIcon}
                    />
                    <label
                      className={classes.valueText}
                      style={{ color: COLORS.bluePrimary }}
                    >
                      {goalData.data &&
                        goalData.data.calculatedValue.target_value}
                    </label>
                  </div>
                </div>
              </td>
            </tr>

            <tr className={classes.spacer}></tr>

            <tr>
              <td>
                <label className={classes.titleText}>Nilai Aktual</label> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconResultValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label className={classes.valueText}>
                      {goalData.data &&
                        DecimalFormatWithPercentage(
                          goalData.data.calculatedValue.actualOriented
                            .actual_value_achievement
                        )}
                      %
                    </label>
                    <label className={classes.valueText}>
                      {goalData.data &&
                        CurrencyFormat(
                          goalData.data.calculatedValue.actualOriented
                            .actual_value
                        )}
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div style={{ textAlign: "left", float: "right" }}>
                  <label className={classes.titleText}>Nilai Hasil</label>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    <Image
                      src={iconResultValue}
                      roundedCircle
                      className={classes.valueIcon}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label className={classes.valueText}>
                        {goalData.data &&
                          DecimalFormatWithPercentage(
                            goalData.data.calculatedValue.resultOriented
                              .result_value_achievement
                          )}
                        %
                      </label>
                      <label className={classes.valueText}>
                        {goalData.data &&
                          CurrencyFormat(
                            goalData.data.calculatedValue.resultOriented
                              .result_value
                          )}
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr className={classes.spacer}></tr>

            <tr>
              <td>
                <label className={classes.titleText}>
                  Nilai yang diharapkan
                </label>{" "}
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconExpectedValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      className={classes.valueText}
                      style={{ color: COLORS.greenPrimary }}
                    >
                      {goalData.data &&
                        DecimalFormatWithPercentage(
                          goalData.data.calculatedValue.resultOriented
                            .expected_result_value_achievement
                        )}
                      %
                    </label>
                    <label
                      className={classes.valueText}
                      style={{ color: COLORS.greenPrimary }}
                    >
                      {goalData.data &&
                        CurrencyFormat(
                          goalData.data.calculatedValue.resultOriented
                            .expected_result_value
                        )}
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div style={{ textAlign: "left", float: "right" }}>
                  <label className={classes.titleText}>Nilai Selisih</label>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    <Image
                      src={iconGapValue}
                      roundedCircle
                      className={classes.valueIcon}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label
                        className={classes.valueText}
                        style={{ color: COLORS.yellowPrimary }}
                      >
                        {goalData.data &&
                          DecimalFormatWithPercentage(
                            goalData.data.calculatedValue.resultOriented
                              .gap_result_value_achievement
                          )}
                        %
                      </label>
                      <label
                        className={classes.valueText}
                        style={{ color: COLORS.yellowPrimary }}
                      >
                        {goalData.data &&
                          CurrencyFormat(
                            goalData.data.calculatedValue.resultOriented
                              .gap_result_value
                          )}
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr className={classes.spacer}></tr>

            <tr>
              <td>
                <label className={classes.titleText}>Sisa Hari</label> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconDaysLeftValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <label
                    className={classes.valueText}
                    style={{ color: COLORS.yellowPrimary }}
                  >
                    {goalData.data && goalData.data.time.remaining_days}
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <SwipeableBottomSheet
        overflowHeight={50}
        marginTop={150}
        overlay={false}
        topShadow={false}
        shadowTip={false}
        fullScreen={true}
        open={isBottomSheetOpen}
        onChange={handleOnChangeBottomSheet.bind(this)}
        //scrollTopAtClose={true}
        //defaultOpen={false}
        //overlayStyle={{}}
        //swipeableViewsProps={{disabled:false}}
        //onTransitionEnd={}
        style={{
          margin: "0 auto",
          width: "100%",
          backgroundColor: "transparent",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          maxWidth: VALUES.maxScreenWidth,
        }}
        bodyStyle={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <div
          style={{
            color: COLORS.textColorGray4,
          }}
        >
          <div
            style={{
              display: "flex",
              //top: 0,
              //position: "absolute",
              //marginTop: -1,
              //backgroundColor: "white",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 10,
              paddingBottom: 15,
            }}
          >
            <label
              onClick={handleClickShowGoalResultCollection}
              style={{
                paddingBottom: 2,
                color: "black",
                fontSize: 14,
                borderBottom: isGoalResultCollectionShow
                  ? "2px solid red"
                  : "0px solid red",
              }}
            >
              Overview
            </label>
            <label
              onClick={handleClickShowMeasuredActivityCollection}
              style={{
                marginLeft: 20,
                paddingBottom: 2,
                fontSize: 14,
                color: "black",
                borderBottom: isMeasuredActivityCollectionShow
                  ? "2px solid red"
                  : "0px solid red",
              }}
            >
              Measured Activity
            </label>

            <div style={{ marginLeft: "auto" }}>
              <IconButton
                aria-label="open-detail"
                style={{ padding: 0 }}
                onClick={handleShowBottomSheet}
              >
                {isBottomSheetOpen ? (
                  <KeyboardArrowDownIcon style={{ color: "gray" }} />
                ) : (
                  <KeyboardArrowUpIcon style={{ color: "gray" }} />
                )}
              </IconButton>
            </div>
          </div>

          <div
            style={{
              //marginTop: 50,
              //backgroundColor: "white",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            {isGoalResultCollectionShow && (
              <div style={{ paddingBottom: 5 }}>
                <GoalResultCollection goalId={goalId} />
              </div>
            )}
            {isMeasuredActivityCollectionShow && (
              <div>
                <MeasuredActivityCollection goalId={goalId} />
              </div>
            )}
          </div>
        </div>
      </SwipeableBottomSheet>

      <Modal
        open={isGoalResultFormShow}
        onClose={handleCloseGoalResultForm}
        className={classes.modal}
        closeAfterTransition
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        {
          <div style={{ padding: 0, outline: 0 }}>
            <GoalResultFormCreate
              goalId={goalId}
              onCloseFormDialog={handleCloseGoalResultForm}
              existingData=""
              action="add"
            />
          </div>
        }
      </Modal>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeLoader}
        loaderText="Loading ..."
      />
    </div>
  );
};

export default GoalDetail;
