//import lib
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { format } from 'date-fns';
import { navigate } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
//import lib component
import { FilePicker } from 'react-file-picker';
//import image assets
import iconDate from '../../../assets/icons/ic_calendar_circle_gray.png';
//import custom component
import AppLoader from '../../../components/Loader/AppLoader';
//import custom lib
import { COLORS } from '../../../helper/constants/colors';
import { Endpoint } from '../../../helper/utils/ApiEndpoint';
import { getToken } from '../../../helper/utils/AuthData';

//css style
const useStyles = makeStyles({
	root: {
		width: '100%',
		minWidth: 200,
		borderRadius: 15,
		backgroundColor: 'white',
		border: '1px solid lightGray',
		//padding: 15,
		//margin: 20,
	},
	container: {
		padding: 20,
	},
	textField: {
		width: '100%',
		borderRadius: 5,
		height: 40,
		backgroundColor: COLORS.gray2,
		border: '0px solid #cfd0d1',
		padding: 5,
		boxSizing: 'border-box',
	},
	textArea: {
		width: '100%',
		maxWidth: '100%',
		resize: 'none',
		borderRadius: 5,
		backgroundColor: COLORS.gray2,
		border: '0px solid #cfd0d1',
		padding: 5,
		boxSizing: 'border-box',
	},
	dateTextField: {
		fontSize: 10,
		width: 150,
		padding: 0,
	},
	submitButton: {
		minWidth: 90,
		backgroundColor: COLORS.redPrimary,
		height: 35,
		textAlign: 'center',
		color: 'white',
		border: '0px solid white',
		borderRadius: 20,
		paddingLeft: 10,
		paddingRight: 10,
		outline: 0,
		boxShadow: 0,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: COLORS.redprimaryDark,
			opacity: 1,
			transition: 'all .2s ease-in-out',
		},
		'&:focus': {
			backgroundColor: COLORS.redprimaryDark,
		},
	},
});

const supportedImageExtension = ['jpg', 'jpeg', 'pjpeg', 'png', 'jfif', 'gif', 'svg', 'webp'];

const GoalResultFormCreate = ({ goalId, onCloseFormDialog, action, existingData }) => {
	//init makeStyles classes
	const classes = useStyles();

	//state date picker
	const [selectedDate, setSelectedDate] = useState(
		new Date()
		//new Date("2021-01-01T23:59:59")
	);

	//state time picker
	const [selectedTime, setSelectedTime] = useState(
		new Date()
		//new Date("2021-01-01T23:59:59")
	);

	//state loader
	const [loading, setLoading] = useState(false);

	//state compressor loader
	const [isCompressing, setIsCompressing] = useState(false);

	//state Goal Result Param
	const [resultValue, setResultValue] = useState({
		result_value: '',
		result_date: '',
		result_time: '',
		description: '',
		evidence_category_id: 'c9441e2a-972d-47da-a07c-a1eb5215de05', //attachment
		evidence_content: null,
		evidence_latitude: '',
		evidence_longitude: '',
	});

	//state request success
	const [isSuccess, setIsSuccess] = useState(false);

	//state file name
	const [fileName, setFileName] = useState('');

	//state error text
	const [errorText, setErrortext] = useState('');

	//state is evidence required
	const [isEvidenceRequired, setIsEvidenceRequired] = useState(false);

	//state dialog message info
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [messageInfo, setMessageInfo] = useState('Performate');

	//run first before load page
	useEffect(() => {
		//set default date time
		let date = new Date();
		let formattedDate = format(date, 'yyyy-MM-dd');
		let formattedTime = format(date, 'HH:mm:ss');

		if (action === 'add') {
			//set default value if action is "add"
			setResultValue({
				...resultValue,
				['result_date']: formattedDate,
				['result_time']: formattedTime,
			});
		} else {
			//set default value if action is "edit"
			setResultValue({
				...resultValue,
				['result_date']: existingData.result_date,
				['result_time']: existingData.result_time,
				['result_value']: existingData.result_value,
				['description']: existingData.description,
			});

			let dateString = existingData.result_date + ' ' + existingData.result_time;
			let existingDateTime = new Date(dateString);
			setSelectedDate(existingDateTime);
			setSelectedTime(existingDateTime);
		}
		//setResultValue({ ...resultValue, ["result_time"]: formattedTime });
		//console.log("goal id form gr", goalId);

		console.log('existingData', existingData.result_date);
	}, []);

	//handle change date picker
	const handleDateChange = date => {
		setSelectedDate(date);
		let formattedDate = format(date, 'yyyy-MM-dd');
		console.log('date 0', date);
		setResultValue({ ...resultValue, ['result_date']: formattedDate });
	};

	//handle change time picker
	const handleTimeChange = date => {
		setSelectedTime(date);
		let formattedTime = format(date, 'HH:mm:ss');
		//console.log("date 0", formattedDate);
		setResultValue({ ...resultValue, ['result_time']: formattedTime });
	};

	//handle change text input value
	const handleChange = prop => event => {
		console.log('props', prop);
		//event.preventDefault();
		setResultValue({ ...resultValue, [prop]: event.target.value });
	};

	//handle change file picker
	const handleFileChange = file => {
		/*
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
      this.extractWordRawText(e.target.result);
    };
    */

		//this.setState({ title: file.name });

		//check file is image or not
		let fileName = file.name;
		let fileExtension = fileName.split('.').pop().toLowerCase();
		if (supportedImageExtension.includes(fileExtension)) {
			compressImage(file);
			console.log('compress image', 'true');
		} else {
			console.log('compress image', 'false');
			setFileName(file.name);
			setErrortext('');
			setResultValue({ ...resultValue, ['evidence_content']: file });
		}

		console.log('filename', fileName);
	};

	function compressImage(imageFile) {
		setIsCompressing(true);
		//var imageFile = file.target.files[0];
		//console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
		console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

		var options = {
			maxSizeMB: 2,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
			onProgress: onProgress, // optional, a function takes one progress argument (percentage from 0 to 100)

			// following options are for advanced users
			//maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
			//exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
			//fileType: string,           // optional, fileType override
			//initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
		};
		imageCompression(imageFile, options)
			.then(function (compressedFile) {
				console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
				console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

				//return uploadToServer(compressedFile); // write your own logic

				setFileName(compressedFile.name);
				setErrortext('');
				setResultValue({
					...resultValue,
					['evidence_content']: compressedFile,
				});
				console.log('compressed', 'success');
				setIsCompressing(false);
			})
			.catch(function (error) {
				console.log(error.message);
				console.log('compressed', 'ERROR');

				setErrortext(error.message);
			});
	}

	function onProgress(p) {
		//progressDom.value = '(' + p + '%' + ')'
		console.log('progress: ', p);
	}

	//handle close dialog message info
	const handleCloseDialog = () => {
		setIsDialogOpen(false);
		if (isSuccess) {
			onCloseFormDialog();
		}
	};

	//handle close loader
	function closeModal() {
		setLoading(false);
	}

	//handle close compressor loader
	function closeCompressorLoader() {
		setIsCompressing(false);
	}

	const injectTheme = () => {
		let node =
			document.getElementById('datepicker-arrow-right').parentNode.parentNode.parentNode.parentNode.parentNode
				.parentElement;
		node.classList.add('Css-Class-Name');
	};

	//request create goal result
	const handleSubmit = () => {
		console.log('date 2', resultValue.result_date);
		console.log('time 2', resultValue.result_time);
		console.log('res val', resultValue.result_value);

		if (resultValue.result_value === '') {
			//alert("Masukkan nilai pencapaian");
			setMessageInfo('Masukkan nilai pencapaian');
			setIsDialogOpen(true);
		} else {
			setLoading(true);
			let formData1 = JSON.stringify({ GoalResult: resultValue });

			// Create an object of formData
			const formData = new FormData();

			// Update the formData object
			formData.append('GoalResult[result_value]', resultValue.result_value);
			formData.append('GoalResult[result_date]', resultValue.result_date);
			formData.append('GoalResult[result_time]', resultValue.result_time);
			formData.append('GoalResult[description]', resultValue.description);

			if (fileName !== '') {
				formData.append('GoalResult[evidence_category_id]', resultValue.evidence_category_id);
				formData.append('GoalResult[evidence_content]', resultValue.evidence_content);
				formData.append('GoalResult[evidence_description]', resultValue.evidence_content.name);
			}

			formData.append('GoalResult[evidence_latitude]', '');
			formData.append('GoalResult[evidence_longitude]', '');

			axios
				.post(Endpoint + 'swd/goal/' + goalId + '/result', formData, {
					headers: {
						Accept: 'application/json',
						//"Content-Type": "application/json",
						'content-type': 'multipart/form-data',
						//"Content-Type": "application/x-www-form-urlencoded",
						Authorization: 'Bearer ' + getToken(),
					},
				})
				.then(response => {
					setIsSuccess(true);
					setLoading(false);
					const responseData = response.data;
					console.log('response', JSON.stringify(responseData));
					//alert("Sukses, Goal result berhasil disimpan !");

					setMessageInfo('Sukses, Goal result berhasil disimpan.');
					setIsDialogOpen(true);

					/*
          setTimeout(() => {
            window.history.go(-1);
          }, 3000);
          */
				})
				.catch(error => {
					setLoading(false);
					try {
						const response = error.response;
						const statusCode = response.status;
						const responseData = response.data;
						console.log('statusCode', statusCode);
						console.log('errorMessage', error);
						console.log('responseData', responseData);

						if (statusCode == 401) {
							console.log('unauthorized', statusCode);
							localStorage.clear();
							navigate('/');
						} else {
							let infoMessage = responseData.info.message;
							let infoError = '';
							if (responseData.info.errors.length > 0) {
								infoError = responseData.info.errors[0].description;
							}
							console.log('infoMessage', infoMessage);
							console.log('infoError', infoError);

							if (infoError != '') {
								infoMessage = infoError;
							}

							//alert(infoMessage);
							//setErrortext(infoMessage);
							setMessageInfo(infoMessage);
							setIsDialogOpen(true);
						}
					} catch (errorCatch) {
						setMessageInfo(errorCatch);
						setIsDialogOpen(true);
					}
				});
		}
	};

	//request update goal result
	const handleSubmitUpdate = () => {
		let goalResultId = existingData.id;
		console.log('date 2', resultValue.result_date);
		console.log('time 2', resultValue.result_time);
		console.log('res val', resultValue.result_value);

		if (resultValue.result_value === '') {
			//alert("Masukkan nilai pencapaian");
			setMessageInfo('Masukkan nilai pencapaian');
			setIsDialogOpen(true);
		} else {
			setLoading(true);
			let formData1 = JSON.stringify({ GoalResult: resultValue });

			// Create an object of formData
			const formData = new FormData();

			// Update the formData object
			formData.append('GoalResult[result_value]', resultValue.result_value);
			formData.append('GoalResult[result_date]', resultValue.result_date);
			formData.append('GoalResult[result_time]', resultValue.result_time);
			formData.append('GoalResult[description]', resultValue.description);

			if (fileName !== '') {
				formData.append('GoalResult[evidence_category_id]', resultValue.evidence_category_id);
				formData.append('GoalResult[evidence_content]', resultValue.evidence_content);
				formData.append('GoalResult[evidence_description]', resultValue.evidence_content.name);
			}

			formData.append('GoalResult[evidence_latitude]', '');
			formData.append('GoalResult[evidence_longitude]', '');

			formData.append('_method', 'PUT');

			axios
				.post(Endpoint + 'swd/goal/' + goalId + '/result/' + goalResultId, formData, {
					headers: {
						Accept: 'application/json',
						//"Content-Type": "application/json",
						'content-type': 'multipart/form-data',
						//"Content-Type": "application/x-www-form-urlencoded",
						Authorization: 'Bearer ' + getToken(),
					},
				})
				.then(response => {
					setIsSuccess(true);
					setLoading(false);
					const responseData = response.data;
					console.log('response', JSON.stringify(responseData));
					//alert("Sukses, Goal result berhasil disimpan !");

					setMessageInfo('Sukses, Goal result berhasil disimpan.');
					setIsDialogOpen(true);

					/*
          setTimeout(() => {
            window.history.go(-1);
          }, 3000);
          */
				})
				.catch(error => {
					setLoading(false);
					try {
						const response = error.response;
						const statusCode = response.status;
						const responseData = response.data;
						console.log('statusCode', statusCode);
						console.log('errorMessage', error);
						console.log('responseData', responseData);

						if (statusCode == 401) {
							console.log('unauthorized', statusCode);
							localStorage.clear();
							navigate('/');
						} else {
							let infoMessage = responseData.info.message;
							let infoError = '';
							if (responseData.info.errors.length > 0) {
								infoError = responseData.info.errors[0].description;
							}
							console.log('infoMessage', infoMessage);
							console.log('infoError', infoError);

							if (infoError != '') {
								infoMessage = infoError;
							}

							//alert(infoMessage);
							//setErrortext(infoMessage);
							setMessageInfo(infoMessage);
							setIsDialogOpen(true);
						}
					} catch (errorCatch) {
						setMessageInfo(errorCatch);
						setIsDialogOpen(true);
					}
				});
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Typography style={{ fontSize: 15, fontWeight: 'bold' }} color="textPrimary">
					{action === 'add' ? <label>Tambah Pencapaian</label> : <label>Ubah Pencapaian</label>}
				</Typography>

				<div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
					<Image src={iconDate} roundedCircle style={{ width: 25, height: 25, marginRight: 10 }} />

					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						{/* <KeyboardDatePicker
              placeholder="01/01/2021"
              value={selectedDate}
              onChange={(date) => handleDateChange(date)}
              format="dd/MM/yyyy"
              style={{ width: 150 }}
            /> */}

						<DatePicker
							//autoOk
							//clearable
							//disableFuture
							//label="Clearable"
							format="dd MMM yyyy"
							views={['year', 'month', 'date']}
							value={selectedDate}
							onChange={handleDateChange}
							style={{ width: 100, fontSize: 10 }}
						/>

						<TimePicker
							ampm={false}
							openTo="hours"
							views={['hours', 'minutes', 'seconds']}
							format="HH:mm:ss"
							//label="With seconds"
							value={selectedTime}
							onChange={handleTimeChange}
							style={{ marginLeft: 20, width: 70 }}
						/>
					</MuiPickersUtilsProvider>
				</div>
				<Typography style={{ fontSize: 13, marginTop: 20, marginBottom: 5 }} color="textSecondary">
					Masukkan nilai pencapaian
				</Typography>

				<input
					key="result_value"
					id="result-value"
					type="number"
					className={classes.textField}
					value={resultValue.result_value}
					placeholder=""
					onChange={handleChange('result_value')}
				/>
				<br />
				<Typography style={{ fontSize: 13, marginTop: 15, marginBottom: 5 }} color="textSecondary">
					Keterangan
				</Typography>
				<textarea
					key="description"
					id="result-description"
					className={classes.textArea}
					defaultValue={resultValue.description}
					rows={3}
					onChange={handleChange('description')}
				/>

				<br />
				<FilePicker
					extensions={[
						'doc',
						'docx',
						'xls',
						'xlsx',
						'ppt',
						'pptx',
						'txt',
						'jpg',
						'jpeg',
						'png',
						'gif',
						'mp4',
						'3gp',
						'mp3',
						'mpeg',
					]} // Notice that I removed the "."
					maxSize={20}
					onChange={handleFileChange}
					onError={errMsg => {
						setErrortext(errMsg);
						setFileName('');
						setResultValue({ ...resultValue, ['evidence_content']: null });
						console.log(errMsg);
					}} // Please handle error
				>
					<button className={classes.submitButton} style={{ marginTop: 10 }}>
						Tambahkan Bukti
					</button>
				</FilePicker>
				<br />
				<label>{fileName}</label>
				<br />
				<label style={{ color: 'red' }}>{errorText}</label>

				<br />
				<br />
				<div style={{ display: 'flex' }}>
					<div style={{ marginLeft: 'auto' }}>
						{/* <button>Tutup</button> */}

						{action === 'add' ? (
							<button onClick={handleSubmit} className={classes.submitButton} style={{ marginLeft: 10 }}>
								Simpan
							</button>
						) : (
							<button onClick={handleSubmitUpdate} className={classes.submitButton} style={{ marginLeft: 10 }}>
								Ubah
							</button>
						)}
					</div>
				</div>
			</div>

			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Info'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{messageInfo}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} color="primary" autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<AppLoader isLoaderOpen={loading} onRequestClose={closeModal} loaderText="Menyimpan ..." />

			<AppLoader isLoaderOpen={isCompressing} onRequestClose={closeCompressorLoader} loaderText="Loading image ..." />
		</div>
	);
};

export default GoalResultFormCreate;
