import { Card, CardContent, Grid, makeStyles, Typography, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DateRange } from '@material-ui/icons';
import axios from 'axios';
import navigate from 'hookrouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import noteImage from '../../../assets/images/note.png';
import BackToolbar from '../../../components/BackToolbar';
import BottomNavigation from '../../../components/BottomNavigation';
import AppLoader from '../../../components/Loader/AppLoader';
import { COLORS } from '../../../helper/constants/colors';
import { VALUES } from '../../../helper/constants/ValueConstants';
import { Endpoint } from '../../../helper/utils/ApiEndpoint';
import { getToken, isAuthenticated } from '../../../helper/utils/AuthData';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
		marginBottom: 100,
	},
	topCard: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		background: 'linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)',
		borderRadius: 11,
		padding: '0px 20px',
	},
}));

const BorderLinearProgress = withStyles(theme => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: '#9B1B2D',
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#EC5267',
	},
}))(LinearProgress);

function Presence() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({});
	const [presence, setPresence] = useState([]);

	useEffect(() => {
		if (!isAuthenticated()) {
			navigate('/');
		}
		fetchPresence();
	}, []);

	const fetchPresence = async () => {
		try {
			setLoading(true);
			const response = await axios.get(`${Endpoint}human-resource/account/presence`, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getToken()}`,
				},
			});
			setStatus({ status: 'success', message: response.data.info.message });
			setPresence(response.data.data);
		} catch (error) {
			setStatus({ status: 'error', message: error.response?.data?.info.message });
		} finally {
			setLoading(false);
		}
	};

	const closeLoader = () => {
		setLoading(false);
	};

	return (
		<>
			<div className={classes.root}>
				<BackToolbar title={'Presence'} />
				<div style={{ paddingTop: 10 }}>
					<Card className={classes.topCard}>
						<CardContent style={{ flex: '1 0 auto' }}>
							<div
								style={{
									color: 'white',
									display: 'flex',
									gap: '10px',
									marginBottom: '10px',
									fontWeight: 'bold',
								}}
							>
								<DateRange />
								<Typography variant="h6">
									<b>Kehadiran anda bulan ini</b>
								</Typography>
							</div>
							<Grid container spacing={1}>
								<Grid item xs={4}>
									<img src={noteImage} alt="attendance" width={120} />
								</Grid>
								<Grid item xs={8}>
									<Grid container spacing={1} style={{ marginTop: 20, marginBottom: 5 }}>
										<Grid item xs={9}>
											<Typography variant="h6" style={{ color: 'white' }}>
												<b>15</b>/30 Kehadiran
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="h6" style={{ color: 'white', textAlign: 'right' }}>
												<b>50%</b>
											</Typography>
										</Grid>
									</Grid>
									<BorderLinearProgress variant="determinate" value={50} />
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<div>
						<Typography variant="h5" style={{ color: '#221E5B', margin: '20px 0px' }}>
							<b>Presence Detail</b>
						</Typography>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
							{isEmpty(presence) ? (
								<Typography variant="h4" align="center" style={{ color: '#221E5B', margin: '50px 0px' }}>
									<b>Tidak Ada Data Presen</b>
								</Typography>
							) : (
								presence.map(item => (
									<Card style={{ padding: '10px', color: '#221E5B', borderRadius: '10px' }}>
										<CardContent>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													flexWrap: 'wrap',
													gap: '10px',
													marginBottom: '10px',
													fontWeight: 'bold',
												}}
											>
												<DateRange />
												<Typography variant="h6">
													<b>{item.tanggal}</b>
												</Typography>
											</div>
											<Typography variant="subtitle1" style={{ color: COLORS.textColorGray2 }}>
												Pukul : {item.pukul}
											</Typography>
											<Typography variant="subtitle2">
												<b>Lokasi : {item.lokasi}</b>
											</Typography>
											<Typography variant="subtitle2">
												<b>Status : Hadir</b>
											</Typography>
										</CardContent>
									</Card>
								))
							)}
						</div>
					</div>
				</div>
				<BottomNavigation />
			</div>
			<AppLoader isLoaderOpen={loading} onRequestClose={closeLoader} loaderText="Fetching Data ..." />
		</>
	);
}

export default Presence;
