import { useRoutes } from 'hookrouter';
import { Provider } from 'react-redux';
import './App.css';
import { routes } from './routes';
import { store } from './store/Attendance/store';

const App = () => {
	//useRedirect("/", "/");
	const routeResult = useRoutes(routes);

	return <Provider store={store}>{routeResult || 'Not Found'}</Provider>;
	//   return (
	//     <div className="App">
	//       <Login />
	//       {/* <header className="App-header">
	//         <img src={logo} className="App-logo" alt="logo" />
	//         <p>
	//           Edit <code>src/App.js</code> and save to reload.
	//         </p>
	//         <a
	//           className="App-link"
	//           href="https://reactjs.org"
	//           target="_blank"
	//           rel="noopener noreferrer"
	//         >
	//           Learn React
	//         </a>
	//       </header> */}
	//     </div>
	//   );
};

export default App;
