import React, { useEffect, useState } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { id } from "date-fns/locale";

import { Button, CircularProgress, FormControl } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Grid,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DurationPicker } from "material-duration-picker";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty, now } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import {
  getToken,
  isAuthenticated,
  getAuthData,
} from "../../../helper/utils/AuthData";
import { AlarmAddRounded, BeachAccess } from "@material-ui/icons";
import { FileUploader } from "react-drag-drop-files";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const RequestTimeOff = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  const [category, setCategory] = useState([]);

  // input
  const [category_id, setCategory_id] = useState("");
  const [user_id, setUser_id] = useState(
    JSON.parse(getAuthData()).data.user_id
  );
  const [schedule_in, setSchedule_in] = useState(new Date());
  const [schedule_out, setSchedule_out] = useState(new Date());
  const [file, setFile] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      getForm();
    } /* eslint-disable-line */,
    []
  );

  const getForm = async () => {
    try {
      setLoading(true);
      const token = getToken();
      const response = await axios.get(
        Endpoint + "human-resource/request-timeoff",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setCategory(response.data.data.cuti_list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/time-off");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const storeTimeOff = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.set("Leave[category_id]", category_id);
      body.set("Leave[user_id]", user_id);
      body.set("Leave[schedule_in]", schedule_in.toISOString().split("T")[0]);
      body.set("Leave[schedule_out]", schedule_out.toISOString().split("T")[0]);
      body.set("Leave[description]", description);
      body.set("Leave[file]", file);

      let headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      };

      let response = await axios.post(
        Endpoint + "human-resource/request-timeoff",
        body,
        {
          headers,
        }
      );

      if (response.status === 200) {
        setStatus({
          status: "success",
          info: "Berhasil mengajukan Time Off",
          message: response.data.info.message,
        });
      } else {
        setStatus({
          status: "error",
          info: "Gagal mengajukan Time Off",
          message: response.data.info.message,
        });
      }
    } catch (error) {
      setStatus({
        status: "error",
        info: "Gagal mengajukan Time Off",
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar title={"Request Time-Off"} />
      </AppBar>

      <Card
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#fff",
          maxWidth: VALUES.maxScreenWidth,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        <CardContent>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <InputLabel htmlFor="overtime-type">Jenis Time-off</InputLabel>
            <Select
              id="overtime-type"
              value={category_id}
              onChange={(e) => setCategory_id(e.target.value)}
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <BeachAccess />
                </InputAdornment>
              }
            >
              <MenuItem value="">PILIH JENIS CUTI</MenuItem>
              {category.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Tanggal Mulai"
                  value={schedule_in}
                  onChange={(date) => setSchedule_in(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                align="center"
                style={{
                  marginTop: "1rem",
                  fontFamily: "Nunito",
                  fontWeight: "bold",
                }}
              >
                s/d
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Tanggal Selesai"
                  value={schedule_out}
                  onChange={(date) => setSchedule_out(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <TextField
              fullWidth
              label="Deskripsi"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </FormControl>

          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              types={["doc", "docx", "pdf", "txt", "png", "jpg", "jpeg"]}
              label="Upload File Bukti"
              multiple={false}
            />
          </div>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "11rem",
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={
                  () => {
                    setDescription("");
                  } /*handleCancel()*/
                }
                disabled={loading}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    storeTimeOff();
                  } /*handleSubmit()*/
                }
                disabled={loading}
              >
                Ajukan
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ErrorDialog
        open={status.status === "error"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <SuccessDialog
        open={status.status === "success"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default RequestTimeOff;
