import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fingerprint } from '@material-ui/icons';
import { navigate } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import BackToolbar from '../../components/BackToolbar';
import { VALUES } from '../../helper/constants/ValueConstants';
//import custom lib
import { isAuthenticated } from '../../helper/utils/AuthData';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
}));

const FingerPrint = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false); //true
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(
		() => {
			if (!isAuthenticated()) {
				navigate('/');
			}
		} /* eslint-disable-line */,
		[]
	);

	return (
		<div className={classes.root}>
			<AppBar
				position="static"
				style={{
					background: '#fff',
					borderTopLeftRadius: 24,
					borderTopRightRadius: 24,
				}}
				//elevation={0}
			>
				<BackToolbar />
			</AppBar>
			<Card
				style={{
					margin: '0 auto',
					width: '100%',
					background: '#fff',
					maxWidth: VALUES.maxScreenWidth,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					borderBottomLeftRadius: 24,
					borderBottomRightRadius: 24,
				}}
			>
				<CardContent>
					<div
						style={{
							color: '#221E5B',
							marginTop: '10rem',
							marginBottom: '17rem',
							textAlign: 'center',
						}}
					>
						<Fingerprint style={{ fontSize: 100 }} onClick={() => navigate('/live-attendance/pin')} />
						<Typography variant="h6" style={{ marginTop: '1rem' }}>
							Scan Fingerprint
						</Typography>
						<Typography variant="body1" style={{ marginTop: '1rem' }}>
							Letakkan jari anda pada bagian fingerprint Handphone anda.
						</Typography>
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default FingerPrint;
