import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Endpoint } from '../../helper/utils/ApiEndpoint';
import { getToken } from '../../helper/utils/AuthData';

const initialState = {
	loading: false,
	status: { success: false, error: false, message: {} },
	data: {},
	clockStatus: 'clockin',
	/*
	there is three types of clock status
	 - clockin
	 - clockout
	 - done (if the user already clock in and out)
	*/
};

export const getVerificationMethod = createAsyncThunk(
	'verificationMethod/getVerificationMethod',
	async (params, { rejectWithValue }) => {
		let response;
		try {
			response = await axios.get(`${Endpoint}human-resource/attendance/verification-method`, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getToken()}`,
				},
			});
			return response.data;
		} catch (err) {
			response = err.response.data;
			return rejectWithValue(response);
		}
	}
);

const verificationMethod = createSlice({
	name: 'verificationMethod',
	initialState,
	reducers: {},
	extraReducers: {
		[getVerificationMethod.pending]: (state, { payload }) => {
			state.loading = true;
		},
		[getVerificationMethod.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.data = payload?.data;
			state.clockStatus =
				payload.data.clock_in === null ? 'clockin' : payload?.data.clock_in.clock_out === null ? 'clockout' : 'done';
			state.status = {
				success: true,
				error: false,
				message: payload?.info,
			};
		},
		[getVerificationMethod.rejected]: (state, { payload }) => {
			state.loading = false;
			state.data = payload?.data;
			state.status = {
				success: false,
				error: true,
				message: payload?.info,
			};
			state.clockStatus = "clockin"
		},
	},
});

export default verificationMethod.reducer;
