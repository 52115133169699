import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import successCheckImage from "../../assets/images/success_check.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-success-title"
      aria-describedby="alert-dialog-success-description"
    >
      <DialogTitle
        id="alert-dialog-success-title"
        style={{
          textAlign: "center",
        }}
      >
        {props.info}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          style={{
            textAlign: "center",
          }}
        >
          {props.message}
        </Typography>
        <img
          src={successCheckImage}
          alt="success"
          width={150}
          style={{
            margin: "0 auto",
            display: "block",
            marginBottom: "1rem",
          }}
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={props.handleClose}
          color="primary"
          style={{
            background: "#221E5B",
            color: "#fff",
            width: "100%",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
