import React, { useEffect, useRef, useState } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import { Box, Divider, Grid, List, ListItem } from "@material-ui/core";

//import material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { DateRange } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const HistoryOvertime = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState({});
  const [dataRaw, setDataRaw] = useState([]);
  const [dataPending, setDataPending] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if (newValue === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === "99";
      });
      setDataApproved(data);
      console.log(data);
    } else if (newValue === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === "1";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    console.log(index);
    if (index === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === "99";
      });
      setDataApproved(data);
      console.log(data);
    } else if (index === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === "1";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      fetchOvertime();
    } /* eslint-disable-line */,
    []
  );

  const fetchOvertime = async () => {
    let response;
    try {
      setLoading(true);
      response = await axios.get(`${Endpoint}human-resource/overtime/`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setDataRaw(response.data.data);
      setDataPending(
        response.data.data.filter((item) => {
          return item.status_approve[0].status === "1";
        })
      );
      setDataApproved(
        response.data.data.filter((item) => {
          return item.status_approve[0].status === "99";
        })
      );
      setStatus({ status: "success", message: response.data.info.message });
    } catch (e) {
      setStatus({ status: "error", message: e.response?.data?.info.message });
      console.log(e.response);
    } finally {
      setLoading(false);
    }
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " Jam, " : " Jam, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Menit, " : " Menit, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " Detik" : " Detik") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar title={"Overtime"} />
      </AppBar>
      <Card
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#fff",
          maxWidth: VALUES.maxScreenWidth,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        <CardContent>
          <div
            style={{
              color: "#221E5B",
              marginTop: "1rem",
              marginBottom: "17rem",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              className={classes.fixedBottomButton}
              style={{
                backgroundColor: COLORS.purple,
                color: COLORS.white,
              }}
              onClick={() => navigate("/overtime/request")}
            >
              {loading ? (
                <CircularProgress sx={{ color: COLORS.gray3 }} />
              ) : (
                <b>AJUKAN OVERTIME</b>
              )}
            </Button>

            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="History Overtime"
              style={{
                marginTop: "1rem",
              }}
            >
              <Tab label="Disetujui" {...a11yProps(0)} />
              <Tab label="Perlu Persetujuan" {...a11yProps(1)} />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {dataApproved?.map((item, index) => {
                  return (
                    <List key={index}>
                      <ListItem
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            color: "#221E5B",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <b>
                            <DateRange />
                            <span>
                              &nbsp;
                              <Moment
                                format="dddd, D MMMM YYYY"
                                tz="Asia/Jakarta"
                                locale="id"
                                interval={1}
                                unit="seconds"
                              >
                                {item.workday}
                              </Moment>
                            </span>
                          </b>
                        </div>
                        <Typography variant="body1">
                          Durasi Lembur:{" "}
                          <b>{secondsToHms(item.overtime_duration)}</b>
                        </Typography>
                        <Typography variant="body1">
                          Durasi Istirahat:{" "}
                          <b>{secondsToHms(item.rest_duration)}</b>
                        </Typography>
                        <Typography variant="body1">
                          Deskripsi: <b>{item.description}</b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#221E5B",
                          }}
                        >
                          <b>Status: Telah Disetujui</b>
                        </Typography>
                      </ListItem>
                    </List>
                  );
                })}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {dataPending?.map((item, index) => {
                  return (
                    <List key={index}>
                      <ListItem
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            color: "#221E5B",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <b>
                            <DateRange />
                            <span>
                              &nbsp;
                              <Moment
                                format="dddd, D MMMM YYYY"
                                tz="Asia/Jakarta"
                                locale="id"
                                interval={1}
                                unit="seconds"
                              >
                                {item.workday}
                              </Moment>
                            </span>
                          </b>
                        </div>
                        <Typography variant="body1">
                          Durasi Lembur:{" "}
                          <b>{secondsToHms(item.overtime_duration)}</b>
                        </Typography>
                        <Typography variant="body1">
                          Durasi Istirahat:{" "}
                          <b>{secondsToHms(item.rest_duration)}</b>
                        </Typography>
                        <Typography variant="body1">
                          Deskripsi: <b>{item.description}</b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: COLORS.redPrimary,
                          }}
                        >
                          <b>Status: Menunggu Persetujuan HR/Admin</b>
                        </Typography>
                      </ListItem>
                    </List>
                  );
                })}
              </TabPanel>
            </SwipeableViews>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default HistoryOvertime;
