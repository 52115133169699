import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import { navigate } from "hookrouter";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import locationImage from "../../assets/icons/location.svg";
import overtimeImage from "../../assets/icons/req-overtime.svg";
import timeOffImage from "../../assets/icons/req-time-off.svg";
import BottomNavigation from "../../components/BottomNavigation";
import ErrorDialog from "../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../components/Dialog/SuccessDialog";
import AppLoader from "../../components/Loader/AppLoader";
import { COLORS } from "../../helper/constants/colors";
import { VALUES } from "../../helper/constants/ValueConstants";
import { getToken, isAuthenticated } from "../../helper/utils/AuthData";
import { Endpoint } from "../../helper/utils/ApiEndpoint";
import DialogNotifDetail from "./Inbox/DialogNotifDetail";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    marginBottom: 100,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box style={{ padding: "0px 25px" }}>{children}</Box>}
    </div>
  );
}

function MailPage() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  const inbox = useSelector((state) => state.inbox);

  const [isOpenDialogNotifDetail, setOpenDialogNotifDetail] = useState(false);
  const [notifDetail, setNotifDetail] = useState([]);

  const [notifList, setNotifList] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const [inboxList, setInboxList] = useState([]);
  const [inboxCount, setInboxCount] = useState(0);

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/mail");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getNotif() {
    try {
      const response = await axios.get(`${Endpoint}human-resource/inbox`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const notif = response.data.data.filter((item) => {
        return item.type === "notification";
      });
      const inbox = response.data.data.filter((item) => {
        return item.type !== "notification";
      });
      const unreadNotif = notif.filter((item) => {
        return item.is_read === 0;
      });
      setNotifList(notif);
      setNotifCount(unreadNotif.length);
      setInboxList(inbox);
      setInboxCount(inbox.length);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.statusText,
          });
        }

        if (error.response.status == 403) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.data.info.message,
          });
        }
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Periksa koneksi jaringan internet Anda ",
        });
      }
      console.log("Error : ", error.response);
    }
  }

  async function handleRequestApprove(id, type) {
    try {
      if (type === "request_exception") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-exception/approve/${id}`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menerima permintaan",
          });
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.statusText,
          });
        }

        if (error.response.status == 403) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.data.info.message,
          });
        }
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Periksa koneksi jaringan internet Anda ",
        });
      }
      console.log("Error : ", error.response);
    }
  }

  async function handleRequestReject(id, type) {
    try {
      if (type === "request_exception") {
        const response = await axios.post(
          `${Endpoint}human-resource/request-exception/reject/${id}`,
          {},
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setStatus({
            status: "success",
            info: "Sukses !!",
            message: "Berhasil menolak permintaan",
          });
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.statusText,
          });
        }

        if (error.response.status == 403) {
          setStatus({
            status: "error",
            info: "error",
            message: error.response.data.info.message,
          });
        }
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Periksa koneksi jaringan internet Anda ",
        });
      }
      console.log("Error : ", error.response);
    }
  }

  useEffect(() => {
    getNotif();
    const notifInterval = setInterval(() => {
      getNotif();
    }, 10000);
    return () => {
      clearInterval(notifInterval);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" style={{ color: COLORS.purple, margin: "20px" }}>
        <b>Inbox</b>
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            style={{ textColor: COLORS.gray1 }}
            textColor="primary"
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                borderBottom: `3px solid ${COLORS.purple}`,
                borderRadius: "20px",
              },
            }}
          >
            <Tab label="Notifikasi" />
            <Tab label="Requests" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              margin: "30px 0px",
            }}
          >
            {notifList.map((item, index) => (
              <Card
                style={{
                  padding: "6px",
                  color: "#221E5B",
                  borderRadius: "10px",
                }}
                key={index}
              >
                <CardContent>
                  <Grid container spacing={6} alignItems="center">
                    <Grid item xs={1}>
                      <img
                        src={locationImage}
                        style={{ width: "40px" }}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h5">
                        <b>{item.title}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "right" }}
                      >
                        {moment(item.created_at).format("HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="h6" style={{ margin: "10px 0px" }}>
                    <b>{item.desc}</b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: COLORS.textColorGray2 }}
                  >
                    Pada : {item.date}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: COLORS.textColorGray2 }}
                  >
                    Jam kerja : {item.shift}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: COLORS.textColorGray2 }}
                  >
                    Lokasi kerja : {item.company_name}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              margin: "30px 0px",
            }}
          >
            {inboxList.map((item, index) => (
              <Card
                style={{
                  padding: "6px",
                  color: "#221E5B",
                  borderRadius: "10px",
                }}
                key={index}
                onClick={() => {
                  navigate(`/mail-detail/${item.type}/${item.id}`);
                }}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={6}
                    alignItems="center"
                    style={{ marginBottom: "5px" }}
                  >
                    <Grid item xs={1}>
                      <img
                        src={
                          item.title === "Req.Overtime"
                            ? overtimeImage
                            : item.title === "Req.Time-Off"
                            ? timeOffImage
                            : locationImage
                        }
                        style={{ width: "40px" }}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h5">
                        <b>{item.title}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "right" }}
                      >
                        {moment(item.created_at).format("HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle2"
                    style={{ color: COLORS.textColorGray2 }}
                  >
                    Pada : {item.date}
                  </Typography>
                  {item.time && (
                    <Typography
                      variant="subtitle2"
                      style={{ color: COLORS.textColorGray2 }}
                    >
                      Pukul : {item.time}
                    </Typography>
                  )}
                  {item.desc !== undefined && (
                    <Typography
                      variant="subtitle2"
                      style={{ color: COLORS.textColorGray2 }}
                    >
                      Deskripsi : {item.desc ?? "-"}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle2"
                    style={{
                      color:
                        item.status === "approved"
                          ? COLORS.purple
                          : COLORS.redPrimary,
                    }}
                  >
                    <b>Status : {item.status}</b>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </TabPanel>
      </Box>
      <BottomNavigation />
      <ErrorDialog
        open={status.status === "error"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <SuccessDialog
        open={status.status === "success"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
      <DialogNotifDetail
        isOpenDialogNotifDetail={isOpenDialogNotifDetail}
        setOpenDialogNotifDetail={setOpenDialogNotifDetail}
        notifDetail={notifDetail}
        handleApprove={handleRequestApprove}
        handleReject={handleRequestReject}
      />
    </div>
  );
}

export default MailPage;
