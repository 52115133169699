import { Button, CircularProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import material UI
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { navigate } from 'hookrouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import PinInput from 'react-pin-input';
import { useSelector } from 'react-redux';
import BackToolbar from '../../../components/BackToolbar';
import ErrorDialog from '../../../components/Dialog/ErrorDialog';
import PreRequestAttendance from '../../../components/HOC/PreRequestAttendance';
import { COLORS } from '../../../helper/constants/colors';
import { VALUES } from '../../../helper/constants/ValueConstants';
import { Endpoint } from '../../../helper/utils/ApiEndpoint';
//import custom lib
import { getToken, isAuthenticated } from '../../../helper/utils/AuthData';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
	},
	backButton: {
		marginRight: theme.spacing(2),
	},
	fixedBottomButton: {
		width: '100%',
		height: '5rem',
		bottom: 0,
		left: 0,
		right: 0,
		borderTopLeftRadius: 14,
		borderTopRightRadius: 14,
		borderBottomLeftRadius: 14,
		borderBottomRightRadius: 14,
	},
}));

const PinClockInOut = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false); //true
	const [error, setError] = useState({}); //true
	const [pinValue, setPinValue] = useState(false);
	const verificationMethod = useSelector(state => state.verificationMethod);

	useEffect(
		() => {
			if (!isAuthenticated()) {
				navigate('/');
			}
		} /* eslint-disable-line */,
		[]
	);

	const handleClose = () => {
		setError({});
	};

	const verificationPin = async () => {
		setLoading(true);
		let response;
		try {
			response = await axios.post(
				`${Endpoint}human-resource/attendance/pin-verification`,
				{
					Attendance: {
						pin: pinValue,
					},
				},
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			if (verificationMethod.data?.verification.verification_member_password === 1) {
				navigate('/live-attendance/password');
			} else {
				navigate('/live-attendance/clock-in-out');
			}
		} catch (e) {
			response = {};
			console.log(e.response);
			setError({ info: 'Konfirmasi password gagal', message: e.response.data.info.message });
		} finally {
			setLoading(false);
		}
		return response;
	};

	return (
		<PreRequestAttendance>
			<div className={classes.root}>
				<AppBar
					position="static"
					style={{
						background: '#fff',
						borderTopLeftRadius: 24,
						borderTopRightRadius: 24,
					}}
					//elevation={0}
				>
					<BackToolbar title={'Masukan Pin'} />
				</AppBar>
				<Card
					style={{
						margin: '0 auto',
						width: '100%',
						background: '#fff',
						maxWidth: VALUES.maxScreenWidth,
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 24,
						borderBottomRightRadius: 24,
					}}
				>
					<CardContent>
						<div
							style={{
								color: '#221E5B',
								marginTop: '10rem',
								marginBottom: '17rem',
								textAlign: 'center',
							}}
						>
							<PinInput
								length={6}
								focus
								secret
								inputStyle={{ borderRadius: '10px', border: '2px solid grey' }}
								onComplete={(value, index) => {
									setPinValue(value);
								}}
								onChange={(value, index) => {
									if (value.length < 6) {
										setPinValue(false);
									}
								}}
								type="numeric"
							/>
						</div>
						<Button
							variant="contained"
							className={classes.fixedBottomButton}
							style={{ backgroundColor: pinValue === false ? COLORS.gray3 : COLORS.purple, color: COLORS.white }}
							onClick={() => pinValue !== false && verificationPin()}
						>
							{loading ? <CircularProgress sx={{ color: COLORS.gray3 }} /> : <b>Input</b>}
						</Button>
					</CardContent>
				</Card>
				<ErrorDialog open={!isEmpty(error)} info={error.info} message={error.message} handleClose={handleClose} />
			</div>
		</PreRequestAttendance>
	);
};

export default PinClockInOut;
