import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { MeetingRoom, LocationOn, LockOpen } from "@material-ui/icons";
import { navigate } from "hookrouter";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import absenceImage from "../../assets/icons/absence.svg";
import rightArrow from "../../assets/icons/right-arrow.svg";
import userImage from "../../assets/images/user.png";
import BottomNavigation from "../../components/BottomNavigation";
import ListItem from "../../components/List/ListItem";
import ListView from "../../components/List/ListView";
import Picture from "../../components/List/Picture";
import AppLoader from "../../components/Loader/AppLoader";
import { AUTH_CONSTANT } from "../../helper/constants/AuthConstant";
import { VALUES } from "../../helper/constants/ValueConstants";
import { isAuthenticated } from "../../helper/utils/AuthData";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    marginBottom: 100,
  },
}));

function ProfilePage() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState({});

  const verificationMethod = useSelector((state) => state.verificationMethod);

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      let jsonAuthData = JSON.parse(localStorage.getItem(AUTH_CONSTANT.data));
      setAuthData(jsonAuthData);
    } /* eslint-disable-line */,
    []
  );

  const closeLoader = () => {
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem(AUTH_CONSTANT.data);
    navigate("/");
  };

  return (
    <>
      <div className={classes.root}>
        {/* Profile Card */}
        <Card
          style={{
            margin: "0 auto",
            width: "100%",
            background: "#221E5B",
            maxWidth: VALUES.maxScreenWidth,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            marginBottom: "1rem",
          }}
        >
          <CardContent>
            <Typography variant="h5" style={{ color: "#fff" }}>
              <b>Akun</b>
            </Typography>
          </CardContent>
          <CardContent
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Picture picture={userImage} style={{ backgroundColor: "white" }} />
            <Typography
              variant="h6"
              style={{ color: "#fff", marginTop: "1rem" }}
            >
              {authData.data?.member_first_name}{" "}
              {authData.data?.member_last_name}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ color: "#fff", textAlign: "center" }}
              decoration="bold"
            >
              {verificationMethod.data?.work_pattern?.name}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ color: "#fff", textAlign: "center" }}
              decoration="bold"
            >
              {verificationMethod.data?.schedule?.name}
            </Typography>
          </CardContent>
        </Card>
        <div
          style={{
            margin: "0 auto",
            width: "95%",
            maxWidth: VALUES.maxScreenWidth,
            marginTop: "1rem",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#221E5B", textAlign: "left", marginLeft: "10px" }}
            decoration="bold"
          >
            <b>Attendance Summary</b>
          </Typography>
        </div>
        <div
          style={{
            margin: "0 auto",
            width: "100%",
            maxWidth: VALUES.maxScreenWidth,
            borderRadius: 24,
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <ListView style={{ gap: "20px", margin: "0px 20px" }}>
            <ListItem
              iconPicture={absenceImage}
              pictureProps={{ style: { width: 40, height: 40 } }}
              primaryText={"Absence"}
              primaryTextProps={{ style: { fontSize: "20px" } }}
              secondaryText={false}
              trailPicture={rightArrow}
              border
              onClick={() => navigate("/profile/absence")}
            />
            <ListItem
              icon={
                <LocationOn
                  style={{ color: "#221E5B", width: 40, height: 40 }}
                />
              }
              pictureProps={{ style: { width: 40, height: 40 } }}
              primaryText={"Presence"}
              primaryTextProps={{ style: { fontSize: "20px" } }}
              secondaryText={false}
              trailPicture={rightArrow}
              border
              onClick={() => navigate("/profile/presence")}
            />
            <ListItem
              icon={
                <LockOpen style={{ color: "#221E5B", width: 40, height: 40 }} />
              }
              pictureProps={{ style: { width: 40, height: 40 } }}
              primaryText={"Change Pin"}
              primaryTextProps={{ style: { fontSize: "20px" } }}
              secondaryText={false}
              trailPicture={rightArrow}
              border
              onClick={() => navigate("/change-pin")}
            />
            <ListItem
              icon={
                <MeetingRoom
                  style={{ color: "#221E5B", width: 40, height: 40 }}
                />
              }
              pictureProps={{ style: { width: 40, height: 40 } }}
              primaryText={"Sign Out"}
              primaryTextProps={{ style: { fontSize: "20px" } }}
              secondaryText={false}
              trailPicture={rightArrow}
              onClick={() => handleLogout()}
            />
          </ListView>
        </div>
        <BottomNavigation />
      </div>
      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeLoader}
        loaderText="Fetching Data ..."
      />
    </>
  );
}

export default ProfilePage;
