import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { COLORS } from "../../helper/constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
    columnGap: "20px",
    alignItems: "center",
    justifyItems: "center",
  },
  text: (props) => ({
    display: "flex",
    flexDirection: "column",
    alignSelf: props.secondaryText === false ? "center" : "flex-start",
  }),
  picture: {
    borderRadius: 50,
    width: "70px",
    height: "70px",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  iconPicture: {
    width: "70px",
    height: "70px",
  },
  trailPicture: {
    width: 30,
    height: 30,
  },
  primaryText: {
    margin: 0,
    padding: 0,
    color: COLORS.purple,
    fontSize: "22px",
    fontWeight: "bold",
  },
  secondaryText: {
    margin: 0,
    padding: 0,
    color: COLORS.textColorGray2,
    fontSize: "17px",
  },
  div: {
    borderRadius: "50%",
    width: 60,
    height: 60,
    overflow: "hidden",
    display: "flex",
    position: "relative",
  },

  border: { border: `2px solid ${COLORS.gray1}`, borderRadius: "50px" },
}));

function ListItem({
  primaryText,
  secondaryText,
  style,
  iconPicture,
  icon,
  picture,
  pictureProps,
  primaryTextProps,
  secondaryTextProps,
  border,
  borderProps,
  trailPicture,
  trailPictureProps,
  ...other
}) {
  const classes = useStyles({ secondaryText });

  return (
    <>
      <div className={classes.root} style={style} {...other}>
        <div style={{ gridColumn: "span 1 / span 1", position: "relative" }}>
          {iconPicture ? (
            <img
              src={picture ?? iconPicture}
              alt=""
              className={picture ? classes.picture : classes.iconPicture}
              {...pictureProps}
            />
          ) : (
            icon
          )}
        </div>
        <div
          className={classes.text}
          style={{ gridColumn: "span 4 / span 4", justifySelf: "self-start" }}
        >
          <h2 className={classes.primaryText} {...primaryTextProps}>
            {primaryText}
          </h2>
          {secondaryText !== false && (
            <h3 className={classes.secondaryText} {...secondaryTextProps}>
              {secondaryText}
            </h3>
          )}
        </div>
        {trailPicture && (
          <div
            style={{ justifySelf: "flex-end", gridColumn: "span 1 / span 1" }}
          >
            <img
              src={trailPicture}
              alt=""
              className={classes.trailPicture}
              {...trailPictureProps}
            />
          </div>
        )}
      </div>
      {border && <div className={classes.border} {...borderProps}></div>}
    </>
  );
}

export default ListItem;
