import { combineReducers } from '@reduxjs/toolkit';
import bottomNavigation from './bottomNavigation';
import historyDetail from './historyDetail';
import inbox from './inbox';
import team from './team';
import verificationMethod from './verificationMethod';

const rootReducer = combineReducers({
	verificationMethod,
	historyDetail,
	bottomNavigation,
	team,
	inbox,
});

export default rootReducer;
