import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
//import material UI
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { CameraAlt, Error, KeyboardBackspace, Room } from "@material-ui/icons";
import axios from "axios";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { navigate } from "hookrouter";
import "moment-timezone";
import "moment/locale/id";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import minusLogo from "../../../assets/icons/ic_minus_red.svg";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialogAttendance from "../../../components/Dialog/SuccessDialogAttendance";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { getTeam } from "../../../store/Attendance/team";
import { getVerificationMethod } from "../../../store/Attendance/verificationMethod";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  cameraButton: {
    position: "absolute",
    left: "50%",
    bottom: "50px",
    width: "6rem",
    height: "6rem",
    border: `12px solid ${COLORS.purple}`,
    backgroundColor: COLORS.gray3,
    borderRadius: "100%",
    transform: "translate(-50%, 0%)",
  },
  cameraDiv: {
    border: `2px solid ${COLORS.gray3}`,
    marginTop: "30px",
    borderRadius: "10px",
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    color: "#fff !important",
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const ClockInOut = () => {
  const webcamRef = useRef();
  const classes = useStyles();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  //if this false, then it was clock out
  const [isClockIn, setIsClockIn] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [openCamera, setOpenCamera] = useState(false);
  const [picture, setPicture] = useState(null);
  const [currentTime, setCurrentTime] = useState({
    time: "00:00",
    date: "01-01-2022",
  });
  const [note, setNote] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);

  const verificationMethod = useSelector((state) => state.verificationMethod);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    if (verificationMethod.data?.clock_in) {
      setIsClockIn(false);
    } else {
      setIsClockIn(true);
    }

    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {
        console.log(permissionObj);
      })
      .catch((error) => {
        console.log("Got error :", error);
      });

    getLocation();
    setClock();
  }, []);

  const addZero = (value) => {
    if (parseInt(value) < 10) {
      return `0${value}`;
    }
    return value;
  };

  const setClock = () => {
    let now = new Date();

    let hour = addZero(now.getHours());
    let minute = addZero(now.getMinutes());
    let date = addZero(now.getDate());
    let month = addZero(parseInt(now.getMonth()) + 1);
    setCurrentTime({
      time: `${hour}:${minute}`,
      date: `${now.getFullYear()}-${month}-${date}`,
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/live-attendance");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const takePicture = () => {
    console.log(webcamRef.current.getScreenshot());
    setPicture(webcamRef.current.getScreenshot());
    setOpenCamera(false);
  };

  const dataURItoBlob = () => {
    var binary = atob(picture.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  const storeClockIn = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.set("Attendance[time]", `${currentTime.date} ${currentTime.time}`);
      body.set("Attendance[long]", longitude);
      body.set("Attendance[lat]", latitude);
      body.set("Attendance[note]", note);

      const pictureFile = dataURItoBlob();
      body.append("Attendance[photo]", pictureFile);
      let headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      };

      if (isClockIn) {
        body.set(
          "Attendance[schedule_id]",
          verificationMethod.data.schedule.id
        );
        let response = await axios.post(
          Endpoint + "human-resource/attendance/clock-in",
          body,
          {
            headers,
          }
        );
        setStatus({
          status: "success",
          info: "Berhasil melakukan Clock In",
          message: response.data.info.message,
        });
      } else {
        body.set(
          "Attendance[attendance_id]",
          verificationMethod.data.clock_in.id
        );
        let response = await axios.post(
          Endpoint + "human-resource/attendance/clock-out",
          body,
          {
            headers,
          }
        );
        setStatus({
          status: "success",
          info: "Berhasil melakukan Clock Out",
          message: response.data.info.message,
        });
      }
      dispatch(getVerificationMethod());
      dispatch(getTeam());
    } catch (e) {
      console.log(e);
      if (isClockIn) {
        setStatus({
          status: "error",
          info: "Gagal melakukan Clock In",
          message: e.response?.data?.info?.message,
        });
      } else {
        setStatus({
          status: "error",
          info: "Gagal melakukan Clock Out",
          message: e.response?.data?.info?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PreRequestAttendance>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            background: "#fff",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
          //elevation={0}
        >
          <Toolbar>
            <IconButton
              edge="start"
              style={{
                color: "#221E5B",
              }}
              className={classes.backButton}
              onClick={() => {
                openCamera ? setOpenCamera(false) : window.history.go(-1);
              }}
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1, color: "#221E5B" }}>
              {isClockIn ? "Clock In" : "Clock Out"}
            </Typography>
          </Toolbar>
        </AppBar>
        {openCamera ? (
          <Card
            style={{
              margin: "0 auto",
              width: "100%",
              height: "90vh",
              background: COLORS.black,
              maxWidth: VALUES.maxScreenWidth,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 24,
              borderBottomRightRadius: 24,
              marginBottom: "1rem",
            }}
          >
            <div id="webcam">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
            </div>
            <button
              className={classes.cameraButton}
              onClick={() => takePicture()}
            ></button>
          </Card>
        ) : (
          <div>
            <Map
              google={window.google}
              onReady={() => {
                navigator.geolocation.getCurrentPosition((position) => {
                  setLatitude(position.coords.latitude);
                  setLongitude(position.coords.longitude);
                });
              }}
              zoom={19}
              style={{
                width: "100%",
                height: "50%",
                maxWidth: VALUES.maxScreenWidth,
              }}
              initialCenter={{
                lat: "-6.3653889",
                lng: "106.8428199",
              }}
            >
              <Marker
                title={"PT Amanah Karya Indonesia"}
                name={"PT Amanah Karya Indonesia"}
              />
            </Map>

            <Card
              style={{
                margin: "0 auto",
                width: "100%",
                background: "#fff",
                maxWidth: VALUES.maxScreenWidth,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 24,
                borderBottomRightRadius: 24,
                marginBottom: "1rem",
                position: "absolute",
                top: "419px",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="input-time">Pukul</InputLabel>
                      <Input
                        id="input-time"
                        type="time"
                        value={currentTime.time}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="input-location">Lokasi</InputLabel>
                      <Input
                        id="input-location"
                        value={`${latitude}, ${longitude}`}
                        startAdornment={
                          <InputAdornment position="start">
                            <Room />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="Catatan"
                  placeholder="Masukkan deskripsi (Opsional)"
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
                <div className={classes.cameraDiv}>
                  <Button
                    fullWidth
                    variant="text"
                    color="primary"
                    style={{
                      height: "3rem",
                    }}
                    startIcon={<CameraAlt />}
                    onClick={() => setOpenCamera(true)}
                  >
                    Ambil Foto Selfie
                  </Button>
                  {picture != null && (
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        margin: "20px",
                      }}
                    >
                      <img src={picture} alt="" style={{ width: "150px" }} />
                      <div
                        style={{ position: "absolute", right: -15, top: -15 }}
                        onClick={() => setPicture(null)}
                      >
                        <img
                          src={minusLogo}
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    color: "#262261",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    fontSize: "x-small",
                  }}
                >
                  <Error />
                  <span style={{ marginLeft: "2px" }}>
                    wajib mengupload foto selfie untuk attendance.
                  </span>
                </div>
                <Button
                  variant="contained"
                  className={classes.fixedBottomButton}
                  style={{
                    marginTop: "1rem",
                    background:
                      picture === null
                        ? COLORS.gray3
                        : isClockIn
                        ? COLORS.purple
                        : COLORS.redPrimary,
                  }}
                  onClick={storeClockIn}
                  disabled={picture === null ? true : false}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: COLORS.gray3 }} />
                  ) : (
                    <b>{isClockIn ? "Clock In" : "Clock Out"}</b>
                  )}
                </Button>
              </CardContent>
            </Card>
          </div>
        )}
        <ErrorDialog
          open={status.status === "error"}
          info={status.info}
          message={status.message}
          handleClose={handleClose}
        />
        <SuccessDialogAttendance
          title={isClockIn ? "Clock In Berhasil" : "Clock Out Berhasil"}
          longitude={longitude}
          latitude={latitude}
          open={status.status === "success"}
          info={status.info}
          message={status.message}
          handleClose={handleClose}
        />
        <AppLoader
          isLoaderOpen={verificationMethod.loading}
          onRequestClose={() => {}}
          loaderText="Loading data ..."
        />
      </div>
    </PreRequestAttendance>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBC60Frrtjsa148QKyqtK9pPOqCJ_i4t-M",
  LoadingContainer: () => (
    <AppLoader
      isLoaderOpen={true}
      onRequestClose={() => {}}
      loaderText="Loading data ..."
    />
  ),
})(ClockInOut);
