export const VALUES = {
	maxScreenWidth: 500,
	minScreenWidth: 225,
	titlebarHeight: 45,
	days: ['Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu'],
	months: [
		'Januari',
		'Febuari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember',
	],
};
