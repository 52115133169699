import React, { useState, useEffect } from "react";
//import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
import axios from "axios";

//import material UI
import {
  //Container,
  //Row,
  //Col,
  Image,
} from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";

//import material icon
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
//import DeleteIcon from "@material-ui/icons/Delete";
//import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

//import lib component
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import {
  CircularProgressbar,
  //CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//import image icon assets
import avatarUser from "../../../assets/icons/ic_avatar_user.png";
import iconTargetValue from "../../../assets/icons/ic_blue_target_value.png";
import iconExpectedValue from "../../../assets/icons/ic_expected_value.png";
import iconDaysLeftValue from "../../../assets/icons/ic_value_days_left.png";
import iconGapValue from "../../../assets/icons/ic_value_gap.png";
import iconResultValue from "../../../assets/icons/ic_value_result.png";
import iconStartValue from "../../../assets/icons/ic_start_value.png";
import iconMeasuredActivity from "../../../assets/icons/ic_ma.png";

//import custom lib
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import UseGeoLocation from "../../../components/UseGeoLocation";
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";
import {
  DecimalFormat,
  DecimalFormatWithPercentage,
  CurrencyFormat,
} from "../../../helper/utils/StringFormatter";

//import custom component
import AppLoader from "../../../components/Loader/AppLoader";
import MeasuredActivityResultFormCreate from "../MeasuredActivityResult/MeasuredActivityResultFormCreate";
import MeasuredActivityResultCollection from "../MeasuredActivityResult/MeasuredActivityResultCollection";

//css style
const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    width: "100%",
    //height: "100vh",
    color: "white",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    minWidth: VALUES.minScreenWidth,
    background: "linear-gradient(180deg, #6AB2D6, #053F9E)",
  },
  titlebarOuter: {
    display: "table",
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: VALUES.maxScreenWidth,
    backgroundColor: "#6AB2D6",
    height: VALUES.titlebarHeight,
    borderRadius: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  titlebarInner: {
    display: "table-cell",
    verticalAlign: "middle",
    paddingLeft: 20,
  },
  mainContainer: {
    marginTop: 45,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 60,
  },
  ownerTitle: {
    display: "flex",
    alignItems: "center", // middle / center vertical
    alignContent: "center",
    flexDirection: "row",
  },
  titleText: {
    color: COLORS.textColorGray1,
    fontSize: 13,
  },
  valueText: {
    color: "white",
    fontSize: 14,
    fontFamily: "NunitoSemiBold",
    marginLeft: 10,
  },
  valueIcon: {
    width: 16,
    height: 16,
  },
  textMethod: {
    fontSize: 12,
    color: COLORS.yellowPrimary,
    backgroundColor: COLORS.yellowPrimaryOpacity20,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 6,
  },
  spacer: {
    height: 20,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

//custom horizontal progress bar
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const MeasuredActivityDetail = (props) => {
  //init makestyles classes
  const classes = useStyles();

  //state measured activity id
  const [measuredActivityId, setMeasuredActivityId] = useState("");

  //state show form add measured activity result
  const [
    isMeasuredActivityResultFormShow,
    setIsMeasuredActivityResultFormShow,
  ] = useState(false);

  //state loader
  const [loading, setLoading] = useState(false);

  //state request success
  const [isSuccess, setIsSuccess] = useState(false);

  //state measured activity data derail
  const [measuredActivityData, setMeasuredActivityData] = useState({});

  //state circle percentage value
  const [percentage, setPercentage] = useState(0);
  const [progressbarColor, setProgressbarColor] = useState(COLORS.redPrimary);

  //state show bottom sheet
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  //location
  const location = UseGeoLocation();

  //run first before laod page
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    //set measured activity id
    console.log("ma id", props.id);
    setMeasuredActivityId(props.id);

    //get measured activity data detail by id
    getMeasuredActivityDetail(props.id);
  }, []);

  //handle show form add MAR
  const handleShowMeasuredActivityResultForm = () => {
    setIsMeasuredActivityResultFormShow(true);
  };

  //handle close form add MAR
  const handleCloseMeasuredActivityResultForm = () => {
    setIsMeasuredActivityResultFormShow(false);
  };

  //handle click set show bottom sheet
  const handleShowBottomSheet = () => {
    setIsBottomSheetOpen(!isBottomSheetOpen);
  };

  //handle on change bottom sheet show
  const handleOnChangeBottomSheet = (open) => {
    setIsBottomSheetOpen(open);
  };

  //handle close loader
  function closeLoader() {
    setLoading(false);
  }

  //request get measured activity detail by id
  const getMeasuredActivityDetail = (measuredActivityId) => {
    //show loader
    setLoading(true);

    //Get Request
    let searchParam = "";
    //if (textSearch !== "") {
    //searchParam = "&options[filter][search]=" + txtSearch;
    //}
    //let filterByStatusParam = "&options[filter][status_id]=" + statusId;

    axios
      .get(Endpoint + "swd/measured-activity/" + measuredActivityId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((response) => {
        //hide loader
        setLoading(false);

        //set request success true
        setIsSuccess(true);

        //set measured activity detail data
        //const responseData = JSON.parse(response.data);
        const responseData = response.data;
        setMeasuredActivityData(responseData);

        //set value
        let ora =
          responseData.data.calculatedValue.resultOriented
            .overall_result_achievement * 100;

        let progressbarColorValue = COLORS.redPrimary;
        if (ora < 85) {
          progressbarColorValue = COLORS.redPrimary;
        } else if (ora < 100) {
          progressbarColorValue = COLORS.yellowPrimary;
        } else {
          progressbarColorValue = COLORS.greenPrimary;
        }

        setPercentage(ora);
        setProgressbarColor(progressbarColorValue);

        //console.log("response ma detail", JSON.stringify(responseData));
      })
      .catch((error) => {
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  return (
    <div className={classes.root}>
      {/* <div style={{ padding: 20 }}>
        <GoalResultFormCreate goalId={goalId} />
      </div> */}

      <div className={classes.titlebarOuter}>
        <div className={classes.titlebarInner}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={iconMeasuredActivity}
              //roundedCircle
              style={{ width: 20, height: 20 }}
            />
            <label style={{ marginLeft: 10 }}>Measured Activity</label>

            <div style={{ marginLeft: "auto" }}>
              <IconButton
                aria-label="add"
                onClick={handleShowMeasuredActivityResultForm}
              >
                <AddCircleOutlineIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainContainer}>
        <div className={classes.ownerTitle}>
          <Image
            src={avatarUser}
            roundedCircle
            style={{ width: 35, height: 35 }}
          />
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
          >
            <label style={{ fontFamily: "NunitoBold", fontSize: 13 }}>
              {measuredActivityData.data &&
                measuredActivityData.data.member.first_name +
                  " " +
                  measuredActivityData.data.member.last_name}
            </label>
            <label style={{ fontSize: 12 }}>
              {measuredActivityData.data &&
                measuredActivityData.data.owner.structure_position_title_name}
            </label>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div
            style={{
              margin: "0 auto",
              width: 150,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgressbar
              value={percentage}
              //text={`${percentage}%`}
              strokeWidth={5}
              styles={buildStyles({
                textColor: "white",
                pathColor: progressbarColor,
                trailColor: COLORS.gray1,
              })}
            />

            <Typography
              style={{
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                fontSize: 25,
              }}
            >
              {measuredActivityData.data &&
                DecimalFormatWithPercentage(
                  measuredActivityData.data.calculatedValue.resultOriented
                    .overall_result_achievement
                )}
              %
            </Typography>
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: 15 }}>
          {measuredActivityData.data && measuredActivityData.data.name}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <label className={classes.textMethod} style={{ marginRight: 5 }}>
            {measuredActivityData.data &&
              measuredActivityData.data.achievementType.name}
          </label>
          <label className={classes.textMethod} style={{ marginRight: 5 }}>
            {measuredActivityData.data &&
              measuredActivityData.data.calculationMethod.name}
          </label>
          <label className={classes.textMethod} style={{ marginRight: 5 }}>
            {measuredActivityData.data &&
              measuredActivityData.data.inputMethod.name}
          </label>
          <label className={classes.textMethod}>
            {measuredActivityData.data &&
              measuredActivityData.data.targetType.name}
          </label>
        </div>

        <div style={{ marginTop: 15 }}>
          <BorderLinearProgress variant="determinate" value={percentage} />
        </div>

        <table style={{ marginTop: 20, width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <label className={classes.titleText}>Nilai Awal</label> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconStartValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <label className={classes.valueText}>
                    {measuredActivityData.data &&
                      measuredActivityData.data.calculatedValue.start_value}
                  </label>
                </div>
              </td>
              <td>
                <div style={{ textAlign: "left", float: "right" }}>
                  <label className={classes.titleText}>Nilai Target</label>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    <Image
                      src={iconTargetValue}
                      roundedCircle
                      className={classes.valueIcon}
                    />
                    <label
                      className={classes.valueText}
                      style={{ color: COLORS.bluePrimary }}
                    >
                      {measuredActivityData.data &&
                        measuredActivityData.data.calculatedValue.target_value}
                    </label>
                  </div>
                </div>
              </td>
            </tr>

            <tr className={classes.spacer}></tr>

            <tr>
              <td>
                <label className={classes.titleText}>Nilai Aktual</label> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconResultValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label className={classes.valueText}>
                      {measuredActivityData.data &&
                        DecimalFormatWithPercentage(
                          measuredActivityData.data.calculatedValue
                            .actualOriented.actual_value_achievement
                        )}
                      %
                    </label>
                    <label className={classes.valueText}>
                      {measuredActivityData.data &&
                        CurrencyFormat(
                          measuredActivityData.data.calculatedValue
                            .actualOriented.actual_value
                        )}
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div style={{ textAlign: "left", float: "right" }}>
                  <label className={classes.titleText}>Nilai Hasil</label>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    <Image
                      src={iconResultValue}
                      roundedCircle
                      className={classes.valueIcon}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label className={classes.valueText}>
                        {measuredActivityData.data &&
                          DecimalFormatWithPercentage(
                            measuredActivityData.data.calculatedValue
                              .resultOriented.result_value_achievement
                          )}
                        %
                      </label>
                      <label className={classes.valueText}>
                        {measuredActivityData.data &&
                          CurrencyFormat(
                            measuredActivityData.data.calculatedValue
                              .resultOriented.result_value
                          )}
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr className={classes.spacer}></tr>

            <tr>
              <td>
                <label className={classes.titleText}>
                  Nilai yang diharapkan
                </label>{" "}
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconExpectedValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      className={classes.valueText}
                      style={{ color: COLORS.greenPrimary }}
                    >
                      {measuredActivityData.data &&
                        DecimalFormatWithPercentage(
                          measuredActivityData.data.calculatedValue
                            .resultOriented.expected_result_value_achievement
                        )}
                      %
                    </label>
                    <label
                      className={classes.valueText}
                      style={{ color: COLORS.greenPrimary }}
                    >
                      {measuredActivityData.data &&
                        CurrencyFormat(
                          measuredActivityData.data.calculatedValue
                            .resultOriented.expected_result_value
                        )}
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div style={{ textAlign: "left", float: "right" }}>
                  <label className={classes.titleText}>Nilai Selisih</label>{" "}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                    }}
                  >
                    <Image
                      src={iconGapValue}
                      roundedCircle
                      className={classes.valueIcon}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label
                        className={classes.valueText}
                        style={{ color: COLORS.yellowPrimary }}
                      >
                        {measuredActivityData.data &&
                          DecimalFormatWithPercentage(
                            measuredActivityData.data.calculatedValue
                              .resultOriented.gap_result_value_achievement
                          )}
                        %
                      </label>
                      <label
                        className={classes.valueText}
                        style={{ color: COLORS.yellowPrimary }}
                      >
                        {measuredActivityData.data &&
                          CurrencyFormat(
                            measuredActivityData.data.calculatedValue
                              .resultOriented.gap_result_value
                          )}
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr className={classes.spacer}></tr>

            <tr>
              <td>
                <label className={classes.titleText}>Sisa Hari</label> <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Image
                    src={iconDaysLeftValue}
                    roundedCircle
                    className={classes.valueIcon}
                  />
                  <label
                    className={classes.valueText}
                    style={{ color: COLORS.yellowPrimary }}
                  >
                    {measuredActivityData.data &&
                      measuredActivityData.data.time.remaining_days}
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <SwipeableBottomSheet
        overflowHeight={50}
        marginTop={150}
        overlay={false}
        topShadow={false}
        shadowTip={false}
        fullScreen={true}
        open={isBottomSheetOpen}
        onChange={handleOnChangeBottomSheet.bind(this)}
        //scrollTopAtClose={true}
        //defaultOpen={false}
        //overlayStyle={{}}
        //swipeableViewsProps={{disabled:false}}
        //onTransitionEnd={}
        style={{
          margin: "0 auto",
          width: "100%",
          backgroundColor: "transparent",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          maxWidth: VALUES.maxScreenWidth,
        }}
        bodyStyle={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <div
          style={{
            //height: screenHeight, //"240px",
            color: COLORS.textColorGray4,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            background: "white",
            padding: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              //top: 0,
              //position: "absolute",
              //marginTop: -1,
              //backgroundColor: "white",
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <label style={{ color: COLORS.textColorGray4, fontSize: 14 }}>
              Measured Activity Result
            </label>
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                aria-label="open-detail"
                style={{ padding: 0 }}
                onClick={handleShowBottomSheet}
              >
                {isBottomSheetOpen ? (
                  <KeyboardArrowDownIcon style={{ color: "gray" }} />
                ) : (
                  <KeyboardArrowUpIcon style={{ color: "gray" }} />
                )}
              </IconButton>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <MeasuredActivityResultCollection
              measuredActivityId={measuredActivityId}
            />
          </div>
        </div>
      </SwipeableBottomSheet>

      <Modal
        open={isMeasuredActivityResultFormShow}
        onClose={handleCloseMeasuredActivityResultForm}
        className={classes.modal}
        closeAfterTransition
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        {
          <div style={{ padding: 0, outline: 0 }}>
            {
              <MeasuredActivityResultFormCreate
                measuredActivityId={measuredActivityId}
                onCloseFormDialog={handleCloseMeasuredActivityResultForm}
                action="add"
                existingData=""
              />
            }
          </div>
        }
      </Modal>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeLoader}
        loaderText="Loading ..."
      />
    </div>
  );
};

export default MeasuredActivityDetail;
