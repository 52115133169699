import React, { useEffect, useState } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { id } from "date-fns/locale";

import { Button, CircularProgress, FormControl } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Grid,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DurationPicker } from "material-duration-picker";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty, now } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { AlarmAddRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const RequestAttendance = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  // input
  const [workday, set_workday] = useState(new Date(now()));
  const [description, set_description] = useState("");
  const [clock_in, set_clock_in] = useState();
  const [clock_out, set_clock_out] = useState();

  const verificationMethod = useSelector((state) => state.verificationMethod);
  //const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
  }, []);

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/request-attendance");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const storeRequestAttendance = async () => {
    try {
      setLoading(true);
      const data = {
        RequestAttendance: {
          date: workday.toISOString().split("T")[0],
          clock_in: moment(clock_in).format("HH:mm"),
          clock_out: moment(clock_out).format("HH:mm"),
          deskripsi: description,
        },
      };

      const response = await axios.post(
        Endpoint + "human-resource/request-attendance",
        data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setStatus({
          status: "success",
          info: "success",
          message: "Successfully Requested",
        });
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Something went wrong",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PreRequestAttendance>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            background: "#fff",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
          //elevation={0}
        >
          <BackToolbar title={"Request Attendance"} />
        </AppBar>

        <Card
          style={{
            margin: "0 auto",
            width: "100%",
            background: "#fff",
            maxWidth: VALUES.maxScreenWidth,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
          }}
        >
          <CardContent>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date"
                  label="Kapan"
                  format="d, MMMM, yyyy"
                  value={workday}
                  onChange={(v) => {
                    set_workday(v);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="clockin"
                  label="Clock In"
                  format="HH:mm"
                  value={clock_in}
                  onChange={(v) => {
                    set_clock_in(v);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time clock in",
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="clockout"
                  label="Clock Out"
                  format="HH:mm"
                  value={clock_out}
                  onChange={(v) => {
                    set_clock_out(v);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time clock out",
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                marginTop: "1rem",
              }}
            >
              <TextField
                fullWidth
                label="Deskripsi"
                placeholder="Masukkan deskripsi (Opsional)"
                onChange={(e) => set_description(e.target.value)}
                value={description}
              />
            </FormControl>

            <Grid
              container
              spacing={3}
              style={{
                marginTop: "11rem",
              }}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={
                    () => {
                      set_workday(new Date(now()));
                      set_description("");
                    } /*handleCancel()*/
                  }
                  disabled={loading}
                >
                  Batal
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={
                    () => {
                      storeRequestAttendance();
                    } /*handleSubmit()*/
                  }
                  disabled={loading}
                >
                  Ajukan
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <ErrorDialog
          open={status.status === "error"}
          info={status.info}
          message={status.message}
          handleClose={handleClose}
        />
        <SuccessDialog
          open={status.status === "success"}
          info={status.info}
          message={status.message}
          handleClose={handleClose}
        />
        <AppLoader
          isLoaderOpen={verificationMethod.loading}
          onRequestClose={() => {}}
          loaderText="Loading data ..."
        />
      </div>
    </PreRequestAttendance>
  );
};

export default RequestAttendance;
