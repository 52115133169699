import React, { Fragment } from "react";
import CheckInOut from "./modules/Attendance/CheckInOut/CheckInOut";
import HistoryCheckInOut from "./modules/Attendance/CheckInOut/HistoryCheckInOut";
import LiveCheckInOut from "./modules/Attendance/CheckInOut/LiveCheckInOut";
import PasswordCheckInOut from "./modules/Attendance/CheckInOut/PasswordCheckInOut";
import PinCheckInOut from "./modules/Attendance/CheckInOut/PinCheckInOut";
import ClockInOut from "./modules/Attendance/ClockInOut/ClockInOut";
import DetailHistoryClockInOut from "./modules/Attendance/ClockInOut/DetailHistoryClockInOut";
import HistoryClockInOut from "./modules/Attendance/ClockInOut/HistoryClockInOut";
import LiveAttendance from "./modules/Attendance/ClockInOut/LiveAttendance";
import PasswordClockInOut from "./modules/Attendance/ClockInOut/PasswordClockInOut";
import PinClockInOut from "./modules/Attendance/ClockInOut/PinClockInOut";
import FingerPrint from "./modules/Attendance/FingerPrint";
import MainPage from "./modules/Attendance/MainPage";
import TeamPage from "./modules/Attendance/TeamPage";
import Login from "./modules/Auth/Login";
import GoalCollection from "./modules/SWD/Goal/GoalCollection";
import GoalDetail from "./modules/SWD/Goal/GoalDetail";
import MeasuredActivityDetail from "./modules/SWD/MeasuredActivity/MeasuredActivityDetail";
import HistoryOvertime from "./modules/Attendance/Overtime/HistoryOvertime";
import RequestOvertime from "./modules/Attendance/Overtime/RequestOvertime";
import HistoryRequestAttendance from "./modules/Attendance/RequestAttendance/HistoryRequestAttendance";
import RequestAttendance from "./modules/Attendance/RequestAttendance/RequestAttendance";
import RequestException from "./modules/Attendance/RequestException/RequestException";
import HistoryTimeOff from "./modules/Attendance/TimeOff/HistoryTimeOff";
import RequestTimeOff from "./modules/Attendance/TimeOff/RequestTimeOff";
import DetailTimeOffQuota from "./modules/Attendance/TimeOff/DetailTimeOffQuota";
import ProfilePage from "./modules/Attendance/ProfilePage";
import Absence from "./modules/Attendance/Profile/Absence";
import Presence from "./modules/Attendance/Profile/Presence";
import MailPage from "./modules/Attendance/MailPage";
import MySchedule from "./modules/Attendance/MySchedule";
import ReportPage from "./modules/Attendance/ReportPage";
import ChangePin from "./modules/Attendance/Profile/ChangePin";
import InboxDetail from "./modules/Attendance/Inbox/InboxDetail";

export const routes = {
  "/": () => (
    <Fragment>
      <Login />
    </Fragment>
  ),
  "/goal": () => (
    <Fragment>
      <GoalCollection />
    </Fragment>
  ),
  // "/goal-detail": () => (
  //   <Fragment>
  //     <GoalDetail />
  //   </Fragment>
  // ),
  "/goal-detail/:id*": ({ id }) => (
    <Fragment>
      <GoalDetail id={id} />
    </Fragment>
  ),

  "/measured-activity-detail/:id*": ({ id }) => (
    <Fragment>
      <MeasuredActivityDetail id={id} />
    </Fragment>
  ),

  "/attendance": () => (
    <Fragment>
      <MainPage />
    </Fragment>
  ),
  "/live-attendance": () => (
    <Fragment>
      <LiveAttendance />
    </Fragment>
  ),
  "/live-attendance/fingerprint": () => (
    <Fragment>
      <FingerPrint />
    </Fragment>
  ),
  "/live-attendance/pin": () => (
    <Fragment>
      <PinClockInOut />
    </Fragment>
  ),
  "/live-attendance/password": () => (
    <Fragment>
      <PasswordClockInOut />
    </Fragment>
  ),
  "/live-attendance/clock-in-out": () => (
    <Fragment>
      <ClockInOut />
    </Fragment>
  ),
  "/live-check-in-out": () => (
    <Fragment>
      <LiveCheckInOut />
    </Fragment>
  ),
  "/live-check-in-out/fingerprint": () => (
    <Fragment>
      <FingerPrint />
    </Fragment>
  ),
  "/live-check-in-out/pin": () => (
    <Fragment>
      <PinCheckInOut />
    </Fragment>
  ),
  "/live-check-in-out/password": () => (
    <Fragment>
      <PasswordCheckInOut />
    </Fragment>
  ),
  "/live-check-in-out/check-in-out": () => (
    <Fragment>
      <CheckInOut />
    </Fragment>
  ),
  "/history-clock-in-out": () => (
    <Fragment>
      <HistoryClockInOut />
    </Fragment>
  ),
  "/history-check-in-out": () => (
    <Fragment>
      <HistoryCheckInOut />
    </Fragment>
  ),
  "/history-clock-in-out/detail": () => (
    <Fragment>
      <DetailHistoryClockInOut />
    </Fragment>
  ),
  "/team": () => (
    <Fragment>
      <TeamPage />
    </Fragment>
  ),
  "/profile": () => (
    <Fragment>
      <ProfilePage />
    </Fragment>
  ),
  "/profile/presence": () => (
    <Fragment>
      <Presence />
    </Fragment>
  ),
  "/profile/absence": () => (
    <Fragment>
      <Absence />
    </Fragment>
  ),
  "/change-pin": () => (
    <Fragment>
      <ChangePin />
    </Fragment>
  ),
  "/mail": () => (
    <Fragment>
      <MailPage />
    </Fragment>
  ),
  "/mail-detail/:type*/:id*": ({ type, id }) => (
    <Fragment>
      <InboxDetail type={type} id={id} />
    </Fragment>
  ),
  "/my-schedule": () => (
    <Fragment>
      <MySchedule />
    </Fragment>
  ),
  // overtime
  "/overtime": () => (
    <Fragment>
      <HistoryOvertime />
    </Fragment>
  ),
  "/overtime/request": () => (
    <Fragment>
      <RequestOvertime />
    </Fragment>
  ),

  // request attendance
  "/request-attendance": () => (
    <Fragment>
      <HistoryRequestAttendance />
    </Fragment>
  ),
  "/request-attendance/request": () => (
    <Fragment>
      <RequestAttendance />
    </Fragment>
  ),

  //report
  "/report": () => (
    <Fragment>
      <ReportPage />
    </Fragment>
  ),
  // request attendance
  "/request-attendance": () => (
    <Fragment>
      <HistoryRequestAttendance />
    </Fragment>
  ),
  "/request-exception": () => (
    <Fragment>
      <RequestException />
    </Fragment>
  ),
  "/request-attendance/request": () => (
    <Fragment>
      <RequestAttendance />
    </Fragment>
  ),

  // time off
  "/time-off": () => (
    <Fragment>
      <HistoryTimeOff />
    </Fragment>
  ),
  "/time-off/request": () => (
    <Fragment>
      <RequestTimeOff />
    </Fragment>
  ),
  "/time-off/quota": () => (
    <Fragment>
      <DetailTimeOffQuota />
    </Fragment>
  ),
};
