import React, { useState, useEffect } from "react";
//import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
import axios from "axios";

//import material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
//import Alert from "@material-ui/core/Alert";

//import custom lib
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";
import {
  DecimalFormat,
  DecimalFormatWithPercentage,
  CurrencyFormat,
} from "../../../helper/utils/StringFormatter";

//import custom component
import AppLoader from "../../../components/Loader/AppLoader";
import GoalResultFormCreate from "./GoalResultFormCreate";
import GoalResultEvidenceItem from "../GoalResultEvidence/GoalResultEvidenceItem";

//confirm dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//snackbar transition
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

//css style
const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    width: "100%",
    //height: "100vh",
    //color: "white",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
    //background: "linear-gradient(180deg, #54507d, #25225e)",
  },
  titlebarOuter: {
    display: "table",
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: VALUES.maxScreenWidth,
    backgroundColor: "#54507d",
    height: VALUES.titlebarHeight,
    borderRadius: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const GoalResultCollectionItem = ({ goalId, goalResultData, onRemove }) => {
  //init make styles classes
  const classes = useStyles();

  //state show confirm delete dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //state show loader
  const [loading, setLoading] = useState(false);

  //state is request success
  const [isSuccess, setIsSuccess] = useState(false);

  //state show edit form goal result
  const [isGoalResultFormShow, setIsGoalResultFormShow] = useState(false);

  //state snackbar
  const [transition, setTransition] = useState(() => TransitionUp);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //state option menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //run first load page
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
  }, []);

  //handle show option menu
  const handleClick = (event) => {
    //show option menu
    setAnchorEl(event.currentTarget);
  };

  //handle close option menu
  const handleClose = () => {
    //close option menu
    setAnchorEl(null);
  };

  //handle click show edit form
  const handleEditGoalResult = (data) => {
    //close option menu
    setAnchorEl(null);

    //show edit form
    setIsGoalResultFormShow(true);

    //let jsonData = JSON.parse(data);
    console.log("data item", data);
  };

  //handle click show confirm dialog delete goal result
  const handleDeleteGoalresult = () => {
    //close option menu
    setAnchorEl(null);

    //show confirm delete dialog
    setOpenConfirmDialog(true);
  };

  //handle click close edit form
  const handleCloseGoalResultForm = () => {
    //close edit form dialog
    setIsGoalResultFormShow(false);
  };

  //handle click delete goal result
  const handleClickDeleteGoalResult = () => {
    //close confirm delete dialog
    setOpenConfirmDialog(false);

    //request delete goal result
    requestDeleteGoalResult(goalResultData.id);
  };

  //handle click close confirm delete dialog
  const handleCloseDeleteDialog = () => {
    //close confirm delete dialog
    setOpenConfirmDialog(false);
  };

  //handle hide loader
  function closeLoader() {
    setLoading(false);
  }

  //hanlde hide snackbar
  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  //request delete goal result
  const requestDeleteGoalResult = (goalResultId) => {
    setLoading(true);

    axios
      .delete(Endpoint + "swd/goal/" + goalId + "/result/" + goalResultId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((response) => {
        setLoading(false);
        setIsSuccess(true);
        const responseData = response.data;

        console.log("response delete GR", JSON.stringify(responseData));

        //show snackbar
        setOpenSnackBar(true);

        onRemove();
      })
      .catch((error) => {
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  return (
    <div className={classes.root}>
      <div
        //key={goalResultData.id}
        style={{
          //width: "100%",
          //maxWidth: VALUES.maxScreenWidth,
          padding: 10,
          marginBottom: 10,
          fontSize: 12,
          border: "1px solid #dddddd",
          borderRadius: 6,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {goalResultData.result_date}

          <div style={{ marginLeft: "auto" }}>
            <IconButton
              style={{ padding: 2 }}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreHorizIcon style={{ color: "black" }} />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  //maxHeight: ITEM_HEIGHT * 4.0,
                  //minHeight: 40,
                  width: "10ch",
                  boxShadow:
                    "0 1px 3px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1) inset",
                },
              }}
            >
              <MenuItem
                onClick={() => handleEditGoalResult("1")}
                style={{ height: 40, minHeight: 40 }}
              >
                Edit
              </MenuItem>
              <MenuItem onClick={handleDeleteGoalresult}>Delete</MenuItem>
            </Menu>
          </div>
        </div>

        {CurrencyFormat(goalResultData.result_value, 2)}
        <br />
        <div style={{ display: "flex", flexDirection: "row" }}>
          {goalResultData &&
            goalResultData.goalResultEvidence.map((item, index) => (
              <GoalResultEvidenceItem key={item.id} evidenceData={item} />
            ))}
        </div>

        {goalResultData.description}
      </div>

      <Modal
        open={isGoalResultFormShow}
        onClose={handleCloseGoalResultForm}
        className={classes.modal}
        closeAfterTransition
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        {
          <div style={{ padding: 0, outline: 0 }}>
            <GoalResultFormCreate
              goalId={goalId}
              action="edit"
              existingData={goalResultData}
              onCloseFormDialog={handleCloseGoalResultForm}
            />
          </div>
        }
      </Modal>

      <Dialog
        open={openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Hapus?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Yakin ingin menghapus Goal Result ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickDeleteGoalResult} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={closeLoader}
        loaderText="Deleting ..."
      />

      <Snackbar
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        autoHideDuration={2000}
        TransitionComponent={transition}
        //message="Sukses, Goal Result berhasil di hapus."
        key={transition ? transition.name : ""}
      >
        {/* <Alert severity="success">Sukses, Goal Result berhasil di hapus.</Alert> */}
        <div
          style={{
            backgroundColor: "#4caf50",
            color: "white",
            padding: 10,
            borderRadius: 6,
            fontSize: 14,
          }}
        >
          Sukses, Goal Result berhasil di hapus.
        </div>
      </Snackbar>
    </div>
  );
};

export default GoalResultCollectionItem;
