import React, { useEffect, useState } from "react";
import "date-fns";
import { formatDuration } from "date-fns";
import { id } from "date-fns/locale";

import { Button, CircularProgress, FormControl } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Grid,
  Paper,
  Input,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DurationPicker } from "material-duration-picker";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { navigate } from "hookrouter";
import { isEmpty, now } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { useSelector } from "react-redux";
import BackToolbar from "../../../components/BackToolbar";
import ErrorDialog from "../../../components/Dialog/ErrorDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import PreRequestAttendance from "../../../components/HOC/PreRequestAttendance";
import AppLoader from "../../../components/Loader/AppLoader";
import { COLORS } from "../../../helper/constants/colors";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
//import custom lib
import { getToken, isAuthenticated } from "../../../helper/utils/AuthData";
import { Autorenew, BusinessCenter, Room } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    fontFamily: "Nunito",
    maxWidth: VALUES.maxScreenWidth,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  fixedBottomButton: {
    width: "100%",
    height: "5rem",
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
}));

const RequestException = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false); //true
  const [status, setStatus] = useState({
    status: false,
    info: "",
    message: "",
  }); //true

  const [shiftList, setShiftList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  //input
  const [value, setValue] = useState({
    date_before: null,
    shift_id: null,
    schedule_id: null,
    date_after: null,
    clock_in: null,
    clock_out: null,
    clock_location: null,
    deskripsi: "",
    detail_schedule: [],
  });

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
    getShiftList();
  }, []);

  useEffect(() => {
    async function getScheduleList() {
      try {
        setLoading(true);
        const response = await axios.get(
          Endpoint +
            "human-resource/request-exception/" +
            value.shift_id +
            "/schedule",
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (response.status === 200) {
          setScheduleList(response.data.data.schedule);
        }
      } catch (error) {
        setStatus({
          status: "error",
          info: "error",
          message: "Something went wrong",
        });
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
    if (value.shift_id !== null) {
      getScheduleList();
    }
  }, [value.shift_id]);

  useEffect(() => {
    if (value.date_before !== null) {
      getScheduleDetail();
    }
  }, [value.date_before]);

  const handleClose = () => {
    if (status.status === "success") {
      navigate("/request-exception");
    }
    setStatus({ status: false, info: "", message: "" });
  };

  const handleRequestException = async () => {
    try {
      setLoading(true);
      const data = {
        RequestException: {
          shift_id: value.shift_id,
          schedule_id: value.schedule_id,
          date_before: value.date_before.toISOString().split("T")[0],
          date_after: value.date_after.toISOString().split("T")[0],
          clock_in: value.clock_in,
          clock_out: value.clock_out,
          deskripsi: value.deskripsi,
          detail_schedule: value.detail_schedule,
        },
      };

      const response = await axios.post(
        Endpoint + "human-resource/request-exception",
        data,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setStatus({
          status: "success",
          info: "success",
          message: "Successfully Requested",
        });
      } else {
        setStatus({
          status: "error",
          info: "error",
          message: "Something went wrong",
        });
      }
    } catch (error) {
      setStatus({
        status: "error",
        info: "error",
        message: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getShiftList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        Endpoint + "human-resource/request-exception/shift",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      setShiftList(response.data.data.shift);
    } catch (error) {
      setStatus({
        status: "error",
        info: "error",
        message: "Something went wrong",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const getScheduleDetail = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        Endpoint +
          "human-resource/request-exception/" +
          value.date_before.toISOString().split("T")[0] +
          "/schedule-detail",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setValue({
        ...value,
        shift_id: response.data.data.shift.id,
        schedule_id: response.data.data.schedule.id,
        clock_in: response.data.data.shift.schedule_in,
        clock_out: response.data.data.shift.schedule_out,
        clock_location: response.data.data.schedule.location.location_name,
        detail_schedule: response.data.data.schedule_detail,
      });
    } catch (error) {
      setStatus({
        status: "error",
        info: "error",
        message: "Something went wrong",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#fff",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
        //elevation={0}
      >
        <BackToolbar title={"Request Exception"} />
      </AppBar>

      <Card
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#fff",
          maxWidth: VALUES.maxScreenWidth,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        <CardContent>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Jadwal"
                format="d, MMMM, yyyy"
                value={value.date_before}
                onChange={(v) => {
                  setValue({ ...value, date_before: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <InputLabel htmlFor="shift-type">Jenis Shift</InputLabel>
            <Select
              id="shift-type"
              value={value.shift_id}
              onChange={(e) =>
                setValue({
                  ...value,
                  shift_id: e.target.value,
                })
              }
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <Autorenew />
                </InputAdornment>
              }
              disabled={true}
            >
              <MenuItem value="">PILIH JENIS</MenuItem>
              {shiftList.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <InputLabel htmlFor="schedule-type">Jenis Schedule</InputLabel>
            <Select
              id="schedule-type"
              value={value.schedule_id}
              onChange={(e) =>
                setValue({
                  ...value,
                  schedule_id: e.target.value,
                })
              }
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <Autorenew />
                </InputAdornment>
              }
              disabled={true}
            >
              <MenuItem value="">PILIH JENIS</MenuItem>
              {scheduleList.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Paper
            style={{
              display: value.date_before === null ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#ffffff",
              borderColor: "#262261",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
            variant="outlined"
          >
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenter style={{ marginRight: "10px" }} />
              <Typography style={{ fontWeight: "bold" }}>
                CLOCK IN &amp; OUT
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="input-clockin">Clock In</InputLabel>
                  <Input
                    id="input-clockin"
                    type="time"
                    value={value.clock_in}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        clock_in: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="input-clockout">Clock Out</InputLabel>
                  <Input
                    id="input-clockout"
                    type="time"
                    value={value.clock_out}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        clock_out: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="input-location">Lokasi</InputLabel>
                  <Input
                    id="input-location"
                    startAdornment={
                      <InputAdornment position="start">
                        <Room />
                      </InputAdornment>
                    }
                    value={value.clock_location}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{
              display: value.date_before === null ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#ffffff",
              borderColor: "#262261",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
            variant="outlined"
          >
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenter style={{ marginRight: "10px" }} />
              <Typography style={{ fontWeight: "bold" }}>
                CHECK IN &amp; OUT
              </Typography>
            </div>
            {value.detail_schedule?.map((item, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`input-checkin-${index + 1}`}>
                      Check In {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-checkin-${index + 1}`}
                      type="time"
                      value={item.check_in}
                      onChange={(e) => {
                        const newValue = [...value.detail_schedule];
                        newValue[index].check_in = e.target.value;
                        setValue({
                          ...value,
                          detail_schedule: newValue,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`input-checkout-${index + 1}`}>
                      Check Out {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-checkout-${index + 1}`}
                      type="time"
                      value={item.check_out}
                      onChange={(e) => {
                        const newValue = [...value.detail_schedule];
                        newValue[index].check_out = e.target.value;
                        setValue({
                          ...value,
                          detail_schedule: newValue,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`input-location-${index + 1}`}>
                      Lokasi {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-location-${index + 1}`}
                      startAdornment={
                        <InputAdornment position="start">
                          <Room />
                        </InputAdornment>
                      }
                      value={
                        item.location ? item.location.location_name : "Kantor"
                      }
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </Paper>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Tanggal ganti"
                format="d, MMMM, yyyy"
                value={value.date_after}
                onChange={(v) => {
                  if (
                    v.toISOString().split("T")[0] ===
                    value.date_before.toISOString().split("T")[0]
                  ) {
                    setStatus({
                      status: "error",
                      info: "error",
                      message: "Tidak boleh sama dengan jadwal",
                    });
                  } else {
                    setValue({ ...value, date_after: v });
                  }
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <TextField
              fullWidth
              label="Deskripsi"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) => {
                setValue({ ...value, deskripsi: e.target.value });
              }}
              value={value.deskripsi}
            />
          </FormControl>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "1rem",
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    navigate("/live-attendance");
                  } /*handleCancel()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={
                  () => {
                    handleRequestException();
                  } /*handleSubmit()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Ajukan
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ErrorDialog
        open={status.status === "error"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <SuccessDialog
        open={status.status === "success"}
        info={status.info}
        message={status.message}
        handleClose={handleClose}
      />
      <AppLoader
        isLoaderOpen={loading}
        onRequestClose={() => {}}
        loaderText="Loading data ..."
      />
    </div>
  );
};

export default RequestException;
