export const COLORS = {
  white: "#fff",
  black: "#000",
  redPrimary: "#d1354a",
  redprimaryDark: "#C40049",

  redPrimaryOpacity30: "rgba(209, 53, 74, 0.3)",
  yellowPrimaryOpacity30: "rgba(255, 193, 7, 0.3)",
  yellowPrimaryOpacity20: "rgba(255, 193, 7, 0.2)",

  yellowPrimary: "#FFC107",
  greenPrimary: "#55dc87",
  bluePrimary: "#0f8ff7",

  gray1: "#f2f3f4",
  gray2: "#E6E9ED",
  gray3: "#cfd0d1",
  textColorGray1: "#AAB2BD",
  textColorGray2: "#656D78",
  textColorGray3: "#787b80",
  textColorGray4: "#454646",
  textColorGray5: "#1b1b1b",

  green: "#55dc87",
  green2: "#079C3F",

  textColorPlaceholder: "#AAB2BD",
  textColorPrimary: "#454646",
  purple: "#25225e",
  // your colors
};
