import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	historyDetail: {},
	data: {},
};
const historyDetail = createSlice({
	name: 'historyDetail',
	initialState,
	reducers: {
		setHistoryDetail: (state, action) => {
			state.historyDetail = action.payload.historyDetail;
			state.data = action.payload.data;
			return state;
		},
	},
});

export const { setHistoryDetail } = historyDetail.actions;
export default historyDetail.reducer;
