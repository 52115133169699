import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import userImage from '../../assets/images/user-red.png';
import BottomNavigation from '../../components/BottomNavigation';
import PreRequestAttendance from '../../components/HOC/PreRequestAttendance';
import AppLoader from '../../components/Loader/AppLoader';
import { COLORS } from '../../helper/constants/colors';
import { VALUES } from '../../helper/constants/ValueConstants';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: VALUES.maxScreenWidth,
		marginBottom: 100,
	},
	listView: {
		display: 'flex',
		flexDirection: 'column',
		padding: '30px 0px',
	},
	listItem: {
		display: 'grid',
		gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
		columnGap: '20px',
		alignItems: 'center',
		justifyItems: 'center',
		margin: '20px 0px',
	},
	text: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'flex-start',
	},
	picture: {
		borderRadius: 50,
		width: '70px',
		height: '70px',
		position: 'absolute',
		left: '50%',
		transform: 'translate(-50%, 0%)',
	},
	primaryText: {
		margin: 0,
		padding: 0,
		color: COLORS.purple,
		fontSize: '22px',
		fontWeight: 'bold',
	},
	secondaryText: {
		margin: 0,
		padding: 0,
		color: COLORS.textColorGray2,
		fontSize: '17px',
	},
	div: {
		borderRadius: '50%',
		width: 60,
		height: 60,
		overflow: 'hidden',
		display: 'flex',
		position: 'relative',
	},

	borderListItem: { border: `2px solid ${COLORS.gray1}`, borderRadius: '50px' },
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && <Box style={{ padding: '0px 25px' }}>{children}</Box>}
		</div>
	);
}

function TeamPage() {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(0);

	const team = useSelector(state => state.team);

	const closeLoader = () => {
		setLoading(false);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<PreRequestAttendance>
			<div className={classes.root}>
				<Typography variant="h4" style={{ color: COLORS.purple, margin: '20px' }}>
					<b>Team</b>
				</Typography>
				<Box sx={{ width: '100%' }}>
					<Box>
						<Tabs
							style={{ textColor: COLORS.gray1 }}
							textColor="primary"
							indicatorColor="primary"
							value={value}
							onChange={handleChange}
							aria-label="team-tab"
							variant="fullWidth"
							TabIndicatorProps={{ style: { borderBottom: `3px solid ${COLORS.purple}`, borderRadius: '20px' } }}
						>
							<Tab label="Masuk" />
							<Tab label="Tidak Masuk" />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<div className={classes.listView}>
							{team.data
								?.filter(item => item.clockin === true)
								.map(item => (
									<>
										<div className={classes.listItem}>
											<div
												style={{ gridColumn: 'span 1 / span 1', position: 'relative', width: '70px', height: '70px' }}
											>
												<img src={item.photo_url ?? userImage} alt="" className={classes.picture} />
											</div>
											<div
												className={classes.text}
												style={{ gridColumn: 'span 4 / span 4', justifySelf: 'self-start' }}
											>
												<h2 className={classes.primaryText}>
													{item.first_name} {item.last_name}
												</h2>
												<h3 className={classes.secondaryText}>{item.structur_position_title_name}</h3>
											</div>
										</div>
										<div className={classes.borderListItem}></div>
									</>
								))}
						</div>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<div className={classes.listView}>
							{team.data
								?.filter(item => item.clockin === false)
								.map(item => (
									<>
										<div className={classes.listItem}>
											<div
												style={{ gridColumn: 'span 1 / span 1', position: 'relative', width: '70px', height: '70px' }}
											>
												<img src={item.photo_url ?? userImage} alt="" className={classes.picture} />
											</div>
											<div
												className={classes.text}
												style={{ gridColumn: 'span 4 / span 4', justifySelf: 'self-start' }}
											>
												<h2 className={classes.primaryText}>
													{item.first_name} {item.last_name}
												</h2>
												<h3 className={classes.secondaryText}>{item.structur_position_title_name}</h3>
											</div>
										</div>
										<div className={classes.borderListItem}></div>
									</>
								))}
						</div>
					</TabPanel>
				</Box>
				<BottomNavigation />
			</div>
			<AppLoader isLoaderOpen={loading || team.loading} onRequestClose={closeLoader} loaderText="Fetching Data ..." />
		</PreRequestAttendance>
	);
}

export default TeamPage;
