import React, { useState, useEffect } from "react";
//import { useMediaQuery } from "react-responsive";
import { navigate } from "hookrouter";
import axios from "axios";

//import material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Row, Col, Image } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
//import Alert from "@material-ui/core/Alert";

//import component lib
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Player from "griffith"; //video player

//import custom lib
import { Endpoint } from "../../../helper/utils/ApiEndpoint";
import { isAuthenticated, getToken } from "../../../helper/utils/AuthData";
import { VALUES } from "../../../helper/constants/ValueConstants";
import { COLORS } from "../../../helper/constants/colors";
import {
  DecimalFormat,
  DecimalFormatWithPercentage,
  CurrencyFormat,
} from "../../../helper/utils/StringFormatter";

//import image assets
import imageUnknownFile from "../../../assets/images/ic_other_file.png";
import imageVideo from "../../../assets/images/ic_video.png";
import imageAudio from "../../../assets/images/ic_media_audio.png";
import imagePdf from "../../../assets/images/ic_thumbnail_file_pdf.png";
import imageExcel from "../../../assets/images/ic_thumbnail_file_excel.png";
import imageDoc from "../../../assets/images/ic_thumbnail_file_word.png";
import imagePpt from "../../../assets/images/ic_thumbnail_file_powerpoint.png";
import imageTxt from "../../../assets/images/ic_doc_txt.jpg";

//css style
const useStyles = makeStyles({
  root: {
    //margin: "0 auto",
    //width: "100%",
    //height: "100vh",
    //color: "white",
    fontFamily: "Nunito",
    //maxWidth: VALUES.maxScreenWidth,
    //background: "linear-gradient(180deg, #54507d, #25225e)",
    marginTop: 5,
  },
  titlebarOuter: {
    display: "table",
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: VALUES.maxScreenWidth,
    backgroundColor: "#54507d",
    height: VALUES.titlebarHeight,
    borderRadius: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appBar: {
    position: "relative",
    //height: 40,
    //minHeight: 40,
  },
  title: {
    marginLeft: 8,
    flex: 1,
    overflow: "hidden",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const supportedImageExtension = [
  "jpg",
  "jpeg",
  "pjpeg",
  "png",
  "jfif",
  "gif",
  "svg",
  "webp",
];
const supportedVideoExtension = ["mp4", "3gp", "3gpp", "mpeg"];
const supportedAudioExtension = ["mp3", "wav", "ogg"];
const supportedExcelExtension = ["xls", "xlsx", "csv"];
const supportedDocExtension = ["doc", "docx"];
const supportedPptExtension = ["ppt", "pptx"];
const supportedPdfExtension = ["pdf"];
const supportedTxtExtension = ["txt"];
//['red', 'green'].includes('red') //true ✅

const GoalResultEvidenceItem = ({ evidenceData }) => {
  //init make styles classes
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isAudioOpen, setIsAudioOpen] = useState(false);
  const [isDocumentOpen, setIsDocumentOpen] = useState(false);
  const [isOfficeDocument, setIsOfficeDocument] = useState(false);
  const [isPDFDocument, setIsPDFDocument] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  //run first load page
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }

    //getEvidenceFile(evidenceData.self.rel.content);

    let subFileUrl = evidenceData.self.rel.content;
    let urlFile = Endpoint + subFileUrl + "?token=" + getToken();
    setFileUrl(urlFile);
  }, []);

  const handleClickPreviewEvidence = (ext) => {
    if (supportedImageExtension.includes(ext)) {
      console.log("image");
      setIsImageOpen(true); //
    } else if (supportedVideoExtension.includes(ext)) {
      console.log("video", fileUrl);
      setIsVideoOpen(true); //
    } else if (supportedAudioExtension.includes(ext)) {
      console.log("audio");
      setIsAudioOpen(true);
    } else if (supportedExcelExtension.includes(ext)) {
      console.log("excel");
      setIsOfficeDocument(true);
      setIsDocumentOpen(true); //
    } else if (supportedDocExtension.includes(ext)) {
      console.log("doc");
      setIsOfficeDocument(true);
      setIsDocumentOpen(true); //
    } else if (supportedPptExtension.includes(ext)) {
      console.log("ppt");
      setIsOfficeDocument(true);
      setIsDocumentOpen(true); //
    } else if (supportedPdfExtension.includes(ext)) {
      console.log("pdf");
      setLoading(true);
      setIsPDFDocument(true);
      setIsDocumentOpen(true); //
    } else if (supportedTxtExtension.includes(ext)) {
      console.log("txt");
      setIsPDFDocument(true);
      setIsDocumentOpen(true); //
    }
  };

  const handleCloseDialogDocumentViewer = () => {
    setIsImageOpen(false);
    setIsPDFDocument(false);
    setIsDocumentOpen(false);
  };

  const handleCloseDialogVideoViewer = () => {
    setIsVideoOpen(false);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  //request get goal result collection
  const getEvidenceFile = (subFileUrl) => {
    //show loader
    setLoading(true);

    console.log("fileUrl:", Endpoint + subFileUrl);

    axios
      .get(
        Endpoint + subFileUrl,
        {
          responseType: "arraybuffer", //responseType: "blob" /* or responseType: 'arraybuffer'  */,
        },
        {
          headers: {
            //Accept: "application/json",
            //"Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        //hide loader
        setLoading(false);

        //set success true
        setIsSuccess(true);

        const responseData = response.data;
        console.log("response evidence file", responseData);

        //const data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
      })
      .catch((error) => {
        //hide loader
        setLoading(false);
        try {
          const response = error.response;
          const statusCode = response.status;
          console.log("statusCode", statusCode);
          if (statusCode === 401) {
            console.log("unauthorized", statusCode);
            localStorage.clear();
            navigate("/");
          } else {
            const responseData = response.data;
            console.log("errorMessage", error);
            console.log("responseData", responseData);

            let infoMessage = responseData.info.message;
            let infoError = "";
            if (responseData.info.errors.length > 0) {
              infoError = responseData.info.errors[0].description;
            }
            console.log("infoMessage", infoMessage);
            console.log("infoError", infoError);

            if (infoError !== "") {
              infoMessage = infoError;
            }
          }
        } catch (catchError) {
          alert(error);
        }
      });
  };

  const renderEvidence = () => {
    let imageSrc = imageUnknownFile;
    let subFileUrl = evidenceData.self.rel.content;
    let fileUrl =
      Endpoint + subFileUrl + "?token=" + getToken() + "&mode=thumbnail";

    //if evidence is attachment
    if (
      evidenceData.evidence_category_id ===
      "c9441e2a-972d-47da-a07c-a1eb5215de05"
    ) {
      let fileExtension = evidenceData.label.split(".").pop().toLowerCase();
      let formatType = evidenceData.formatType.name;

      if (formatType === "image") {
        imageSrc = fileUrl;
      } else if (formatType === "video") {
        imageSrc = imageVideo;
      } else if (formatType === "audio") {
        imageSrc = imageAudio;
      } else if (formatType === "document") {
        let fileExtension = evidenceData.label.split(".").pop().toLowerCase();
        if (fileExtension === "pdf") {
          imageSrc = imagePdf;
        } else if (
          fileExtension === "xlsx" ||
          fileExtension === "xls" ||
          fileExtension === "csv"
        ) {
          imageSrc = imageExcel;
        } else if (fileExtension === "docx" || fileExtension === "doc") {
          imageSrc = imageDoc;
        } else if (fileExtension === "pptx" || fileExtension === "ppt") {
          imageSrc = imagePpt;
        } else if (fileExtension === "txt") {
          imageSrc = imageTxt;
        } else if (
          fileExtension === "mp4" ||
          fileExtension === "3gp" ||
          fileExtension === "wmv" ||
          fileExtension === "flv" ||
          fileExtension === "mkv" ||
          fileExtension === "mpeg"
        ) {
          imageSrc = imageVideo;
        } else if (
          fileExtension === "mp3" ||
          fileExtension === "wav" ||
          fileExtension === "ogg"
        ) {
          imageSrc = imageAudio;
        } else {
          imageSrc = imageUnknownFile;
        }
      }

      return (
        <div onClick={() => handleClickPreviewEvidence(fileExtension)}>
          <Image
            src={imageSrc}
            rounded
            //roundedCircle
            style={{
              width: 25,
              height: 25,
              marginRight: 10,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </div>
      );

      //if evidence drive
    } else if (
      evidenceData.evidence_category_id ===
      "4700760d-891a-4eee-87bb-bdd8cb28198d"
    ) {
      let fileExtension = evidenceData.label.split(".").pop().toLowerCase();
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "pjpeg" ||
        fileExtension === "png" ||
        fileExtension === "gif" ||
        fileExtension === "svg" ||
        fileExtension === "webp"
      ) {
        imageSrc = fileUrl;
      } else if (fileExtension === "pdf") {
        imageSrc = imagePdf;
      } else if (
        fileExtension === "xlsx" ||
        fileExtension === "xls" ||
        fileExtension === "csv"
      ) {
        imageSrc = imageExcel;
      } else if (fileExtension === "docx" || fileExtension === "doc") {
        imageSrc = imageDoc;
      } else if (fileExtension === "pptx" || fileExtension === "ppt") {
        imageSrc = imagePpt;
      } else if (fileExtension === "txt") {
        imageSrc = imageTxt;
      } else if (
        fileExtension === "mp4" ||
        fileExtension === "3gp" ||
        fileExtension === "wmv" ||
        fileExtension === "flv" ||
        fileExtension === "mkv" ||
        fileExtension === "mpeg"
      ) {
        imageSrc = imageVideo;
      } else if (
        fileExtension === "mp3" ||
        fileExtension === "wav" ||
        fileExtension === "ogg"
      ) {
        imageSrc = imageAudio;
      } else {
        imageSrc = imageUnknownFile;
      }

      return (
        <div onClick={() => handleClickPreviewEvidence(fileExtension)}>
          <Image
            src={imageSrc}
            rounded
            style={{
              width: 25,
              height: 25,
              marginRight: 10,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </div>
      );

      //if evidence is link
    } else if (
      evidenceData.evidence_category_id ===
      "1afdcfec-4503-4044-9f62-8e2566f24cd5"
    ) {
      return (
        <div
          style={{
            width: 40,
            backgroundColor: COLORS.gray2,
            borderRadius: 4,
            color: "black",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 4,
            paddingRight: 4,
            marginRight: 10,
            overflow: "hidden",
            textOverflow: "ellipsis",
            //wordBreak: "break-all",
            //wordWrap: "break-word",
          }}
        >
          <a
            href={evidenceData.content}
            target="_blank"
            style={{ textDecoration: "none", color: COLORS.textColorGray4 }}
          >
            {evidenceData.content}
          </a>
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      {renderEvidence()}
      {isImageOpen && (
        <Lightbox
          mainSrc={fileUrl}
          onCloseRequest={() => setIsImageOpen(false)}
          /*
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            } */
        />
      )}

      <Dialog
        fullScreen
        open={isDocumentOpen}
        onClose={handleCloseDialogDocumentViewer}
        TransitionComponent={Transition}
        style={{
          width: "100%",
          maxWidth: VALUES.maxScreenWidth,
          margin: "0 auto",
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialogDocumentViewer}
              aria-label="close"
              //style={{ padding: 0, margin: 0 }}
            >
              <CloseIcon />
            </IconButton>

            <Typography className={classes.title}>
              {evidenceData && evidenceData.label}
            </Typography>
          </Toolbar>
        </AppBar>

        <div>
          {isPDFDocument && (
            <div>
              {isLoading && (
                <div style={{ marginTop: 200, textAlign: "center" }}>
                  loading ...
                </div>
              )}
              <iframe
                src={
                  "https://docs.google.com/viewer?url=" +
                  fileUrl +
                  "&embedded=true"
                }
                style={{ width: "100%", height: "100vh" }}
                onLoad={hideLoading}
                frameborder="0"
              ></iframe>
            </div>
          )}
          {isOfficeDocument && (
            <iframe
              src={
                "https://view.officeapps.live.com/op/embed.aspx?src=" + fileUrl
              }
              style={{ width: "100%", height: "100vh" }}
              frameborder="0"
            ></iframe>
          )}
        </div>
      </Dialog>

      <Dialog
        open={isVideoOpen}
        onClose={handleCloseDialogVideoViewer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <video controls style={{ width: "100%" }}>
              <source src={fileUrl} />
            </video>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GoalResultEvidenceItem;
